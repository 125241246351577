import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import Link from "../../../components/common/link"
import {getNextNonConnectionService, getPreviousSectionSlug} from "../../../services/router/non-connection-service"
import CheckboxRadio from "../../common/checkbox-radio"
import Textarea from "../../common/textarea/textarea"
import Tooltip from "../../common/tooltip"
import Calendar from "../../common/calendar"
import Input from "../../common/input/input"
import Alert from "../../common/alert"
import MandatoryIndicator from "../../../components/common/mandatory-indicator"
import MandatoryText from "../../../components/common/mandatory-text"
import formatDateReadable from "../../../helpers/date/format-date-readable"
import TextButton from "../../common/text-button"
import TimePicker from "../../common/timepicker"
import getIcpNumberHelperText from "../../../helpers/get-icp-number-helper-text"
import IcpNumber from "../../../components/common/icp-number"
import shouldPreventNextIcpTriggerNavigation from "../../../helpers/should-prevent-next-icp-trigger-navigation"
import getServiceCheckboxesData from "../../../services/general/get-service-checkboxes-data"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        services: {
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            safetyDisconnectionDateRequested: existsGet(state, 'services.safetyDisconnectionDateRequested', null),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked')
        },
        safetyDisconnection: {
            reconnectionDateRequested: existsGet(state, 'safetyDisconnection.reconnectionDateRequested', null),
            preferredDisconnectionTime: existsGet(state, 'safetyDisconnection.preferredDisconnectionTime', null),
            preferredReconnectionTime: existsGet(state, 'safetyDisconnection.preferredReconnectionTime', null),
            electricalWork: existsGet(state, 'safetyDisconnection.electricalWork'),
            electricalWorkWho: existsGet(state, 'safetyDisconnection.electricalWorkWho'),
            electricalWorkRisk: existsGet(state, 'safetyDisconnection.electricalWorkRisk'),
            description: existsGet(state, 'safetyDisconnection.description', ''),
            electricianName: existsGet(state, 'safetyDisconnection.electricianName', ''),
            electricianPhone: existsGet(state, 'safetyDisconnection.electricianPhone', ''),
            icpNumber: existsGet(state, 'safetyDisconnection.icpNumber', '')
        },
        propertyType: existsGet(state, 'propertyType', null),
        serviceAddress: existsGet(state, 'serviceAddress', null),
        // start icp-number requirements
        icpNumbersApproved: existsGet(state, 'icpNumbersApproved', []),
        icpNumbersDenied: existsGet(state, 'icpNumbersDenied', []),
        icpNumberPendingApprovalLatest: existsGet(state, 'icpNumberPendingApprovalLatest', [])
        // end icp-number requirements
    }
}

const validationMessages = {
    safetyDisconnection_description: {
        'required': 'Please enter a description.'
    },
    safetyDisconnection_electricianName: {
        'min': 'Your electrician\'s name must be a minimum of {int} characters long.',
        'max': 'Your electrician\'s name must be a maximum of {int} characters long.',
        'lettersSpacesHyphens': 'Your electrician\'s name can only contain letters, spaces and hyphens.'
    },
    safetyDisconnection_electricianPhone: {
        'phoneNumber': 'Please enter a valid phone number.',
        'requiredIf': 'Please enter your electrician\'s phone number.'
    },
    safetyDisconnection_electricalWork: {
        'required': 'Please select an option.'
    },
    safetyDisconnection_electricalWorkWho: {
        'requiredIf': 'Please select an option.'
    },
    safetyDisconnection_electricalWorkRisk: {
        'requiredIf': 'Please select an option.'
    },
    safetyDisconnection_reconnectionDateRequested: {
        'required': 'Please select an option.'
    },
    safetyDisconnection_preferredDisconnectionTime: {
        'time-minute': 'Please select a preferred disconnection time minute value.',
        'time-hour': 'Please select a preferred disconnection time hour value.'
    },
    safetyDisconnection_preferredReconnectionTime: {
        'time-minute': 'Please select a preferred reconnection time minute value.',
        'time-hour': 'Please select a preferred reconnection time hour value.'
    }
}

const content = require('../../../json/content/content.json')

class TemporaryDisconnection extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            safetyDisconnection: {
                description: ['required'],
                electricalWork: ['required'],
                electricalWorkWho: [{'requiredIf': 'electricalWork:Yes'}],
                electricalWorkRisk: [{'requiredIf': 'electricalWork:Yes'}],
                electricianName: [{'min': 2}, {'max': 25}, 'lettersSpacesHyphens'],
                electricianPhone: [{'requiredIf': 'electricianName'}, 'phoneNumber'],
                reconnectionDateRequested: ['required', 'date'],
                preferredDisconnectionTime: ['time'],
                preferredReconnectionTime: ['time']
            },
            services: {
                safetyDisconnectionDateRequested: ['required', 'date'],
            }
        }

        // start icp-number requirements
        this.preventNextIcpTriggerNavigation = false
        this.direction = 'next'
        // end icp-number requirements

        this.validationErrorFields = {
            safetyDisconnection_descriptionErrors: [],
            safetyDisconnection_electricalWorkErrors: [],
            safetyDisconnection_electricalWorkWhoErrors: [],
            safetyDisconnection_electricalWorkRiskErrors: [],
            safetyDisconnection_electricianNameErrors: [],
            safetyDisconnection_electricianPhoneErrors: [],
            safetyDisconnection_reconnectionDateRequestedErrors: [],
            safetyDisconnection_preferredDisconnectionTimeErrors: [],
            safetyDisconnection_preferredReconnectionTimeErrors: [],
            safetyDisconnection_icpNumberErrors: [],
            services_safetyDisconnectionDateRequestedErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            hasError: false,
            editDisconnectionDate: false,
            // start icp-number requirements
            loadingForward: false,
            triggerIcpValidation: false,
            pendingIcpTriggerNavigation: false
            // end icp-number requirements
        }
    }

    componentDidUpdate(prevProps) {
        // start icp-number requirements
        if (this.state.loadingForward)
        {
            const changeableValues = [
                'safetyDisconnection.icpNumber',
                'safetyDisconnection.electricalWorkWho',
                'safetyDisconnection.electricalWorkRisk',
                'safetyDisconnection.description',
                'safetyDisconnection.electricianName',
                'safetyDisconnection.electricianPhone',
                'services.safetyDisconnectionDateRequested',
                'safetyDisconnection.preferredDisconnectionTime',
                'safetyDisconnection.reconnectionDateRequested',
                'safetyDisconnection.preferredReconnectionTime'
            ]

            if (shouldPreventNextIcpTriggerNavigation(changeableValues, prevProps, this.props))
            {
                this.preventNextIcpTriggerNavigation = true
            }
        }

        if (
            !this.preventNextIcpTriggerNavigation &&
            this.state.pendingIcpTriggerNavigation &&
            prevProps.icpNumbersApproved.length !== this.props.icpNumbersApproved.length
        )
        {
            this.setState({pendingIcpTriggerNavigation: false}, () => {
                this.handleNavigation('next')
            })
        }

        if (this.state.pendingIcpTriggerNavigation && this.preventNextIcpTriggerNavigation)
        {
            this.setState({pendingIcpTriggerNavigation: false})
            this.preventNextIcpTriggerNavigation = false
        }
        // end icp-number requirements
    }

    // start icp-number requirements -- note addition of callback
    setParentState = (obj, callback = null) => {
        this.setState(obj, callback)
    }
    // end icp-number requirements

    handleNavigation = (direction) => {
        // start icp-number requirements
        this.direction = direction

        if (this.direction === 'next' || this.direction === 'summary' || this.direction === 'forward')
        {
            return this.validateAndNavigate()
        }
        // end icp-number requirements

        if (this.direction === 'back')
        {
            this.props.removeProgress({'path': '/temporary-disconnection'})

            const previousSectionSlug = getPreviousSectionSlug(this.props.services, this.props.connections, this.props.propertyType, this.props.historyLocation, this.props.serviceAddress)
            this.props.updateLocation(previousSectionSlug, null, 'back')

            return true
        }

        return false
    }


    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()

        // start icp-number requirements
        if (!this.props.icpNumbersApproved.includes(this.props.safetyDisconnection.icpNumber))
        {
            this.setState({triggerIcpValidation: true})
            this.props.removeProgress({'path': '/temporary-disconnection'})
            return false
        }
        // end icp-number requirements

        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/temporary-disconnection'})
            return false
        }

        this.props.addProgress({'path': '/temporary-disconnection'})

        // start icp-number requirements
        if (this.direction === 'summary')
            return '/summary'
        // end icp-number requirements

        return getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
    }

    validateAndNavigate = () => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, this.direction)

            return true
        }

        return false
    }

    render() {
        const disconnectionDate = formatDateReadable(this.props.services.safetyDisconnectionDateRequested)
        const isElectricalWork = this.props.safetyDisconnection.electricalWork === 'Yes'
        const serviceCheckboxesData = getServiceCheckboxesData(this.props)
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="temporary-disconnection" className="section-margin-bottom">
                <Header
                    title="Need a temporary power disconnection?"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <div>
                                <div>
                                    <p>Let us know some more information about you and your request and we will get started. <b>Your electrician can help you with this.</b></p>
                                    <p>
                                        Find out more about safety (temporary) disconnections on the <Link text="Northpower website" link={links.temporaryDisconnection} />.
                                    </p>
                                </div>
                                <b>If you require an urgent disconnection within the next 2 working days please call 0800 10 40 <span className='non-breaking'>40.<Tooltip content={<>
                                    <p>We are happy to complete your temporary safety disconnection free of charge so long as we have 2 working days notice and your work will be completed between 7.30 am and 4 pm on a weekday.</p>
                                    <p><b>If you need a safety disconnection urgently, after hours, on a weekend or public holiday please call us on 0800 10 40 40 please note a charge will apply for this service.</b></p>
                                </>}/></span></b>
                            </div>
                        } />

                        <div className="wrapper">

                            <MandatoryText />

                            <CheckboxRadio
                                label="Is your temporary disconnection for electrical work?"
                                mandatoryIndicator={true}
                                keyString="safetyDisconnection.electricalWork"
                                parentErrorKey="safetyDisconnection_electricalWorkErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.safetyDisconnection.electricalWork}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.safetyDisconnection_electricalWork}
                                validationErrorsFromParent={this.state.safetyDisconnection_electricalWorkErrors}
                                checkboxes={
                                    [
                                        {'label': 'Yes', 'value': 'Yes'},
                                        {'label': 'No', 'value': 'No'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <CheckboxRadio
                                show={isElectricalWork}
                                label="Who will be carrying out the work?"
                                mandatoryIndicator={true}
                                keyString="safetyDisconnection.electricalWorkWho"
                                parentErrorKey="safetyDisconnection_electricalWorkWhoErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.safetyDisconnection.electricalWorkWho}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.safetyDisconnection_electricalWorkWho}
                                validationErrorsFromParent={this.state.safetyDisconnection_electricalWorkWhoErrors}
                                checkboxes={
                                    [
                                        {'label': 'Electrician', 'value': 'Electrician'},
                                        {'label': 'Homeowner', 'value': 'Homeowner'},
                                        {'label': 'Other', 'value': 'Other'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />
                            <CheckboxRadio
                                show={isElectricalWork}
                                label={<>
                                    <span>Is the temporary disconnection a high, medium or low <span className='non-breaking'>risk<MandatoryIndicator /><Tooltip
                                                content={<>
                                                    <ul className='no-bullets'>
                                                        <li><b>Low risk work</b> - Replacement for repair or maintenance of a fitting in an existing installation. This work does not require an inspection.</li>
                                                        <li><b>General risk work</b> - Usually this is installation work, including new additions, alterations, rewires, new and extended circuits. Sometimes this work requires inspection.</li>
                                                        <li><b>High risk work</b> - Includes mains work, PV photovoltaic, medical, hazardous, mining and other specialty areas. All work of this nature requires an inspection.</li>
                                                    </ul>
                                                    <span>Read more about risk levels <Link text="here" link={links.temporaryDisconnectionRiskLevels} />.</span>
                                                </>}
                                            />
                                        </span>
                                    </span>
                                </>}
                                keyString="safetyDisconnection.electricalWorkRisk"
                                parentErrorKey="safetyDisconnection_electricalWorkRiskErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.safetyDisconnection.electricalWorkRisk}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.safetyDisconnection_electricalWorkRisk}
                                validationErrorsFromParent={this.state.safetyDisconnection_electricalWorkRiskErrors}
                                checkboxes={
                                    [
                                        {'label': 'Low', 'value': 'Low'},
                                        {'label': 'General', 'value': 'General'},
                                        {'label': 'High', 'value': 'High'},
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <Textarea
                                label={<>
                                    <p>Please tell us about the work you are <MandatoryIndicator>doing</MandatoryIndicator></p>
                                    <span>Let us know as much information as you can about the reason for your temporary disconnection.</span>
                                </>}
                                keyString="safetyDisconnection.description"
                                parentErrorKey="safetyDisconnection_descriptionErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.safetyDisconnection.description}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.safetyDisconnection_description}
                                validationErrorsFromParent={this.state.safetyDisconnection_descriptionErrors}
                            />

                            <Input
                                label="Electrician name"
                                keyString="safetyDisconnection.electricianName"
                                parentErrorKey="safetyDisconnection_electricianNameErrors"
                                value={this.props.safetyDisconnection.electricianName}
                                validationMessages={validationMessages.safetyDisconnection_electricianName}
                                validationErrorsFromParent={this.state.safetyDisconnection_electricianNameErrors}
                                validationRules={this.validationRules.safetyDisconnection.electricianName}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <Input
                                label="Electrician phone"
                                type="tel"
                                keyString="safetyDisconnection.electricianPhone"
                                mandatoryIndicator={this.props.safetyDisconnection.electricianName.length > 0}
                                parentErrorKey="safetyDisconnection_electricianPhoneErrors"
                                value={this.props.safetyDisconnection.electricianPhone}
                                validationMessages={validationMessages.safetyDisconnection_electricianPhone}
                                validationErrorsFromParent={this.state.safetyDisconnection_electricianPhoneErrors}
                                validationRules={this.validationRules.safetyDisconnection.electricianPhone}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <IcpNumber
                                label={<>ICP Number<MandatoryIndicator /><Tooltip content={<p>
                                    <span>{getIcpNumberHelperText()}</span>
                                </p>} /></>}
                                keyString="safetyDisconnection.icpNumber"
                                parentErrorKey="safetyDisconnection_icpNumberErrors"
                                value={this.props.safetyDisconnection.icpNumber}
                                required={true}
                                validationErrorsFromParent={this.state.safetyDisconnection_icpNumberErrors}
                                validationRules={this.validationRules.safetyDisconnection.icpNumber}
                                updateStoreValue={this.props.updateStoreValue}
                                parentHasErrors={showValidationError}
                                setParentState={this.setParentState}
                                loadingForward={this.state.loadingForward}
                                triggerIcpValidation={this.state.triggerIcpValidation}
                                icpNumbersApproved={this.props.icpNumbersApproved}
                                icpNumbersDenied={this.props.icpNumbersDenied}
                                icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                            />

                            <div>
                                <h2>Confirm when you would like your work completed</h2>
                                <p>Safety (Temporary) Disconnection requests take around 2 working days. Please let us know the date and time that you would like your disconnection actioned and when you would like your power reconnected. We will contact you to advise when we can complete your request.</p>
                                <p>We are happy to complete your temporary safety disconnection free of charge so long as we have 2 working days notice and your work will be completed between 7.30 am and 4 pm on a weekday. <b>If you need a safety disconnection urgently, after hours, on a weekend or public holiday please call us on 0800 10 40 40 please note a charge will apply for this service.</b></p>
                            </div>

                            <h2>Please confirm your preferred date and time for disconnection and reconnection:</h2>
                            <h2 className='center-text'>Confirm disconnection date and <MandatoryIndicator>time</MandatoryIndicator></h2>

                            {this.state.editDisconnectionDate || this.props.services.safetyDisconnectionDateRequested === null ?
                                <Calendar
                                    keyString={'services.safetyDisconnection'}
                                    parentErrorKey="services_safetyDisconnectionDateRequestedErrors"
                                    standalone={true}
                                    leadTime={serviceCheckboxesData.services.safetyDisconnection.leadTime}
                                    validationErrorsFromParent={this.state.services_safetyDisconnectionDateRequestedErrors}
                                    dateRequested={this.props.services.safetyDisconnectionDateRequested}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />
                            :
                                <div className="edit-date">
                                    <span>{disconnectionDate}</span>
                                    <TextButton text="edit" onClick={() => {this.setState({editDisconnectionDate: !this.props.editDisconnectionDate})}} />
                                </div>
                            }

                            <TimePicker
                                label="Preferred disconnection time"
                                keyString="safetyDisconnection.preferredDisconnectionTime"
                                parentErrorKey="safetyDisconnection_preferredDisconnectionTimeErrors"
                                value={this.props.safetyDisconnection.preferredDisconnectionTime}
                                updateStoreValue={this.props.updateStoreValue}
                                validationErrorsFromParent={this.state.safetyDisconnection_preferredDisconnectionTimeErrors}
                                validationMessages={validationMessages.safetyDisconnection_preferredDisconnectionTime}
                                setParentState={this.setParentState}
                            />

                            <h2 className='center-text'>Reconnection date and <MandatoryIndicator>time</MandatoryIndicator></h2>
                            <Calendar
                                keyString='safetyDisconnection.reconnection'
                                parentErrorKey='safetyDisconnection_reconnectionDateRequestedErrors'
                                requiredMessage='Please select your requested date for this service.'
                                className='preferred-reconnection-time'
                                standalone={true}
                                leadTime={serviceCheckboxesData.services.safetyDisconnection.leadTime}
                                validationErrorsFromParent={this.state.safetyDisconnection_reconnectionDateRequestedErrors}
                                dateRequested={this.props.safetyDisconnection.reconnectionDateRequested}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <TimePicker
                                label="Preferred reconnection time"
                                keyString="safetyDisconnection.preferredReconnectionTime"
                                parentErrorKey="safetyDisconnection_preferredReconnectionTimeErrors"
                                value={this.props.safetyDisconnection.preferredReconnectionTime}
                                updateStoreValue={this.props.updateStoreValue}
                                validationErrorsFromParent={this.state.safetyDisconnection_preferredReconnectionTimeErrors}
                                validationMessages={validationMessages.safetyDisconnection_preferredReconnectionTime}
                                setParentState={this.setParentState}
                            />
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <TemporaryDisconnection ref={ref} {...props} />
    })
)