import React from 'react'

export default <div>
    <ol>
        <li>
            <b>Purpose of application:</b> your application allows us to assess whether we will permit a nominated person (the <b>Consent Holder</b>) to work or operate mobile plant within reduced minimum safe approach distances in the New Zealand Electrical Code of Practice for Electrical Safe Distances (NZECP 34:2001) for a specific job.
        </li>
        <li>
            <b>Our response:</b> we aim to respond within 2 working days. This is an indicative timeframe only and may vary depending on our workload and what other information we might need.
        </li>
        <li>
            <b>Site Visit:</b> we will undertake a site visit to decide whether to issue a consent. The Consent Holder and the person responsible for work at the site (the <b>Site Supervisor</b>) must be present. If we issue a consent it will include written terms and conditions that the Consent Holder and Site Supervisor must agree to and sign.
        </li>
        <li>
            <b>Our requirements:</b> In addition to any site-specific terms the written consent will include the following general terms and conditions:
            <ol className="alpha-list">
                <li>
                    <b>Scope of permission:</b> Northpower’s (<b>us, we, our</b>) permission to operate the Mobile Plant and load within the Reduced Distances is for the specific Consent Holder named in this permission and on the specific terms detailed on the first page. The permission cannot be transferred to another person, job, site location or period of time/date.
                </li>
                <li>
                    <b>Site Supervision:</b> The Site Supervisor must:
                    <ul>
                        <li>Be contactable by phone and on site during the work to which we have given our permission unless prior arrangements have been made.</li>
                        <li>Keep this document on site at all times.</li>
                        <li>Halt work on site immediately at any time on instruction by our employees.</li>
                    </ul>
                </li>
                <li>
                    <b>Our rights/powers:</b> We may, in our sole discretion, withdraw or vary the consent at any time including to reschedule site visits or outages to meet our operational needs.
                </li>
                <li>
                    <b>Health and Safety:</b>
                    <ul>
                        <li>
                            This permission is not a safety authorisation by us in relation to your work site. The Site Supervisor and Consent Holder and other parties on site are responsible for and must ensure work is undertaken in a safe and compliant manner including in accordance with the Health and Safety at Work Act 2015, the Electricity Act 1992, the Electricity (Safety) Regulations 2010 and NZECP 34:2001.
                        </li>
                        <li>
                            You agree and understand that:
                            <ul className="circle-bullet-list">
                                <li>All workers onsite must assume that power lines are ”live” at all times.</li>
                                <li>At no time will any person or any part of any machine or materials make contact with the power lines.</li>
                                <li>Whenever possible greater clearances should be worked to than the distances permitted by this document.</li>
                                <li><b>A safety observer must be appointed during all work within 4 metres of the power lines.</b></li>
                                <li>At no stage may the plant or load or equipment exceed the permitted distances.</li>
                                <li>Under no circumstances shall any machine or apparatus be worked directly above the lines.</li>
                            </ul>
                        </li>
                        <li>If any unsafe conditions occur during the work covered by this permission or if any workers have concerns or confusion around safety regarding our network assets or this permission, then you will CEASE WORK IMMEDIATELY and contact us on <a href="tel:0800104040">0800 10 40 40</a>.</li>
                    </ul>
                </li>
                <li><b>Duty of care:</b> You must take care at all times not to damage or cause or allow damage to our network assets.</li>
            </ol>
        </li>
        <li><b>Additional charges:</b> You agree that Northpower may charge you for additional costs or fees for any breach of these application terms or any failure by the Consent Holder or the Site Supervisor to comply with the terms of the consent, including those in clause 4.</li>
        <li><b>Representations:</b> By submitting this application you represent and warrant that the information you have provided is true and correct and that you have authority to enter into and agree to these application terms.</li>
    </ol>
</div>