import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import Link from "../../../components/common/link"
import {getPreviousSectionSlug, getNextNonConnectionService} from "../../../services/router/non-connection-service"
import CheckboxRadio from "../../common/checkbox-radio"
import Textarea from "../../common/textarea/textarea"
import propertyBoundary from "../../../assets/images/property-boundary.jpg"
import Alert from "../../common/alert"
import MandatoryText from "../../../components/common/mandatory-text"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        services: {
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            vegetationControlDateRequested: existsGet(state, 'services.vegetationControlDateRequested', null),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked')
        },
        vegetationControl: {
            lineType: existsGet(state, 'vegetationControl.lineType'),
            issue: existsGet(state, 'vegetationControl.issue', ''),
            previousWorkCompleted: existsGet(state, 'vegetationControl.previousWorkCompleted', ''),
        },
        propertyType: existsGet(state, 'propertyType', null),
        serviceAddress: existsGet(state, 'serviceAddress', null)
    }
}

const validationMessages = {
    vegetationControl_lineType: {
        'required': 'Please select your line type.'
    },
    vegetationControl_issue: {
        'required': 'Please enter the issue.'
    },
    vegetationControl_previousWorkCompleted: {
        'required': 'Please select an option.'
    }
}

const content = require('../../../json/content/content.json')

class VegetationControl extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            vegetationControl: {
                lineType: ['required'],
                issue: ['required'],
                previousWorkCompleted: ['required']
            }
        }

        this.validationErrorFields = {
            vegetationControl_lineTypeErrors: [],
            vegetationControl_issueErrors: [],
            vegetationControl_previousWorkCompletedErrors: []
        }

        this.state = {
            ...this.validationErrorFields
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/vegetation-control'})

            const previousSectionSlug = getPreviousSectionSlug(this.props.services, this.props.connections, this.props.propertyType, this.props.historyLocation, this.props.serviceAddress)
            this.props.updateLocation(previousSectionSlug, null, 'back')

            return true
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()
        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/vegetation-control'})
            return false
        }

        this.props.addProgress({'path': '/vegetation-control'})

        return getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="vegetation-control" className="section-margin-bottom">
                <Header
                    title="Do you have trees or vegetation close to power lines?"
                    description={<>

                    </>}
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                        <Alert type="description" text={
                            <div>
                                <p>Let us know some more information about you and your request and we will get started. You need to be the homeowner to complete this form.</p>
                                <p>Find out more about vegetation control on the <Link text="Northpower website" link={links.vegetationControl} />.</p>
                                <span><b>If your request is urgent please call 0800 10 40 40 please note a charge will apply for urgent service.</b></span>
                            </div>
                        } />

                        <div className="wrapper">
                            <MandatoryText />

                            <CheckboxRadio
                                label='Is the power line a network line or service line?'
                                mandatoryIndicator={true}
                                keyString="vegetationControl.lineType"
                                parentErrorKey="vegetationControl_lineTypeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.vegetationControl.lineType}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.vegetationControl_lineType}
                                validationErrorsFromParent={this.state.vegetationControl_lineTypeErrors}
                                checkboxes={
                                    [
                                        {'label': 'Network line', 'value': 'Network line'},
                                        {'label': 'Service line', 'value': 'Service line'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />
                            <div>
                                <h4><b>Important:</b></h4>
                                <p>Network lines are the main network power lines. Service lines are the lines that run from the street to your house or property. Service lines can either be overhead or underground.</p>
                                <p className='mb-0'>If the tree or vegetation is in your service line you will need to use a Network Approved Contractor and request a Safety (Temporary) Disconnection. Read more <Link text="here" link={links.vegetationControlServiceLines}/>.</p>
                                <img src={propertyBoundary} />
                            </div>
                            <Textarea
                                label="Please tell us about the vegetation issue"
                                description={<div>
                                    <p>
                                        Vegetation too close to power lines is dangerous. Please let us know as much information as you can about your issue, things like:
                                    </p>
                                    <ul>
                                        <li>The vicinity of the trees to the power lines</li>
                                        <li>The type of tree or vegetation (if known)</li>
                                    </ul>
                                </div>}
                                mandatoryIndicator={true}
                                keyString="vegetationControl.issue"
                                parentErrorKey="vegetationControl_issueErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.vegetationControl.issue}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.vegetationControl_issue}
                                validationErrorsFromParent={this.state.vegetationControl_issueErrors}
                            />
                            <CheckboxRadio
                                label="Have you had Northpower vegetation control work completed before?"
                                mandatoryIndicator={true}
                                keyString="vegetationControl.previousWorkCompleted"
                                parentErrorKey="vegetationControl_previousWorkCompletedErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.vegetationControl.previousWorkCompleted}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.vegetationControl_previousWorkCompleted}
                                validationErrorsFromParent={this.state.vegetationControl_previousWorkCompletedErrors}
                                checkboxes={
                                    [
                                        {'label': 'Yes', 'value': 'Yes'},
                                        {'label': 'No', 'value': 'No'},
                                        {'label': 'Unsure', 'value': 'Unsure'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <VegetationControl ref={ref} {...props} />
    })
)