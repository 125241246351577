import React, {PureComponent} from 'react'

class link extends PureComponent {
    render(){
        return (
            <a href={this.props.link} target="_blank">
                {this.props.text}
            </a>
        )
    }
}

export default link