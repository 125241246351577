import getRoleSettings from "./get-role-settings"
const rolesSettings = getRoleSettings()

export default (connectionTypes) => {
    let result = []
    connectionTypes.map((connectionType) => {
        const optionalRoles = [...rolesSettings[connectionType].optional]
        optionalRoles.map(optionalRole => result.push(optionalRole))
    })

    return [...new Set([...result])]
}