export default (time, fullHour) => {
    if (typeof time === 'undefined' || time === null)
    {
        return null
    }

    let timeHour = time.hour.value
    if (fullHour)
    {
        timeHour = timeHour.padStart(2, 0)
    }

    return timeHour+':'+time.minute.value+' '+time.meridian.value
}