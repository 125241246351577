import React, {Component} from 'react'
import validateIcp from "../../../services/validator/validate-icp"
import send from "../../../services/endpoint/send"
import Alert from "../alert"
import cancelablePromise from "../../../helpers/make-cancelable"
import TickSmall from "../../../assets/svgs/indicators/tick-small.svg"
import Spinner from "../../../assets/svgs/indicators/spinner-mid-grey-1-xs.svg"
import {notify} from "../../../lib/bugsnag"
import Link from "../../../components/common/link/link"
import keyStringToId from "../../../helpers/key-string-to-id"

class IcpNumber extends Component {
    constructor(props){
        super(props)
        this.id = keyStringToId(this.props.keyString)

        this.standardValidationHasExecuted = false
        this.icpVerificationRequest = null

        this.state = {
            validationErrorMessage: null,
        }

        this.loadingForwardClearInterval = null

        this.errorMessage = {
            generic: 'Please enter a valid ICP number.',
            server: <div>
                <span>Something went wrong, we couldn't verify that your ICP address is valid.</span>
                <button className={"next btn-validation-internal"} onClick={() => {
                    this.setState({validationErrorMessage: null}, () => {
                        this.handleExistsAjaxValidation(this.props.value)
                    })
                }}>
                    <span>Retry</span>
                </button>
                <span>If this issue persists, please contact customer care on <a href='tel:0800104040'>0800 10 40 40</a>.</span>
            </div>,
            denied: <span>We could not find that ICP. Please check that it's correct or you can look it up at <Link link='https://www.ea.govt.nz/consumers/your-power-data-in-your-hands/my-meter/' text='My meter - Electricity Authority (ea.govt.nz)' />.</span>,
        }
    }

    componentWillUnmount() {
        this.cancelIcpHandling(true)

        // stops ICP validation getting stuck
        this.props.updateStoreValue('icpNumberPendingApprovalLatest', null)
    }

    cancelIcpHandling = (willUnmount = false) => {
        if (this.icpVerificationRequest !== null)
            this.icpVerificationRequest.cancel()

        if (this.loadingForwardClearInterval !== null)
            clearInterval(this.loadingForwardClearInterval)

        if (!willUnmount)
        {
            if (this.props.loadingForward)
                this.props.setParentState({loadingForward: false})

            if (this.props.icpNumberPendingApprovalLatest !== null)
                this.props.updateStoreValue('icpNumberPendingApprovalLatest', null)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // makes sure that the validator runs when going back to the section
        if (prevProps.triggerIcpValidation !== this.props.triggerIcpValidation && this.props.triggerIcpValidation)
        {
            this.props.setParentState({triggerIcpValidation: false})
            this.handleStandardValidation(this.props.value, () => this.handleExistsAjaxValidation(this.props.value, true))
        }
    }

    handleChange = (e) => {
        const value = e.target.value
        this.props.updateStoreValue(this.props.keyString, value)

        this.cancelIcpHandling()

        // prevents validation getting triggered as the user enters data until they exit the field or it gets validated normally
        if (this.standardValidationHasExecuted)
        {
            this.handleStandardValidation(value)
        }
    }

    handleBlur = (e) => {
        const value = e.target.value

        this.handleStandardValidation(value, () => this.handleExistsAjaxValidation(value))
    }

    handleStandardValidation = (value, callback = null) => {

        if (!this.standardValidationHasExecuted)
            this.standardValidationHasExecuted = true

        if (this.props.required && value.length === 0)
        {
            this.setState({validationErrorMessage: 'Please enter your ICP number.'})
            this.setParentValidationHasError(true)

            return
        }

        if (
            (!this.props.required && value.length === 0) ||
            this.props.icpNumbersApproved.includes(value)
        ) {
            if (this.state.validationErrorMessage !== null)
                this.setState({validationErrorMessage: null})

            this.setParentValidationHasError(false)

            return
        }

        if (!validateIcp(value))
        {
            this.setState({validationErrorMessage: this.getValidationMessage(value)})
            this.setParentValidationHasError(true)

            return
        }

        if (callback !== null)
        {
            this.setState({validationErrorMessage: null}, callback)
        }
        else
        {
            this.setState({validationErrorMessage: null})
            this.setParentValidationHasError(false)
        }
    }

    updateIcpNumbersApproved = (value) => {
        this.props.updateStoreValue('icpNumbersApproved', [...this.props.icpNumbersApproved, value])
    }

    handleExistsAjaxValidation = (value, allowTriggeringLoadingForward = false) => {
        if (this.props.icpNumbersDenied.includes(value))
        {
            this.setParentValidationHasError(true)
            this.setState({validationErrorMessage: this.errorMessage.denied})
            return
        }

        if (!this.props.parentHasErrors && allowTriggeringLoadingForward)
        {
            if (this.loadingForwardClearInterval !== null)
                clearInterval(this.loadingForwardClearInterval)

            // prevents a race condition if loadingForward false was set inside the callback from send
            this.loadingForwardClearInterval = setInterval(() => {
                if (this.props.icpNumberPendingApprovalLatest === null)
                {
                    clearInterval(this.loadingForwardClearInterval)
                    this.props.setParentState({loadingForward: false})
                }
            }, 500)

            this.props.setParentState({loadingForward: true})
        }

        // prevents requests for the same ICP number
        if (this.props.icpNumberPendingApprovalLatest === value)
            return

        this.props.updateStoreValue('icpNumberPendingApprovalLatest', value)

        this.setParentValidationHasError(false) // this prevents false info as it'll be in a pending state
        this.icpVerificationRequest = cancelablePromise(
            send(process.env.CTP_ENDPOINT+'/icp/exists', {icpNumber: value})
        )

        this.icpVerificationRequest.promise
        .then((res) => {

            // at the top to stop the loading indicator, even when the value has changed
            const wasLoading = this.props.loadingForward

            // it's possible that the field value has changed since the request was made, handle that possibility
            if (value !== this.props.value)
                return

            this.props.updateStoreValue('icpNumberPendingApprovalLatest', null)

            if (res.errorMessage === null && res.icpExists === 1)
            {
                if (wasLoading)
                {
                    this.props.setParentState({pendingIcpTriggerNavigation: true}, () => {
                        this.updateIcpNumbersApproved(value)
                    })
                }
                else
                {
                    this.updateIcpNumbersApproved(value)
                }

                return
            }
            else if (res.errorMessage === null && res.icpExists === 0)
            {
                this.props.updateStoreValue('icpNumbersDenied', [...this.props.icpNumbersDenied, value])
                this.setState({validationErrorMessage: this.errorMessage.denied})
            }
            else if (res.errorMessage === 'failed_validation') // this technically shouldn't ever be triggered, only if there's some weird JS error
            {
                notify('ICP exists failed_validation server error', {state: this.state, res: res}, 'failed_validation')
                this.setState({validationErrorMessage: this.errorMessage.generic})
            }
            else
            {
                notify('ICP exists server error', {state: this.state, res: res}, 'server_error')
                this.setState({validationErrorMessage: this.errorMessage.server})
            }

            this.setParentValidationHasError(true)
        }).catch((reason) => {
            if (reason.isCanceled)
                return

            this.setParentValidationHasError(true)
            this.props.updateStoreValue('icpNumberPendingApprovalLatest', null)

            console.info('ICP exists server error')
            console.info(reason)

            notify('ICP exists server error', {'state' : {state: this.state}, 'props' : this.props}, reason)

            this.setState({validationErrorMessage: this.errorMessage.server})
            this.props.setParentState({loadingForward: false})
        })
    }

    setParentValidationHasError = (shouldHaveError) => {
        if (typeof this.props.validationErrorsFromParent === 'undefined')
        {
            return
        }

        if (shouldHaveError)
        {
            if (!this.props.validationErrorsFromParent.includes('icp'))
                this.props.setParentState({[this.props.parentErrorKey]: ['icp']})
        }
        else
        {
            if (this.props.validationErrorsFromParent.includes('icp'))
                this.props.setParentState({[this.props.parentErrorKey]: []})
        }
    }

    getValidationMessage = (value) => {
        const lowerValue = value.toLowerCase()
        const first10Characters = lowerValue.substr(0, 10) // 1 - 10
        const eleventhToTwelfthCharacters = lowerValue.substr(10, 2) // 11 - 12
        const thirteenToFifteenthCharacters = lowerValue.substr(12, 3) // 13 - 15

        if (value.length !== 15)
        {
            return 'The ICP number must be 15 characters long.'
        }

        if (!first10Characters.match(/[0-9]{10}/))
        {
            return 'The first 10 characters must be numbers.'
        }

        if (eleventhToTwelfthCharacters !== 'nr')
        {
            return 'The 11th and 12th characters must be "NR".'
        }

        if (!thirteenToFifteenthCharacters.match(/[A-F0-9]{3}/))
        {
            return 'The 13th to 15th characters must only contain numbers and letters (A to F).'
        }

        return this.errorMessage.generic
    }

    render(){
        let classNames = "icp-number-component form-group"
        if (this.state.validationErrorMessage !== null)
            classNames += " error"

        return (
            <div className={classNames}>
                <label htmlFor={this.id}>{this.props.label}</label>
                <div className='indicator-wrapper'>
                    {this.props.icpNumbersApproved.includes(this.props.value) ?
                        <TickSmall />
                    : null}
                    {
                        this.props.icpNumberPendingApprovalLatest !== null &&
                        this.props.icpNumberPendingApprovalLatest !== '' && // this can happen if the data is rehydrated via the payment cancellation system
                        this.props.icpNumberPendingApprovalLatest === this.props.value &&
                        this.state.validationErrorMessage !== this.errorMessage.server
                    ?
                        <div className="spinner-wrapper">
                            <Spinner />
                        </div>
                    : null}
                </div>
                <input type='text' id={this.id} className="form-control" onChange={this.handleChange} onBlur={this.handleBlur} value={this.props.value} />

                {this.state.validationErrorMessage !== null ?
                    <Alert text={this.state.validationErrorMessage} type="danger"/>
                : null}
            </div>
        )
    }
}

export default IcpNumber