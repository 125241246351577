import React, {PureComponent} from 'react'
import ValidationAlert from "../components/common/validation-alert"
import getValidationRuleValue from "../helpers/get-validation-rule-value"
import validatePhone from "../services/validator/validate-phone"
import validateEmail from "../services/validator/validate-email"
import lettersSpacesHyphens from "../services/validator/validate-letters-spaces-hyphens"
import validatePostcode from "../services/validator/validate-post-code"
import validateIcp from "../services/validator/validate-icp"
import validateStreetType from "../services/validator/validate-street-type"
import validateMaxAlphabeticalChars from "../services/validator/validate-max-alphabetical-chars"
import validateHyphensForwardSlashAlphanumericSpaces from "../services/validator/validate-hyphens-forward-slash-alphanumeric-spaces"

export default function TextInputWrapper(WrappedComponent) {
    // noinspection JSPotentiallyInvalidUsageOfThis
    return class extends PureComponent {
        constructor(props) {
            super(props)

            this.state = {
                childValidationErrors: []
            }
        }

        componentDidMount = () => {
            // parent validation might be as basic as checking that a field is required, so when navigating back/forward to a component that already has a value, validation is required to be run
            if (this.props.value)
            {
                this.removeErrorsIfValidates(this.props.value)
            }

            if (process.env.APP_ENV === 'dev')
            {
                if (typeof this.props.parentErrorKey !== 'undefined' && !this.props.parentErrorKey.includes('Error'))
                {
                    alert('You have set the error key to: '+this.props.parentErrorKey+'. It does not contain the keyword Error, so its probably set to the wrong state.')
                }
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (prevProps.show !== 'undefined' && prevProps.show && !this.props.show)
            {
                this.removeErrors()
            }
        }

        passesValidation = (value) => {
            const stringLength = value.length

            const validationErrorsFromParent = this.props.validationErrorsFromParent

            if (typeof validationErrorsFromParent === 'undefined' || validationErrorsFromParent.length === 0)
            {
                return false
            }

            if (validationErrorsFromParent.length === 1 && (validationErrorsFromParent.includes('required') || validationErrorsFromParent.includes('requiredWithout') || validationErrorsFromParent.includes('requiredIf')) && stringLength > 0) {
                return true
            }
            else if (validationErrorsFromParent.includes('min') && (stringLength === 0 || stringLength >= getValidationRuleValue('min', this.props.validationRules))) {
                return true
            }
            else if (validationErrorsFromParent.includes('numeric') && (stringLength === 0 || !isNaN(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('nonNumeric') && value.replace(/[0-9]/g, '').length === value.length) {
                return true
            }
            else if (validationErrorsFromParent.includes('maxNumericChars') && value.replace(/[^0-9]/g, '').length <= getValidationRuleValue('maxNumericChars', this.props.validationRules)) {
                return true
            }
            else if (validationErrorsFromParent.includes('maxAlphabeticalChars') && validateMaxAlphabeticalChars(value, getValidationRuleValue('maxAlphabeticalChars', this.props.validationRules))) {
                return true
            }
            else if (validationErrorsFromParent.includes('allowOnlyHyphensForwardSlashAlphaNumericSpaces') && validateHyphensForwardSlashAlphanumericSpaces(value)) {
                return true
            }
            else if (validationErrorsFromParent.includes('max') && stringLength <= getValidationRuleValue('max', this.props.validationRules)) {
                return true
            }
            else if (validationErrorsFromParent.includes('minNumber') && value >= getValidationRuleValue('minNumber', this.props.validationRules)) {
                return true
            }
            else if (validationErrorsFromParent.includes('maxNumber') && value <= getValidationRuleValue('maxNumber', this.props.validationRules)) {
                return true
            }
            else if (validationErrorsFromParent.includes('lettersSpacesHyphens') && (stringLength === 0 || lettersSpacesHyphens(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('phoneNumber') && (stringLength === 0 || validatePhone(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('emailAddress') && (stringLength === 0 || validateEmail(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('postcode') && (stringLength === 0 || validatePostcode(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('icp') && (stringLength === 0 || validateIcp(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('streetType') && (stringLength === 0 || validateStreetType(value))) {
                return true
            }
            else if (validationErrorsFromParent.includes('illegalStrings') && (stringLength === 0 || !getValidationRuleValue('illegalStrings', this.props.validationRules).includes(value))) {
                return true
            }

            return false
        }

        removeErrorsIfValidates = (value) => {
            if (this.passesValidation(value))
                this.removeErrors()
        }

        removeErrors = () => {
            if (this.props.setParentState)
            {
                this.props.setParentState({[this.props.parentErrorKey]: []}) // assuming parent only handles required or a single error
            }

            if (this.props.removeFromErrorTracker)
            {
                this.props.removeFromErrorTracker('parent', this.props.parentErrorKey)
            }
        }

        handleChange = (e) => {
            let value = e.target.value

            // stops invalid characters being added to a number field, accepts decimals
            if (this.props.type === 'number' && isNaN(value))
            {
                return false
            }

            if (value.length > this.props.maxLength)
            {
                return false
            }

            this.props.updateStoreValue(this.props.keyString, value)

            this.removeErrorsIfValidates(value)
        }

        render() {
            const validationErrorsFromParent = this.props.validationErrorsFromParent || []
            const validationErrors = validationErrorsFromParent.length > 0 ? [...validationErrorsFromParent, ...this.state.childValidationErrors] : this.state.childValidationErrors

            let classNames = "form-group"

            if (validationErrors.length > 0)
            {
                classNames += " error"
            }

            return <>
                {typeof this.props.show === 'undefined' || this.props.show ?
                    <div className={classNames}>
                        <WrappedComponent
                            validationErrors={this.props.validationErrors}
                            handleChange={this.handleChange}
                            placeholder={this.props.placeholder}
                            value={this.props.value}
                            label={this.props.label}
                            type={this.props.type}
                            step={this.props.step}
                            max={this.props.max}
                            description={this.props.description}
                            mandatoryIndicator={this.props.mandatoryIndicator}
                        />

                        {validationErrors.length > 0 ?
                            <ValidationAlert validationRules={this.props.validationRules} validationErrors={validationErrors} validationMessages={this.props.validationMessages} fieldValue={this.props.value} />
                        :null}
                    </div>
                :null}
            </>
        }
    }
}