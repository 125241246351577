export default (dateFirst, dateSecond) => {
    if (dateFirst === null || dateSecond === null)
    {
        return false
    }

    return (
        dateFirst.getFullYear() === dateSecond.getFullYear() &&
        dateFirst.getMonth() === dateSecond.getMonth() &&
        dateFirst.getDate() === dateSecond.getDate()
    )
}