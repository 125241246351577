export default (ruleName, validationRules) => {
    let result = null

    if (validationRules === null)
        return null

    validationRules.forEach((rule) => {
        if (typeof rule === 'object')
        {
            const ruleKey = Object.keys(rule)
            const currentRuleName = ruleKey[0]
            if (currentRuleName === ruleName)
            {
                result = rule[ruleName]
            }
        }
    })

    return result
}