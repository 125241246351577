const streetTypes = require('../../json/validation/street-types.json')
export default (street) => {

    // remove numbers, duplicate spaces, prepended and trailing spaces
    street = street.replace(/[0-9]/g, '').replace(/\s+/g,' ').trim().toLowerCase()

    const streetArray = street.split(' ')
    const streetArrayLength = streetArray.length

    return streetTypes.types.some((streetType) => {
        streetType = streetType.toLowerCase()

        const streetTypeArray = streetType.split(' ')
        const streetTypeArrayLength = streetTypeArray.length

        if (streetArrayLength <= streetTypeArrayLength)
            return false

        const streetArrayOffset = streetArrayLength - streetTypeArrayLength

        let matchCounter = 0
        for (let i = 0; i < streetTypeArrayLength; i++) {
            const streetArrayKey = i+streetArrayOffset

            if (typeof streetArray[streetArrayKey] !== 'undefined' && streetArray[streetArrayKey] === streetTypeArray[i])
                matchCounter++
        }

        return matchCounter === streetTypeArrayLength
    }) !== false
}