import React, {PureComponent} from 'react'
import ValidationAlert from "../../../components/common/validation-alert"
import Tick from "../../../assets/svgs/checkbox/tick.svg"
import MandatoryIndicator from "../../../components/common/mandatory-indicator"
import keyStringToId from "../../../helpers/key-string-to-id"

class CheckboxRadio extends PureComponent {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== 'undefined' && prevProps.show && !this.props.show)
        {
            this.removeErrors()
        }
    }

    onClick = (checked, value) => {
        if (checked && !this.props.optional)
        {
            return false
        }

        if (checked)
        {
            value = typeof this.props.default !== 'undefined' ? this.props.default : ''
        }

        this.removeErrors()

        this.props.updateStoreValue(this.props.keyString, value)
    }

    removeErrors = () => {
        if (this.props.setParentState)
        {
            this.props.setParentState({[this.props.parentErrorKey]: []})
        }
    }

    render(){
        const checkboxProp = this.props.checkboxes
        const checkboxCount = checkboxProp.length
        const checkboxes = checkboxProp.map((object, i) => {
            const id = keyStringToId(this.props.keyString)+'_'+i
            const value = object['value']
            const checked = this.props.value === value

            let className = 'checkbox-wrapper clearfix form-check checkbox-wrapper-standalone'
            if (checked)
            {
                className += ' is-checked'
            }

            if (checkboxCount === i+1)
            {
                className += ' last'
            }

            let titleClassName = 'title'
            if (this.props.initialTitleColor === 'gray')
            {
                titleClassName += ' gray'
            }

            return (
                <div className={className} key={i} onClick={() => this.onClick(checked, value)}>
                    <div className='wrapper'>
                        <div className='check'>
                            {checked ?
                                <Tick />
                            : null}
                        </div>
                        <input type='checkbox' value={value} id={id} readOnly={true} checked={checked} />
                        <span className={titleClassName}>{object['label']}</span>
                    </div>
                </div>
            )
        })

        const validationErrors = this.props.validationErrorsFromParent || []
        const hasValidationErrors = validationErrors.length > 0

        let checkboxRadioWrapperClassName = 'checkbox-radio-wrapper'
        if (hasValidationErrors)
        {
            checkboxRadioWrapperClassName += ' error'
        }

        let label = this.props.label || null
        let className = 'checkbox-radio'

        if (this.props.description)
        {
            className += ' has-description'
        }

        return (
            <>
                {typeof this.props.show === 'undefined' || this.props.show ?
                    <div className={className}>
                        {label ?
                            <label>{this.props.mandatoryIndicator ? <MandatoryIndicator>{label}</MandatoryIndicator> : label}</label>
                        : null}

                        {this.props.description ?
                            <div className='description'>
                                {this.props.description}
                            </div>
                        : null}

                        <div className={checkboxRadioWrapperClassName}>
                            {checkboxes}
                        </div>
                        {hasValidationErrors ?
                            <ValidationAlert validationRules={this.props.validationRules} validationErrors={validationErrors} validationMessages={this.props.validationMessages} />
                        : null}
                    </div>
                : null}
            </>
        )
    }
}

export default CheckboxRadio