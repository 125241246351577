import React, {PureComponent} from 'react'
import ProgressItem from "./progress-item"
import {connect} from "react-redux"
import mapDispatchToProps from "../../helpers/map-dispatch-to-props"
import existsGet from "../../helpers/exists-get"
import getCheckedFields from "../../helpers/get-checked-fields"
import checkIsIrrelevantService from "../../helpers/check-is-irrelevant-service"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        progress: existsGet(state, 'progress', []),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        services: {
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked'),
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
        },
        propertyType: existsGet(state, 'propertyType', null)
    }
}

class Sidebar extends PureComponent {

    getAllItems = () => {
        const checkedConnections  = getCheckedFields(this.props.connections)
        const checkedServices  = getCheckedFields(this.props.services)
        const hasConnection = checkedConnections.length > 0
        const hasService = checkedServices.length > 0
        const onlySubdivisionConnection = checkedConnections.length === 1 && checkedConnections.includes('newSubdivision')
        const onlyBts = checkedConnections.length === 1 && checkedConnections.includes('bts')
        const onlyProject = checkedConnections.length === 1 && checkedConnections.includes('project')
        const onlyBtsAndSubdivisionConnection = checkedConnections.length === 2 && (checkedConnections.includes('newSubdivision') && checkedConnections.includes('bts'))
        const onlyNonConnectionServices = checkedServices.length > 0 && checkedConnections.length === 0
        const onlyConnectionServices = checkedConnections.length > 0 && checkedServices.length === 0
        const isBallparkEstimate = checkedConnections.includes('ballparkEstimate')
        const onlyGeneralEnquiry = checkedConnections.length === 0 && checkedServices.length === 1 && checkedServices.includes('generalEnquiry')

        const mainItems = {
            '/': {
              'label': 'Service selection'
            },
            '/preferred-dates': {
                'label': 'Preferred dates'
            },
            '/name-and-address': {
                'label': 'Service address'
            },
            '/enquiry-details': {
                'label': 'Enquiry details'
            },
            '/property-type': {
                'label': 'Property type'
            },
            '/prerequisites': {
                'label': 'Prerequisites'
            },
            '/roles-hub': {
                'label': 'Roles & contacts'
            },
            '/retailer': {
                'label': 'Retailer details'
            },
            '/approved-contractor': {
                'label': 'Approved contractor'
            },
            '/manual-capacity': {
                'label': 'Electrical capacity'
            },
            '/distributed-generation-capacity': {
                'label': 'Generation capacity'
            },
            '/vegetation-control': {
                'label': 'Vegetation Control'
            },
            '/temporary-disconnection': {
                'label': 'Temporary Disconnection'
            },
            '/close-approach-consent': {
                'label': 'Close Approach Consent'
            },
            '/cable-location': {
                'label': 'Cable Location'
            },
            '/other-information': {
                'label': 'Other information'
            },
            '/summary': {
                'label': 'Summary'
            },
            '/pay': {
                'label': 'Pay'
            },
            '/complete': {
                'label': 'Complete'
            }
        }

        const nothingSelected = {
            '/': {
                'label': 'Service selection'
            }
        }

        const itemExcludes = {
            nonConnectionService: [
                '/prerequisites',
                '/property-type',
                '/roles-hub',
                '/retailer',
                '/approved-contractor',
                '/manual-capacity',
                '/distributed-generation-capacity',
                '/other-information',
                '/enquiry-details',
                '/pay'
            ],
            ballparkEstimate: [
                '/retailer',
                '/approved-contractor',
                '/distributed-generation-capacity'
            ],
            subdivision: [
                '/approved-contractor',
                '/distributed-generation-capacity',
                '/property-type',
                '/retailer',
                '/preferred-dates',
                '/enquiry-details'
            ],
            project: [
                '/property-type',
                '/retailer',
                '/approved-contractor',
                '/distributed-generation-capacity',
                '/prerequisites',
                '/preferred-dates',
                '/enquiry-details',
                '/pay'
            ],
            generalEnquiry: [
                '/property-type',
                '/roles-hub',
                '/retailer',
                '/prerequisites',
                '/approved-contractor',
                '/manual-capacity',
                '/distributed-generation-capacity',
                '/other-information',
                '/preferred-dates',
                '/pay'
            ]
        }

        let items = []
        if (onlyNonConnectionServices)
        {
            let allIrrelevantServices = true
            checkedServices.forEach(name => {
                if (!checkIsIrrelevantService(name))
                    allIrrelevantServices = false
            })

            if (allIrrelevantServices)
            {
                items = nothingSelected
            }
            else
            {
                items = mainItems
            }
        }
        else if (hasConnection || hasService)
        {
            items = mainItems
        }
        else
        {
            items = nothingSelected
        }

        const connections = this.props.connections
        const locationNames = Object.keys(items).filter(location => {
            // start location specific conditional logic
            if (location === '/property-type' && (onlyBts || onlyBtsAndSubdivisionConnection))
            {
                return false
            }

            if (isBallparkEstimate && itemExcludes.ballparkEstimate.includes(location))
            {
                return false
            }
            if (onlyNonConnectionServices && itemExcludes.nonConnectionService.includes(location))
            {
                return false
            }
            else if (onlySubdivisionConnection && itemExcludes.subdivision.includes(location))
            {
                return false
            }
            else if (onlyProject && itemExcludes.project.includes(location))
            {
                return false
            }
            else if (location === '/property-type' && (onlyBts || onlyBtsAndSubdivisionConnection))
            {
                return false
            }
            else if (location === '/enquiry-details' && !checkedServices.includes('generalEnquiry'))
            {
                return false
            }
            else if (location === '/vegetation-control' && !checkedServices.includes('vegetationControl'))
            {
                return false
            }
            else if (location === '/temporary-disconnection' && !checkedServices.includes('safetyDisconnection'))
            {
                return false
            }
            else if (location === '/close-approach-consent' && !checkedServices.includes('closeApproachConsent'))
            {
                return false
            }
            else if (location === '/cable-location' && !checkedServices.includes('cableLocation'))
            {
                return false
            }
            else if (location === '/manual-capacity' && ((checkedConnections.length === 1 && checkedConnections.includes('bts')) || (checkedConnections.length === 1 && checkedConnections.includes('onsiteGeneration')) || this.props.propertyType === 'Caravan'))
            {
                return false
            }
            else if (location === '/distributed-generation-capacity' && !connections.onsiteGenerationIsChecked)
            {
                return false
            }
            // end location specific conditional logic

            // start individual services
            if (onlyGeneralEnquiry)
            {
                return !itemExcludes.generalEnquiry.includes(location)
            }
            else if (onlySubdivisionConnection)
            {
                return !itemExcludes.subdivision.includes(location)
            }
            else if (onlyProject)
            {
                return !itemExcludes.project.includes(location)
            }
            // end individual services


            if (onlyNonConnectionServices && itemExcludes.nonConnectionService.includes(location))
            {
                return false
            }
            else if (onlyConnectionServices && location === '/preferred-dates')
            {
                return false
            }

            return true
        })

        let result = {}
        locationNames.forEach((locationName) => {
            result[locationName] = items[locationName]
        })

        return result
    }

    getStatus = (location) => {
        if (this.props.progress.includes(location))
        {
            return 'complete'
        }

        if (this.props.historyLocation.includes(location) && location !== '/complete')
        {
            return 'active'
        }

        return 'incomplete'
    }

    render(){
        const progressItems = this.getAllItems()

        return (
            <div id='sidebar' className={this.props.className}>
                <h2>Your Progress</h2>
                {Object.keys(progressItems).map((location, index) => {
                    const item = progressItems[location]
                    return <ProgressItem key={'progressItem_'+index} title={item.label} status={this.getStatus(location, index)} />
                })}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)