import React, {PureComponent} from 'react'
import StandardTable from '../standard-table'
import Info from '../../../assets/svgs/alerts/info.svg'

class Alert extends PureComponent {

    getContent = (alert, i = 0) => {
        const {type, text, checkbox, checkboxFirst, showInfoIcon} = alert

        return <div key={'alert_'+i}>
            {checkboxFirst ?
                <div className='checkbox-first'>
                    {checkboxFirst}
                </div>
            : null}
            <div className='wrapper'>
                {(showInfoIcon === 'undefined' || showInfoIcon) && (type === 'info' || type === 'description') ?
                    <Info />
                : null}
                {text}
                {this.props.children ?
                    this.props.children
                : null}
            </div>
            {checkbox ?
                <div className='checkbox-bottom'>
                    {checkbox}
                </div>
            : null}
        </div>
    }

    render(){
        let className = this.props.className ? this.props.className+' alert alert-'+this.props.type : 'alert alert-'+this.props.type

        return (
            <div className={className} role="alert">
                {typeof this.props.topTableSettings !== 'undefined' && this.props.topTableSettings !== null ?
                    <StandardTable uniqueTableName='alert' {...this.props.topTableSettings} />
                : null}
                {this.props.multiple ?
                    this.props.multiple.map((alert, i) => {
                        return this.getContent(alert, i)
                    })
                :
                    this.getContent({type: this.props.type, text: this.props.text, checkbox: this.props.checkbox, showInfoIcon: this.props.showInfoIcon})
                }
            </div>
        )
    }
}

export default Alert