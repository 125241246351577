import React, {Component} from 'react'
import { connect } from "react-redux"
import existsGet from "../../../helpers/exists-get"
import getCheckedFields from '../../../helpers/get-checked-fields'
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import validator from "../../../services/validator/validator"
import Button from "../../common/button"
import Alert from "../../common/alert"
import Input from "../../common/input"
import Dropdown from "../../common/dropdown"
import Modal from "../../common/modal"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import getRemainingMandatoryRoles from "../../../services/roles/get-remaining-mandatory-roles"
import MandatoryText from "../../common/mandatory-text"
import MandatoryIndicator from "../../common/mandatory-indicator"
import Tooltip from "../../common/tooltip"
import Link from "../../common/link/link"
import getAllRoles from "../../../services/roles/get-all-roles"
import CheckboxRadio from "../../common/checkbox-radio"
import doesHaveCopyFromContactId from "../../../helpers/does-have-copy-from-contact-id"
import isContactIdUsedAsCopyFromContactId from "../../../helpers/is-contact-id-used-as-copy-from-contact-id"
import convertArrayToDropdownFormat from "../../../helpers/convert-array-to-dropdown-format"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')
const defaultContactObject = require('../../../json/defaults/default-contact-object.json')

const mapStateToProps = (state) => {
    const contactId = existsGet(state, 'historyData.state.contactId')
    const role = existsGet(state, 'historyData.state.role', false) || state.roles[contactId][0]

    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: existsGet(state, 'services', null),
        contacts: existsGet(state, 'contacts', null),
        contactDetails: existsGet(state, 'contacts.'+contactId, null),
        copyFromContactId: existsGet(state, 'contacts.'+contactId+'.copyFromContactId', ''),
        contactId: contactId,
        role: role,
        roles: existsGet(state, 'roles', {}),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked')
        },
        serviceAddress: existsGet(state, 'serviceAddress', null),
        savedState: existsGet(state, 'savedState', null),
        electricity: {
            retailer: existsGet(state, 'electricity.retailer', null)
        },
        content: {
            preferredRetailerPicklist: convertArrayToDropdownFormat(state.content.preferredRetailerPicklist, 'name')
        },
    }
}

const validationMessages = {
    'firstName': {
        'required': 'Please enter a first name.',
        'min': 'The first name must be a minimum of {int} characters long.',
        'max': 'The first name must be a maximum of {int} characters long.',
        'lettersSpacesHyphens': 'The name can only contain letters, spaces and hyphens.'
    },
    'lastName': {
        'required': 'Please enter a surname.',
        'min': 'The last name must be a minimum of {int} characters long.',
        'max': 'The last name must be a maximum of {int} characters long.',
        'lettersSpacesHyphens': 'The last name can only contain letters, spaces and hyphens.'
    },
    'phoneNumber': {
        'required': 'Please enter a phone number.',
        'phoneNumber': 'Please enter a valid phone number.'
    },
    'mobileNumber': {
        'phoneNumber': 'Please enter a valid phone number.'
    },
    'emailAddress': {
        'required': 'Please enter an email address.',
        'emailAddress': 'Please enter a valid email address.'
    },
    'addressNumber': {
        'required': 'Please enter an address number.',
        'min': 'The address number must be a minimum of {int} characters long.',
        'max': 'The address number must be a maximum of {int} characters long.'
    },
    'addressStreet': {
        'required': 'Please enter an address street.',
        'min': 'The address street must be a minimum of {int} characters long.',
        'max': 'The address street must be a maximum of {int} characters long.'
    },
    'addressSuburb': {
        'min': 'The address suburb must be a minimum of {int} characters long.',
        'max': 'The address suburb must be a maximum of {int} characters long.'
    },
    'addressCity': {
        'required': 'Please enter an address city.',
        'min': 'The address city must be a minimum of {int} characters long.',
        'max': 'The address city must be a maximum of {int} characters long.'
    },
    'addressPostcode': {
        'required': 'Please enter a postcode.',
        'postcode': 'Please enter a valid postcode.'
    },
    'companyName': {
        'required': 'Please enter your company\'s name.',
        'min': 'The company name must be a minimum of {int} characters long.',
        'max': 'The company name must be a maximum of {int} characters long.'
    },
    'preferredMethodOfContact': {
        'required': 'Please select the preferred method of contact.'
    }
}

const content = require('../../../json/content/content.json')

class ContactDetails extends Component {
    constructor(props) {
        super(props)

        this.previousContactDetails = this.props.contactDetails
        this.previousRoles = existsGet(this.props.roles, this.props.contactId.toString(), [])
        this.connectionTypes = getCheckedFields(this.props.connections)

        this.forceRevalidate = false
        this.hideErrors = false
        this.contactSelectValues = null
        this.presetSavedTime = null
        this.headerText = this.getHeaderText()

        this.validationRules = {
            contactDetails: {
                firstName: ['required', {'min': 2}, {'max': 25}, 'lettersSpacesHyphens'],
                lastName: ['required', {'min': 2}, {'max': 25}, 'lettersSpacesHyphens'],
                phoneNumber: ['required', 'phoneNumber'],
                mobileNumber: ['phoneNumber'],
                emailAddress: ['required', 'emailAddress'],
                addressNumber: [{'min': 1}, {'max': 50}],
                addressStreet: [{'min': 2}, {'max': 50}],
                addressSuburb: [{'min': 2}, {'max': 50}],
                addressCity: [{'min': 2}, {'max': 50}],
                addressPostcode: ['postcode'],
                companyName: [{'min': 2}, {'max': 50}]
            }
        }

        if (['Applicant', 'Bill Payer', 'Retail Account Holder'].includes(this.props.role))
        {
            let extraRules = {
                contactDetails: {
                    ...this.validationRules.contactDetails,
                    addressNumber: ['required', ...this.validationRules.contactDetails.addressNumber],
                    addressStreet: ['required', ...this.validationRules.contactDetails.addressStreet],
                    addressCity: ['required', ...this.validationRules.contactDetails.addressCity],
                    addressPostcode: ['required', ...this.validationRules.contactDetails.addressPostcode]
                }
            }

            if (this.props.role === 'Applicant')
            {
                extraRules = Object.assign({}, {
                    contactDetails: {
                        ...extraRules.contactDetails,
                        preferredMethodOfContact: ['required']
                    }
                })
            }

            this.validationRules = Object.assign({}, this.validationRules, extraRules)
        }

        this.validationErrorFields = {
            contactDetails_firstNameErrors: [],
            contactDetails_lastNameErrors: [],
            contactDetails_phoneNumberErrors: [],
            contactDetails_mobileNumberErrors: [],
            contactDetails_emailAddressErrors: [],
            contactDetails_addressNumberErrors: [],
            contactDetails_addressStreetErrors: [],
            contactDetails_addressSuburbErrors: [],
            contactDetails_addressCityErrors: [],
            contactDetails_addressPostcodeErrors: [],
            contactDetails_companyNameErrors: [],
            contactDetails_preferredMethodOfContactErrors: [],
            roleErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            render: false,
            showRoleSelectModal: false,
            showConfirmDelete: false,
            showConfirmDeleteChanges: false,
            showDiscardChangesButton: false,
            savePresetBackendValidationErrors: null,
            savePresetBackendError: null,
            savingPreset: false,
            presetSavedSuccessfully: null
        }
    }

    componentDidMount = () =>
    {
        const showDiscardChangesButton = this.shouldShowDiscardChangesButton()
        this.setState({showDiscardChangesButton})

        // initialise contact details for contacts that don't currently exist
        if (this.props.contactDetails === null && typeof this.props.roles[this.props.contactId] === 'undefined')
        {
            this.props.updateStore({
                contacts: {
                    [this.props.contactId]: defaultContactObject
                },
                roles: {
                    [this.props.contactId]: [this.props.role]
                }
            })
        }
        else if (typeof this.props.roles[this.props.contactId] === 'undefined')
        {
            this.props.updateStoreValue('roles.'+this.props.contactId, [this.props.role])
        }
        else if (!this.state.render)
        {
            this.contactSelectValues = this.getContactSelect()
            this.setState({render: true})
        }
    }

    componentDidUpdate = (prevProps, prevState) =>
    {
        if (prevProps.copyFromContactId !== this.props.copyFromContactId)
        {
            if (this.props.copyFromContactId.length > 0)
            {
                this.hideErrors = true

                /**
                 * Note:
                 * We're populating the details from the copyFromContactId contact here. This allows the validation rules to pass.
                 *
                 * While not related to logic below directly, please note that if the copyFromContactId contact gets deleted, the contact details get updated on all contacts with the associated copyFromContactId before the root one gets deleted
                 **/
                this.props.updateStoreValue('contacts.'+this.props.contactId, Object.assign({},
                    {
                        ...this.props.contacts[this.props.copyFromContactId]
                    },
                    {
                        copyFromContactId: this.props.copyFromContactId
                    }
                ))
            }
            else if (prevProps.copyFromContactId.length > 0)
            {
                this.hideErrors = true

                this.props.updateStoreValue('contacts.'+this.props.contactId, defaultContactObject)
            }
        }

        if (!prevState.render && this.props.contactDetails !== null && Object.keys(this.props.roles).includes(this.props.contactId.toString()))
        {
            this.contactSelectValues = this.getContactSelect()
            this.setState({render: true})
        }

        if (prevProps.contactDetails !== this.props.contactDetails || prevProps.roles !== this.props.roles)
        {
            const showDiscardChangesButton = this.shouldShowDiscardChangesButton()
            if (this.state.showDiscardChangesButton !== showDiscardChangesButton)
            {
                this.setState({showDiscardChangesButton})
            }
        }

        if (this.forceRevalidate && prevProps.contactDetails !== this.props.contactDetails)
        {
            this.forceRevalidate = false
            this.validate()
        }

        if (this.state.presetSavedSuccessfully !== prevState.presetSavedSuccessfully && this.state.presetSavedSuccessfully)
        {
            this.presetSavedTime = setTimeout(
                () => {
                    this.setState({presetSavedSuccessfully: null})
                },
                1500
            )
        }

        this.handleHistoryUpdate(prevProps)
    }

    componentWillUnmount = () =>
    {
        if (this.presetSavedTime !== null)
        {
            clearTimeout(this.presetSavedTime)
        }
    }

    isProject = () => {
        return this.props.connections.projectIsChecked
    }

    setParentState = (obj, callback) => {
        this.setState(obj, callback)
    }

    shouldShowDiscardChangesButton = () => {
        const previousContactDetailsNotNull = this.previousContactDetails !== null
        const hasContactDetailsChanged = this.previousContactDetails !== this.props.contactDetails
        const hasRole = typeof this.props.roles[this.props.contactId] !== 'undefined'
        const hasRoleChanged = this.previousRoles !== this.props.roles[this.props.contactId]

        return previousContactDetailsNotNull && ((hasContactDetailsChanged) || (hasRole && hasRoleChanged))
    }

    handleHistoryUpdate = (prevProps) => {
        if (prevProps.historyAction !== this.props.historyAction && this.props.historyAction === 'BACK')
        {
            this.props.updateHistoryAction({action: 'DEFAULT'})

            if (this.previousContactDetails === this.props.contactDetails)
            {
                return this.props.updateLocation('/roles-hub')
            }

            if (this.previousContactDetails !== this.props.contactDetails)
            {
                if (this.state.showDiscardChangesButton)
                {
                    return this.handleDeleteChangesClick()
                }

                return this.handleDeleteClick()
            }
        }
    }

    handleDeleteClick = () =>
    {
        this.setState({showConfirmDelete: true})
    }

    handleDeleteChangesClick = () => {
        this.setState({showConfirmDeleteChanges: true})
    }

    handleDeleteConfirmClick = () => {
        this.props.updateLocation('/roles-hub', {'removeContactId': this.props.contactId.toString()}, null, 'back')
    }

    handleDeleteChangesConfirmClick = () => {
        this.props.updateStoreValue('contacts.'+this.props.contactId, this.previousContactDetails)

        if (this.props.contactId !== '0')
        {
            this.props.updateStoreValue('roles.'+this.props.contactId, this.previousRoles)
        }

        this.props.updateLocation('/roles-hub')
    }

    handleNavigation = (direction) => {
        if (direction === 'back')
        {
            if (this.previousContactDetails === null && this.props.contactDetails === defaultContactObject)
            {
                this.handleDeleteConfirmClick()

                return true
            }

            if (this.previousContactDetails !== this.props.contactDetails || this.previousRoles !== this.props.roles[this.props.contactId])
            {
                if (this.state.showDiscardChangesButton)
                {
                    this.handleDeleteChangesClick()

                    return false
                }
                else if (this.props.contactId !== '0')
                {
                    this.handleDeleteClick()

                    return false
                }
            }

            if (this.previousContactDetails === this.props.contactDetails)
            {
                this.props.updateLocation('/roles-hub', null, 'back')

                return true
            }

            return false
        }

        if (direction === 'summary' && this.validate())
        {
            const remainingMandatoryRoles = getRemainingMandatoryRoles(this.connectionTypes, this.props.roles)
            if (remainingMandatoryRoles.length > 0)
            {
                this.props.updateLocation('/roles-hub', {forceValidate: true})

                return true
            }

            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        if (this.validate())
        {
            return '/roles-hub'
        }

        return false
    }

    handleDone = () => {
        const nextLocation = this.validateAndGetNextLocation()

        this.hideErrors = false

        if (!nextLocation)
        {
            return null
        }

        if (this.props.fromSummary)
        {
            return this.props.updateLocation('/summary', null, 'back')
        }

        return this.props.updateLocation(nextLocation)
    }

    getRolesFromCopyFromContactId = (contactIdToCheck, currentContactId) => {
        const contacts = this.props.contacts
        const contactIds = Object.keys(contacts)
        let result = []

        contactIds.filter(contactId => contactId.toString() !== currentContactId).forEach((contactId) => {
            const contact = contacts[contactId]

            if (doesHaveCopyFromContactId(contact) && contactIdToCheck === contact.copyFromContactId)
            {
                result.push(this.props.roles[contactId][0])
            }
        })

        if (result.length > 0)
        {
            result = result.sort()
        }

        return result
    }

    getRoleString = (contactId, currentContactId) => {
        let result = ' - '+this.props.roles[contactId][0]

        const extraRoles = this.getRolesFromCopyFromContactId(contactId, currentContactId)
        if (extraRoles.length > 0)
        {
            result += ', '+extraRoles.join(', ')
        }

        return result
    }

    getFullName = (contactId) => {
        return this.props.contacts[contactId]['firstName']+' '+this.props.contacts[contactId]['lastName']
    }

    getNormalContactSelect = () => {
        const contactKeys = Object.keys(this.props.contacts)
        const allRoles = getAllRoles(this.connectionTypes)
        const currentContactId = this.props.contactId.toString()

        return contactKeys.filter((contactId) => {

            const contacts = this.props.contacts
            const notSameContact = contactId !== currentContactId
            const notCopyFromContactId = contacts[contactId]['copyFromContactId'].length === 0

            const billPayerRetailAccountHolderCondition = (currentContactId, contactId) => {
                if (contactId === '0')
                {
                    return true
                }

                const hasRole = typeof this.props.roles[currentContactId] !== 'undefined'

                // Bill Payer can copy from RAH
                if (hasRole && this.props.roles[currentContactId].includes('Bill Payer') && (this.props.roles[contactId].includes('Retail Account Holder')))
                {
                    return true
                }

                //  RAH can copy from Bill Payer
                if (hasRole && this.props.roles[currentContactId].includes('Retail Account Holder') && this.props.roles[contactId].includes('Bill Payer'))
                {
                    return true
                }

                return this.props.role !== 'Bill Payer' && this.props.role !== 'Retail Account Holder' // RAH/Bill Payer cannot copy from anything not listed above
            }

            // as contacts are retained when deselecting a service, we don't want to show those "hidden" contacts on other services that don't have that role on their list
            const hasAllowedRole = typeof this.props.roles[contactId] !== 'undefined' && (this.props.roles[contactId][0] === 'Applicant' || allRoles.includes(this.props.roles[contactId][0]))

            return notSameContact &&
                notCopyFromContactId &&
                hasAllowedRole &&
                billPayerRetailAccountHolderCondition(currentContactId, contactId)
        }).reduce((result, contactId) => {
            const roleString = this.getRoleString(contactId, currentContactId)

            result.push({
                label: this.getFullName(contactId)+roleString,
                value: contactId
            })

            return result
        }, [])
    }

    getSystemProviderContactSelect = () => {
        const contacts = this.props.contacts
        const contactKeys = Object.keys(contacts)
        const currentContactId = this.props.contactId.toString()

        return contactKeys.filter((contactId) => {
            return contactId !== currentContactId && this.props.roles[contactId][0] === 'Installer'
        }).reduce((result, contactId) => {
            if (contacts[contactId]['copyFromContactId'].length > 0)
            {
                contactId = contacts[contactId]['copyFromContactId']
            }

            const roleString = this.getRoleString(contactId, currentContactId)

            result.push({
                label: this.getFullName(contactId)+roleString,
                value: contactId
            })

            return result
        }, [])
    }

    getContactSelect = () => {
        if (this.props.role !== 'System Provider')
        {
            return this.getNormalContactSelect()
        }

        return this.getSystemProviderContactSelect()
    }

    getHeaderText = () => {
        if (this.props.contactId === '0')
        {
            return 'Your Details'
        }

        if (typeof this.props.roles[this.props.contactId] !== 'undefined')
        {
            return this.props.roles[this.props.contactId][0]+' Details'
        }

        return this.props.role+' Details'
    }

    getUnitAndAddress = (serviceAddress) => {
        return serviceAddress.unit.length > 0 ? serviceAddress.unit + "/" + serviceAddress.number : serviceAddress.number
    }

    handleCopyFromServiceAddress = () => {
        const serviceAddress = this.props.serviceAddress

        const updatedContactDetails = Object.assign({}, {
            ...this.props.contactDetails,
            addressNumber: this.getUnitAndAddress(serviceAddress),
            addressStreet: serviceAddress.street,
            addressSuburb: serviceAddress.suburb,
            addressCity: serviceAddress.city,
            addressPostcode: serviceAddress.postcode
        })

        // this is so that if a user already entered data into the fields copy from contact details targets,
        // then switches to copy from contact details then those validation messages would get removed
        if (hasError(this.state, this.validationErrorFields))
        {
            this.forceRevalidate = true
        }

        this.props.updateStoreValue('contacts.'+this.props.contactId, updatedContactDetails)
    }

    getRoleDescription = () => {
        let text = null

        if (this.props.role === 'Retail Account Holder')
        {
            text = 'The Retail Account Holder is the person who is billed by the electricity retailer for electricity at the service address.'
        }
        else if (this.props.role === 'Bill Payer')
        {
            text = 'The Bill Payer is the person who receives the quote and invoice if there is a cost associated with this work.'
        }

        return text ? <Alert type="description" text={text} /> : null
    }

    shouldShowCopyDetailsFromExistingContact = () => {
        const isApplicant = this.props.role === 'Applicant'
        const hasContactSelectValues = this.contactSelectValues.length > 0
        const notRootContact = !isContactIdUsedAsCopyFromContactId(this.props.contactId, this.props.contacts)

        return hasContactSelectValues && !isApplicant && notRootContact
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields) && !this.hideErrors

        return <div id="contact-details" className="section-margin-bottom">
            <Header
                title={this.headerText}
            />

            <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} hideNext={true} />

            {this.state.render && this.props.contactDetails !== null ?
                <div className="section">
                    <div className="container single-col">
                        <div className="wrapper">
                            {showValidationError ?
                                <div className="validation-top-wrapper">
                                    <Alert text={content.validation.hasError} type="danger" />
                                </div>
                            : null}

                            {this.getRoleDescription()}

                            {this.shouldShowCopyDetailsFromExistingContact() ?
                                <CheckboxRadio
                                    label={<>Copy details from existing contact</>}
                                    keyString={"contacts."+this.props.contactId+".copyFromContactId"}
                                    updateStoreValue={this.props.updateStoreValue}
                                    value={this.props.contactDetails.copyFromContactId}
                                    setParentState={this.setParentState}
                                    checkboxes={this.contactSelectValues}
                                    optional={true}
                                />
                            : null}

                            {this.props.contactDetails.copyFromContactId.length === 0 ?
                                <>
                                    <MandatoryText />

                                    <Input
                                        label={this.props.role === 'Applicant' ? "Your First Name" : "First Name"}
                                        mandatoryIndicator={true}
                                        keyString={"contacts."+this.props.contactId+".firstName"}
                                        parentErrorKey="contactDetails_firstNameErrors"
                                        value={this.props.contactDetails.firstName}
                                        validationMessages={validationMessages.firstName}
                                        validationErrorsFromParent={this.state.contactDetails_firstNameErrors}
                                        validationRules={this.validationRules.contactDetails.firstName}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label={this.props.role === 'Applicant' ? "Your Surname" : "Surname"}
                                        mandatoryIndicator={true}
                                        keyString={"contacts."+this.props.contactId+".lastName"}
                                        parentErrorKey="contactDetails_lastNameErrors"
                                        value={this.props.contactDetails.lastName}
                                        validationMessages={validationMessages.lastName}
                                        validationErrorsFromParent={this.state.contactDetails_lastNameErrors}
                                        validationRules={this.validationRules.contactDetails.lastName}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Primary Phone Number"
                                        type="tel"
                                        mandatoryIndicator={true}
                                        keyString={"contacts."+this.props.contactId+".phoneNumber"}
                                        parentErrorKey="contactDetails_phoneNumberErrors"
                                        value={this.props.contactDetails.phoneNumber}
                                        validationMessages={validationMessages.phoneNumber}
                                        validationErrorsFromParent={this.state.contactDetails_phoneNumberErrors}
                                        validationRules={this.validationRules.contactDetails.phoneNumber}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Secondary Phone Number"
                                        type="tel"
                                        keyString={"contacts."+this.props.contactId+".mobileNumber"}
                                        parentErrorKey="contactDetails_mobileNumberErrors"
                                        value={this.props.contactDetails.mobileNumber}
                                        validationMessages={validationMessages.mobileNumber}
                                        validationErrorsFromParent={this.state.contactDetails_mobileNumberErrors}
                                        validationRules={this.validationRules.contactDetails.mobileNumber}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Email Address"
                                        type="email"
                                        mandatoryIndicator={true}
                                        keyString={"contacts."+this.props.contactId+".emailAddress"}
                                        parentErrorKey="contactDetails_emailAddressErrors"
                                        value={this.props.contactDetails.emailAddress}
                                        validationMessages={validationMessages.emailAddress}
                                        validationErrorsFromParent={this.state.contactDetails_emailAddressErrors}
                                        validationRules={this.validationRules.contactDetails.emailAddress}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    {!this.isProject() && ['Bill Payer', 'Retail Account Holder', 'Applicant'].includes(this.props.role) ?
                                        <Button className="copy-from-service-address" text="Copy from service address" action={this.handleCopyFromServiceAddress} />
                                    : null}

                                    {this.props.contactId === '0' || this.props.role === 'Bill Payer' || this.props.role === 'Retail Account Holder' ?
                                        <>
                                            <Input
                                                label="Mailing address number"
                                                mandatoryIndicator={true}
                                                keyString={"contacts."+this.props.contactId+".addressNumber"}
                                                parentErrorKey="contactDetails_addressNumberErrors"
                                                value={this.props.contactDetails.addressNumber}
                                                validationMessages={validationMessages.addressNumber}
                                                validationErrorsFromParent={this.state.contactDetails_addressNumberErrors}
                                                validationRules={this.validationRules.contactDetails.addressNumber}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />

                                            <Input
                                                label="Mailing address street"
                                                mandatoryIndicator={true}
                                                keyString={"contacts."+this.props.contactId+".addressStreet"}
                                                parentErrorKey="contactDetails_addressStreetErrors"
                                                value={this.props.contactDetails.addressStreet}
                                                validationMessages={validationMessages.addressStreet}
                                                validationErrorsFromParent={this.state.contactDetails_addressStreetErrors}
                                                validationRules={this.validationRules.contactDetails.addressStreet}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />

                                            <Input
                                                label="Mailing address suburb"
                                                keyString={"contacts."+this.props.contactId+".addressSuburb"}
                                                parentErrorKey="contactDetails_addressSuburbErrors"
                                                value={this.props.contactDetails.addressSuburb}
                                                validationMessages={validationMessages.addressSuburb}
                                                validationErrorsFromParent={this.state.contactDetails_addressSuburbErrors}
                                                validationRules={this.validationRules.contactDetails.addressSuburb}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />

                                            <Input
                                                label="Mailing address city"
                                                mandatoryIndicator={true}
                                                keyString={"contacts."+this.props.contactId+".addressCity"}
                                                parentErrorKey="contactDetails_addressCityErrors"
                                                value={this.props.contactDetails.addressCity}
                                                validationMessages={validationMessages.addressCity}
                                                validationErrorsFromParent={this.state.contactDetails_addressCityErrors}
                                                validationRules={this.validationRules.contactDetails.addressCity}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />

                                            <Input
                                                label={<span>
                                                        Mailing address <span className='non-breaking'>postcode<MandatoryIndicator /><Tooltip content={<>
                                                            Don’t know your postcode? Find it <Link text='here' link={links.postcodeFinder} />.
                                                        </>}/>
                                                    </span>
                                                </span>
                                                }
                                                keyString={"contacts."+this.props.contactId+".addressPostcode"}
                                                parentErrorKey="contactDetails_addressPostcodeErrors"
                                                value={this.props.contactDetails.addressPostcode}
                                                validationMessages={validationMessages.addressPostcode}
                                                validationErrorsFromParent={this.state.contactDetails_addressPostcodeErrors}
                                                validationRules={this.validationRules.contactDetails.addressPostcode}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />
                                        </>
                                    : null}

                                    <Input
                                        label="Company Name"
                                        keyString={"contacts."+this.props.contactId+".companyName"}
                                        parentErrorKey="contactDetails_companyNameErrors"
                                        value={this.props.contactDetails.companyName}
                                        validationMessages={validationMessages.companyName}
                                        validationErrorsFromParent={this.state.contactDetails_companyNameErrors}
                                        validationRules={this.validationRules.contactDetails.companyName}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    {['Bill Payer'].includes(this.props.role) ?
                                        <p>If a company name is entered then the quote and invoice will be issued under this instead of the contact name.</p>
                                    : null}

                                    {this.props.contactId === '0'  ?
                                        <Dropdown
                                            label={<>Preferred Method of <MandatoryIndicator>Contact</MandatoryIndicator></>}
                                            keyString={"contacts."+this.props.contactId+".preferredMethodOfContact"}
                                            parentErrorKey="contactDetails_preferredMethodOfContactErrors"
                                            updateStoreValue={this.props.updateStoreValue}
                                            selectedOption={this.props.contactDetails.preferredMethodOfContact}
                                            options={
                                                [
                                                    {
                                                        label: 'Email',
                                                        value: 'email'
                                                    },
                                                    {
                                                        label: 'Phone',
                                                        value: 'phone'
                                                    }
                                                ]
                                            }
                                            isSearchable={true}
                                            validationMessages={validationMessages.preferredMethodOfContact}
                                            validationErrorsFromParent={this.state.contactDetails_preferredMethodOfContactErrors}
                                            validationRules={this.validationRules.contactDetails.preferredMethodOfContact}
                                            setParentState={this.setParentState}
                                        />
                                    : null}
                                </>
                            : null}

                            {this.state.savePresetBackendValidationErrors !== null ?
                                <Alert type="danger" className="alert-with-span-blocks mb-default">
                                    {this.state.savePresetBackendValidationErrors}
                                </Alert>
                            : null}

                            {this.state.savePresetBackendError !== null ?
                                this.state.savePresetBackendError
                            : null}

                            {showValidationError ?
                                <Alert text={content.validation.hasError} type="danger" className="validation-message" />
                            : null}

                            {!this.state.savingPreset ?
                                <div className="button-wrapper">
                                    <Button text={!this.props.fromSummary ? "Done" : "Update"} btnType="btn-primary" action={this.handleDone} />
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            : null}

            <Modal
                closeAction={() => this.setState({showConfirmDelete: false})}
                component={<div>
                    <span>Are you sure you want to delete this contact?</span>
                    <Button text="Confirm deletion" action={this.handleDeleteConfirmClick} />
                </div>}
                className='confirm'
                show={this.state.showConfirmDelete}
                setParentState={this.setParentState}
            />

            <Modal
                closeAction={() => this.setState({showConfirmDeleteChanges: false})}
                className='confirm'
                component={<div>
                    <span>Are you sure you want to delete your changes?</span>
                    <Button text="Confirm delete changes" action={this.handleDeleteChangesConfirmClick} />
                </div>}
                show={this.state.showConfirmDeleteChanges}
                setParentState={this.setParentState}
            />

        </div>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <ContactDetails ref={ref} {...props} />
    })
)