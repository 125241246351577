import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Dropdown from "../../common/dropdown"
import Checkbox from "../../common/checkbox"
import validate from "../../../services/validator/validator"
import Input from "../../common/input/input"
import CheckboxRadio from "../../common/checkbox-radio"
import Header from "../../common/header"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Link from "../../../components/common/link"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import StandardTable from "../../common/standard-table"
import getOnsiteGenerationPriceRows from "../../../helpers/get-onsite-generation-price-rows"
import getPriceList from "../../../helpers/get-price-list"
import convertArrayToDropdownFormat from "../../../helpers/convert-array-to-dropdown-format"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: existsGet(state, 'services', null),
        onsiteGeneration: {
            connectionType: existsGet(state, 'onsiteGeneration.connectionType', null),
            generationCapacityKw: existsGet(state, 'onsiteGeneration.generationCapacityKw', ''),
            phases: existsGet(state, 'onsiteGeneration.phases', null),
            voltage: existsGet(state, 'onsiteGeneration.voltage', null),
            type: existsGet(state, 'onsiteGeneration.type', null),
            storageType: existsGet(state, 'onsiteGeneration.storageType', ''),
            storageCapacityKwh: existsGet(state, 'onsiteGeneration.storageCapacityKwh', ''),
            inverterManufacturer: existsGet(state, 'onsiteGeneration.inverterManufacturer', null),
            inverterModel: existsGet(state, 'onsiteGeneration.inverterModel', ''),
            compliant: existsGet(state, 'onsiteGeneration.compliant', false)
        },
        content: {
            inverterManufacturerPicklist: existsGet(state, 'content.inverterManufacturerPicklist', null),
        },
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        propertyType: existsGet(state, 'propertyType', null)
    }
}

const validationMessages = {
    'onsiteGeneration_connectionType': {
        'required': 'Please select a connection type.'
    },
    'onsiteGeneration_generationCapacityKw': {
        'required': 'Please enter the generation capacity kW.'
    },
    'onsiteGeneration_phases': {
        'required': 'Please select the number of phases generating.'
    },
    'onsiteGeneration_voltage': {
        'required': 'Please select the connection voltage.'
    },
    'onsiteGeneration_type': {
        'required': 'Please select your generation type.'
    },
    'onsiteGeneration_storageType': {
        'required': 'Please select your storage type.'
    },
    'onsiteGeneration_storageCapacityKwh': {
        'required': 'Please enter your storage capacity in kWh.'
    },
    'onsiteGeneration_inverterManufacturer': {
        'required': 'Please select your inverter manufacturer.'
    },
    'onsiteGeneration_inverterModel': {
        'required': 'Please enter your inverter model.'
    }
}

const content = require('../../../json/content/content.json')

class DistributedGenerationCapacity extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            onsiteGeneration: {
                connectionType: ['required'],
                generationCapacityKw: ['required'],
                phases: ['required'],
                voltage: ['required'],
                type: ['required'],
                storageType: ['required'],
                storageCapacityKwh: ['required', {'bypassRequiredIf': 'storageType:No storage'}]
            }
        }

        this.inverterManufacturerContentLoaded = this.props.content.inverterManufacturerPicklist !== null && this.props.content.inverterManufacturerPicklist.length > 0

        if (this.inverterManufacturerContentLoaded)
        {
            this.validationRules.onsiteGeneration = Object.assign({},
                this.validationRules.onsiteGeneration,
                {
                    inverterManufacturer: ['required'],
                    inverterModel: ['required']
                }
            )
        }

        this.validationErrorFields = {
            onsiteGeneration_connectionTypeErrors: [],
            onsiteGeneration_generationCapacityKwErrors: [],
            onsiteGeneration_phasesErrors: [],
            onsiteGeneration_voltageErrors: [],
            onsiteGeneration_typeErrors: [],
            onsiteGeneration_storageTypeErrors: [],
            onsiteGeneration_storageCapacityKwhErrors: [],
            onsiteGeneration_inverterManufacturerErrors: [],
            onsiteGeneration_inverterModelErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            hideSummary: !this.props.fromSummary
        }
    }

    componentDidMount() {
        const inverterManufacturer = this.props.onsiteGeneration.inverterManufacturer
        if (inverterManufacturer)
        {
            const hasValidInverterManufacturer = this.props.content.inverterManufacturerPicklist.includes(inverterManufacturer)
            if (!hasValidInverterManufacturer)
            {
                this.props.updateStoreValue('onsiteGeneration.inverterManufacturer', null)
            }
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            this.validateAndNavigate(direction)

            return true
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/distributed-generation-capacity'})

            const checkedConnections = getCheckedFields(this.props.connections)
            const caravanOrOnlyBTS = this.props.propertyType === 'Caravan' || checkedConnections.length === 1 && checkedConnections[0] === 'bts'
            if ((checkedConnections.length === 1 && checkedConnections[0] === 'onsiteGeneration') || caravanOrOnlyBTS)
            {
                this.props.updateLocation('/approved-contractor', null, 'back')

                return true
            }

            this.props.updateLocation('/manual-capacity', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validateAndGetNextLocation())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = validate({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })

        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/distributed-generation-capacity'})
            return false
        }

        const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)

        return nextNonConnectionService !== null ? nextNonConnectionService : '/other-information'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.addProgress({'path': '/distributed-generation-capacity'})
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    generateFeeTable = (capacity) => {
        const priceRows = getOnsiteGenerationPriceRows(getPriceList())
        let key = null

        const capacityFloat = parseFloat(capacity)

        if (capacityFloat <= 10)
        {
            key = 'Up to 10kW'
        }
        else if (capacityFloat >= 11 && capacityFloat <= 100)
        {
            key = '11kW - 100kW'
        }
        else if (capacityFloat >= 101 && capacityFloat <= 1000)
        {
            key = '101kW - 1MW (1000kW)'
        }
        else if (capacityFloat >= 1001)
        {
            key = 'More than 1MW (1000kW)'
        }

        if (key === null)
            return null

        let cost = null
        priceRows.some((row) => {
            const condition = row[0]
            const value = row[1]

            if (key === condition)
            {
                cost = value
                return true
            }
        })

        return <StandardTable
            uniqueTableName='dg'
            table={{
                classes: 'table table-bordered table-striped'
            }}
            tableHeaders={{
                classes: 'table-dark',
                headers: [
                    'Service',
                    'Fee'
                ]
            }}
            tableRows={{
                rows: [
                    ['Installation', cost]
                ]
            }}
        />
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="on-site-generation" className="section-margin-bottom">
                <Header
                    title="Tell us more about your intended alternative energy setup"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <>
                                The information you give us here helps to determine your connection requirements.
                            </>
                        } />

                        <div className="wrapper">
                            <MandatoryText />

                            <CheckboxRadio
                                label="Alternative energy type"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.type"
                                parentErrorKey="onsiteGeneration_typeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.type}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_type}
                                validationErrorsFromParent={this.state.onsiteGeneration_typeErrors}
                                checkboxes={
                                    [
                                        {'label': 'Photo Voltaic (Solar)', 'value': 'Photo Voltaic'},
                                        {'label': 'Wind', 'value': 'Wind'},
                                        {'label': 'Other', 'value': 'Other'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <CheckboxRadio
                                label="Connection Type"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.connectionType"
                                parentErrorKey="onsiteGeneration_connectionTypeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.connectionType}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_connectionType}
                                validationErrorsFromParent={this.state.onsiteGeneration_connectionTypeErrors}
                                checkboxes={
                                    [
                                        {'label': 'New Connection', 'value': 'New Connection'},
                                        {'label': 'Expand existing alternative energy generation', 'value': 'Expand Existing Generation'},
                                        {'label': 'Decommission an alternate energy connection', 'value': 'Decommission Generation'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <Input
                                label="Generating Capacity (kW)"
                                mandatoryIndicator={true}
                                type="number"
                                maxLength={6}
                                keyString={"onsiteGeneration.generationCapacityKw"}
                                parentErrorKey="onsiteGeneration_generationCapacityKwErrors"
                                value={this.props.onsiteGeneration.generationCapacityKw}
                                validationMessages={validationMessages.onsiteGeneration_generationCapacityKw}
                                validationErrorsFromParent={this.state.onsiteGeneration_generationCapacityKwErrors}
                                validationRules={this.validationRules.onsiteGeneration.generationCapacityKw}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            {this.props.onsiteGeneration.generationCapacityKw !== null && this.props.onsiteGeneration.generationCapacityKw > 0 ?
                                this.generateFeeTable(this.props.onsiteGeneration.generationCapacityKw)
                            : null}

                            <CheckboxRadio
                                label="Number of Phases Generating"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.phases"
                                parentErrorKey="onsiteGeneration_phasesErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.phases}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_phases}
                                validationErrorsFromParent={this.state.onsiteGeneration_phasesErrors}
                                checkboxes={
                                    [
                                        {'label': '1', 'value': '1'},
                                        {'label': '2', 'value': '2'},
                                        {'label': '3', 'value': '3'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <CheckboxRadio
                                label="Connection Voltage"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.voltage"
                                parentErrorKey="onsiteGeneration_voltageErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.voltage}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_voltage}
                                validationErrorsFromParent={this.state.onsiteGeneration_voltageErrors}
                                checkboxes={
                                    [
                                        {'label': '230/400V', 'value': '230/400V'},
                                        {'label': '11,000V', 'value': '11,000'},
                                        {'label': 'Other', 'value': 'other'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <Dropdown
                                label="Energy storage type"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.storageType"
                                parentErrorKey="onsiteGeneration_storageTypeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                selectedOption={this.props.onsiteGeneration.storageType}
                                options={
                                    [
                                        {'label': 'Battery', 'value': 'Battery'},
                                        {'label': 'Other', 'value': 'Other'},
                                        {'label': 'No storage', 'value': 'No storage'}
                                    ]
                                }
                                isSearchable={true}
                                validationMessages={validationMessages.onsiteGeneration_storageType}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageTypeErrors}
                                validationRules={this.validationRules.onsiteGeneration.storageType}
                                setParentState={this.setParentState}
                            />

                            <Input
                                show={this.props.onsiteGeneration.storageType !== 'No storage'}
                                label="Energy Storage Capacity (kWh)"
                                mandatoryIndicator={true}
                                type="number"
                                maxLength={6}
                                keyString="onsiteGeneration.storageCapacityKwh"
                                parentErrorKey="onsiteGeneration_storageCapacityKwhErrors"
                                value={this.props.onsiteGeneration.storageCapacityKwh}
                                validationMessages={validationMessages.onsiteGeneration_storageCapacityKwh}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageCapacityKwhErrors}
                                validationRules={this.validationRules.onsiteGeneration.storageCapacityKwh}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            {this.inverterManufacturerContentLoaded ?
                                <>
                                    <Dropdown
                                        label="Inverter Manufacturer"
                                        mandatoryIndicator={true}
                                        keyString="onsiteGeneration.inverterManufacturer"
                                        parentErrorKey="onsiteGeneration_inverterManufacturerErrors"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.onsiteGeneration.inverterManufacturer}
                                        options={convertArrayToDropdownFormat(this.props.content.inverterManufacturerPicklist)}
                                        isSearchable={true}
                                        validationMessages={validationMessages.onsiteGeneration_inverterManufacturer}
                                        validationErrorsFromParent={this.state.onsiteGeneration_inverterManufacturerErrors}
                                        validationRules={this.validationRules.onsiteGeneration.inverterManufacturer}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Inverter Model"
                                        mandatoryIndicator={true}
                                        keyString="onsiteGeneration.inverterModel"
                                        parentErrorKey="onsiteGeneration_inverterModelErrors"
                                        value={this.props.onsiteGeneration.inverterModel}
                                        validationMessages={validationMessages.onsiteGeneration_inverterModel}
                                        validationErrorsFromParent={this.state.onsiteGeneration_inverterModelErrors}
                                        validationRules={this.validationRules.onsiteGeneration.inverterModel}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />
                                </>
                            : null}

                            <Checkbox
                                label="I confirm that my inverter is identified on the list of compliant inverters on the Australian Clean Energy Council website."
                                keyString="onsiteGeneration.compliant"
                                updateStoreValue={this.props.updateStoreValue}
                                isChecked={this.props.onsiteGeneration.compliant}
                                setParentState={this.setParentState}
                            />

                            <p>
                                You can find the list of compliant inverters on the <Link text="Australian Clean Energy Council website" link={links.cleanEnergyCouncil} />.
                            </p>
                        </div>

                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation  hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <DistributedGenerationCapacity ref={ref} {...props} />
    })
)