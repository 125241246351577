import React from "react"
import doesHaveCopyFromContactId from "../../helpers/does-have-copy-from-contact-id"
import getContactNameByContactIdOrCopyFromContact from "../../helpers/get-name-from-contact-id-or-copy-from-contact"

export default (contact, contactId, contacts, roles) => {

    const name = getContactNameByContactIdOrCopyFromContact(contactId, contacts, roles)
    const hasCopyFromContactId = doesHaveCopyFromContactId(contact)

    return (
        <>
            <div>
                <span>{name}</span>
            </div>

            {!hasCopyFromContactId ?
                <>
                    <div>
                        <span>{contact.phoneNumber}</span>
                        <span>{contact.mobileNumber}</span>
                    </div>

                    <div>
                        <span>{contact.emailAddress}</span>
                    </div>

                    {contact.addressNumber ?
                        <div>
                            <span>{contact.addressNumber}</span>
                            <span>{contact.addressStreet}</span>
                            <span>{contact.addressSuburb}</span>
                            <span>{contact.addressCity}</span>
                            <span>{contact.addressPostcode}</span>
                        </div>
                    :null}

                    {contact.companyName || contact.preferredMethodOfContact ?
                        <div>
                            {contact.companyName ?
                                <span>{contact.companyName}</span>
                                :null}

                            {contact.preferredMethodOfContact ?
                                <span>Contact via {contact.preferredMethodOfContact}</span>
                                :null}
                        </div>
                    :null}
                </>
            :null}
        </>
    )
}