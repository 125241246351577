import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import Link from "../../../components/common/link"
import {getNextNonConnectionService, getPreviousSectionSlug} from "../../../services/router/non-connection-service"
import Textarea from "../../common/textarea/textarea"
import Tooltip from "../../common/tooltip"
import Calendar from "../../common/calendar"
import Alert from "../../common/alert"
import MandatoryIndicator from "../../../components/common/mandatory-indicator"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import TextButton from "../../common/text-button"
import formatDateReadable from "../../../helpers/date/format-date-readable"
import TimePicker from "../../common/timepicker"
import getServiceCheckboxes from "../../../services/general/get-service-checkboxes-data"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        services: {
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            closeApproachConsentDateRequested: existsGet(state, 'services.closeApproachConsentDateRequested', null),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked')
        },
        closeApproachConsent: {
            workEndDateRequested: existsGet(state, 'closeApproachConsent.workEndDateRequested', null),
            preferredWorkStartTime: existsGet(state, 'closeApproachConsent.preferredWorkStartTime', null),
            preferredWorkEndTime: existsGet(state, 'closeApproachConsent.preferredWorkEndTime', null),
            electricalWork: existsGet(state, 'closeApproachConsent.electricalWork'),
            electricalWorkWho: existsGet(state, 'closeApproachConsent.electricalWorkWho'),
            electricalWorkRisk: existsGet(state, 'closeApproachConsent.electricalWorkRisk'),
            description: existsGet(state, 'closeApproachConsent.description', '')
        },
        propertyType: existsGet(state, 'propertyType', null),
        serviceAddress: existsGet(state, 'serviceAddress', null)
    }
}

const validationMessages = {
    closeApproachConsent_description: {
        'required': 'Please enter a description.'
    },
    closeApproachConsent_electricalWork: {
        'required': 'Please select your line type.'
    },
    closeApproachConsent_electricalWorkWho: {
        'requiredIf': 'Please select an option.'
    },
    closeApproachConsent_electricalWorkRisk: {
        'requiredIf': 'Please select an option.'
    },
    closeApproachConsent_preferredWorkStartTime: {
        'time-minute': 'Please select a preferred start time minute value.',
        'time-hour': 'Please select a preferred start time hour value.'
    },
    closeApproachConsent_preferredWorkEndTime: {
        'time-minute': 'Please select a preferred end time minute value.',
        'time-hour': 'Please select a preferred end time hour value.'
    },
    closeApproachConsent_workEndDateRequested: {
        'required': 'Please select an option.'
    }
}

const content = require('../../../json/content/content.json')

class CloseApproachConsent extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            closeApproachConsent: {
                description: ['required'],
                workEndDateRequested: ['required', 'date'],
                preferredWorkStartTime: ['time'],
                preferredWorkEndTime: ['time']
            },
            services: {
                closeApproachConsentDateRequested: ['required', 'date'],
            }
        }

        this.validationErrorFields = {
            services_closeApproachConsentDateRequestedErrors: [],
            closeApproachConsent_descriptionErrors: [],
            closeApproachConsent_preferredWorkStartTimeErrors: [],
            closeApproachConsent_preferredWorkEndTimeErrors: [],
            closeApproachConsent_workEndDateRequestedErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            editWorkStartDate: false
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/close-approach-consent'})

            const previousSectionSlug = getPreviousSectionSlug(this.props.services, this.props.connections, this.props.propertyType, this.props.historyLocation, this.props.serviceAddress)
            this.props.updateLocation(previousSectionSlug, null, 'back')
            return true
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')
            return true
        }

        return false
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()
        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/close-approach-consent'})
            return false
        }

        this.props.addProgress({'path': '/close-approach-consent'})

        return getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)
            return true
        }

        return false
    }

    render() {

        const workStartDate = formatDateReadable(this.props.services.closeApproachConsentDateRequested)
        const serviceCheckboxesData = getServiceCheckboxes(this.props)

        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="close-approach" className="section-margin-bottom">
                <Header
                    title="Need to work near power lines?"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <div>
                                <p>Let us know some more information about you and your request and we will get started. Find out more about working near power lines on the <Link text="Northpower website" link={links.closeApproach} />.</p>
                                <div>
                                    <b>If your request is urgent please call 0800 10 40 <span className='non-breaking'>40.<Tooltip content={<>
                                        <p>We are happy to complete your safety disconnection free of charge so long as we have 2 working days notice and your work will be completed on a weekday.</p>
                                        <p><b>If you need a safety disconnection urgently or on a weekend or public holiday please call us on 0800 10 40 40.</b></p>
                                    </>} />
                                    </span></b>
                                </div>
                            </div>
                        } />

                        <div className="wrapper">

                            <MandatoryText />

                            <Textarea
                                label={<>
                                    <p>Please tell us about the work you are <MandatoryIndicator>doing</MandatoryIndicator></p>
                                    <span>Let us know as much information as you can about the work you are intending to conduct near power lines, including the distance from the power lines.</span>
                                </>}
                                keyString="closeApproachConsent.description"
                                parentErrorKey="closeApproachConsent_descriptionErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.closeApproachConsent.description}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.closeApproachConsent_description}
                                validationErrorsFromParent={this.state.closeApproachConsent_descriptionErrors}
                            />

                            <div>
                                <h2>Confirm when you would like your work <MandatoryIndicator>completed</MandatoryIndicator></h2>
                                <p>Working Near Lines (Close Approach) requests take around 2 working days, <b>however some complex jobs may take more time</b>. Please let us know the date and time that you expect to start and finish your work. We will contact you to advise when we can complete your request. </p>
                                <p>We are happy to complete any safety disconnections free of charge so long as we have 2 working days notice and your work will be completed on a weekday. <b>If you need to work near power lines urgently or on a weekend or public holiday please call us on 0800 10 40 40</b></p>
                                <p>Please tell us the dates and times you will be completing work near power lines:</p>
                            </div>

                            <h2>Work start date and <MandatoryIndicator>time</MandatoryIndicator></h2>
                            {this.state.editWorkStartDate || this.props.services.closeApproachConsentDateRequested === null ?
                                <Calendar
                                    keyString='services.closeApproachConsent'
                                    parentErrorKey="services_closeApproachConsentDateRequestedErrors"
                                    standalone={true}
                                    leadTime={serviceCheckboxesData.services.closeApproachConsent.leadTime}
                                    validationErrorsFromParent={this.state.services_closeApproachConsentDateRequestedErrors}
                                    dateRequested={this.props.services.closeApproachConsentDateRequested}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />
                            :
                                <div className="edit-date">
                                    <span>{workStartDate}</span>
                                    <TextButton text="edit" onClick={() => {this.setState({editWorkStartDate: !this.props.editWorkStartDate})}} />
                                </div>
                            }

                            <TimePicker
                                label="Preferred start time"
                                keyString="closeApproachConsent.preferredWorkStartTime"
                                parentErrorKey="closeApproachConsent_preferredWorkStartTimeErrors"
                                value={this.props.closeApproachConsent.preferredWorkStartTime}
                                updateStoreValue={this.props.updateStoreValue}
                                validationErrorsFromParent={this.state.closeApproachConsent_preferredWorkStartTimeErrors}
                                validationMessages={validationMessages.closeApproachConsent_preferredWorkStartTime}
                                setParentState={this.setParentState}
                            />

                            <h2>Work end date and <MandatoryIndicator>time</MandatoryIndicator></h2>

                            <Calendar
                                keyString='closeApproachConsent.workEnd'
                                parentErrorKey='closeApproachConsent_workEndDateRequestedErrors'
                                requiredMessage='Please select the work end date.'
                                standalone={true}
                                leadTime={serviceCheckboxesData.services.closeApproachConsent.leadTime}
                                validationErrorsFromParent={this.state.closeApproachConsent_workEndDateRequestedErrors}
                                dateRequested={this.props.closeApproachConsent.workEndDateRequested}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <TimePicker
                                label="Preferred end time"
                                keyString="closeApproachConsent.preferredWorkEndTime"
                                parentErrorKey="closeApproachConsent_preferredWorkEndTimeErrors"
                                value={this.props.closeApproachConsent.preferredWorkEndTime}
                                updateStoreValue={this.props.updateStoreValue}
                                validationErrorsFromParent={this.state.closeApproachConsent_preferredWorkEndTimeErrors}
                                validationMessages={validationMessages.closeApproachConsent_preferredWorkEndTime}
                                setParentState={this.setParentState}
                            />
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <CloseApproachConsent ref={ref} {...props} />
    })
)