import React, {Component} from 'react'
import Tick from '../../assets/svgs/sidebar/tick.svg'

class ProgressItem extends Component {
    render(){
        return (
            <div className={"progress-item-wrapper "+this.props.status}>
                <div>
                    <div className='progress-indicator'>
                        {this.props.status === 'complete' ?
                            <Tick />
                        : null}
                    </div>
                    <span className="progress-header">
                        {this.props.title}
                    </span>
                </div>
            </div>
        )
    }
}

export default ProgressItem