import React, {PureComponent} from 'react'

class TextButton extends PureComponent {
    render(){
        let className = "text-button"

        if (this.props.color)
        {
            className += " "+this.props.color
        }

        if (this.props.className)
        {
            className += " "+this.props.className
        }

        return (
            <span className={className} onClick={this.props.onClick}>
                {this.props.text}
            </span>
        )
    }
}

export default TextButton