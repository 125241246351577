import getCheckedFields from "./get-checked-fields"

export default (nonConnectionServices) => {
    const checkedFields = getCheckedFields(nonConnectionServices)

    const nonConnectionItems = [
        'vegetationControl',
        'safetyDisconnection',
        'closeApproachConsent',
        'cableLocation'
    ]

    return nonConnectionItems.some((nonConnectionItems) => checkedFields.includes(nonConnectionItems))
}