function getUploadNames(uploads)
{
    let result = ''

    Object.keys(uploads).forEach((key, i) => {
        const name = uploads[key]['name']

        if (i === 0)
        {
            result = name
        }
        else
        {
            result = result+', '+name
        }
    })

    return result
}

export default (data) => {
    if (data.otherInformation && data.otherInformation.uploads && data.otherInformation.uploads.length > 0)
    {
        return getUploadNames(data.otherInformation.uploads)
    }
    else if (data.previousUploadFileNames.length > 0 && !data.previousUploadsReset)
    {
        return data.previousUploadFileNames.join(',')
    }

    return ''
}