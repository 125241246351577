import React, {createRef, PureComponent} from 'react'
import BackArrow from '../../../assets/svgs/navigation/back-arrow.svg'
import ForwardArrow from '../../../assets/svgs/navigation/forward-arrow.svg'
import SpinnerNav from '../../../assets/svgs/indicators/spinner-white-small.svg'

class Navigation extends PureComponent {
    navigationRef = createRef()

    componentDidMount() {
        if (this.props.isTop && window.navigationObserver)
        {
            window.navigationObserver.observe(this.navigationRef.current)
        }
    }

    componentWillUnmount() {
        if (this.props.isTop && window.navigationObserver)
        {
            window.navigationObserver.unobserve(this.navigationRef.current)
        }
    }

    render(){
        const hideSummary = typeof this.props.hideSummary === 'undefined' || this.props.hideSummary

        let className = 'navigation'
        if (this.props.className)
        {
            className += ' '+this.props.className
        }

        let backButtonLabel = 'Back'
        if (typeof this.props.renameBackNavLabelToServices !== 'undefined' && this.props.renameBackNavLabelToServices)
            backButtonLabel = 'Services'

        return (
            <div className={className} ref={this.navigationRef}>
                {hideSummary && !this.props.hideBack ?
                    <button className="back btn-primary" onClick={() => this.props.handleNavigation('back')}>
                        <div>
                            <BackArrow />
                            <span>{backButtonLabel}</span>
                        </div>
                    </button>
                : null}

                {(hideSummary && !this.props.hideNext) || !hideSummary ?
                    <button className={!hideSummary ? "next btn-primary summary" : "next btn-primary"} onClick={() => this.props.handleNavigation(hideSummary ? 'next' : 'summary')}>
                        <div>
                            <span>{hideSummary ? 'Next' : 'Update'}</span>
                            {this.props.loadingForward ? <div className="spinner-nav-container"><SpinnerNav/></div> : <ForwardArrow/>}
                        </div>
                    </button>
                : null}
            </div>
        )
    }
}

export default Navigation