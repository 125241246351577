import React, { useState } from "react";
import {DayPicker} from 'react-day-picker'
import 'react-day-picker/style.css'

import CalendarLeftArrow from "../../../assets/svgs/calendar/left.svg"
import CalendarRightArrow from "../../../assets/svgs/calendar/right.svg"

import '../../../assets/scss/lib/_react-day-picker.scss'

export default function DayPickerCustom(props) {

    const [currentMonth, setCurrentMonth] = useState(props.month || new Date());

    return (
        <DayPicker
            mode="single"
            onDayClick={props.onDayClick}
            selectedDays={props.selectedDays}
            disabled={props.disabled}
            defaultMonth={props.month}
            {...props}
        />
    )
}

