import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import Navigation from "../../common/navigation"
import Checkbox from "../../common/checkbox"
import Input from "../../common/input/input"
import Header from "../../common/header"
import CheckboxRadio from "../../common/checkbox-radio"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import Dropdown from "../../common/dropdown"
import Alert from "../../common/alert"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Link from "../../common/link/link"
import Tooltip from "../../common/tooltip"
import MandatoryIndicator from "../../common/mandatory-indicator"
import validator from "../../../services/validator/validator"
import Textarea from "../../common/textarea/textarea"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: existsGet(state, 'services', null),
        propertyType: existsGet(state, 'propertyType', null),
        electricity: {
            maximumDemandAmps: existsGet(state, 'electricity.maximumDemandAmps', ''),
            maximumDemandKW: existsGet(state, 'electricity.maximumDemandKW', ''),
            controlledLoad: existsGet(state, 'electricity.controlledLoad', false),
            fuseCurrentRatings: existsGet(state, 'electricity.fuseCurrentRatings', ''),
            serviceMain: existsGet(state, 'electricity.serviceMain', null)
        },
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        alteration: {
            riskCategory: existsGet(state, 'alteration.riskCategory'),
            workType: existsGet(state, 'alteration.workType'),
            description: existsGet(state, 'alteration.description', '')
        },
        project: {
            description: existsGet(state, 'project.description', ''),
        },
        subdivision: {
            connectionPoints: existsGet(state, 'subdivision.connectionPoints', '')
        }
    }
}

const validationMessages = {
    alteration_riskCategory: {
        'required': 'Please select a risk level.'
    },
    alteration_description: {
        'required': 'Please enter a description of work required.'
    },
    project_description: {
        'required': 'Please enter a description of work required.'
    },
    subdivision_connectionPoints: {
        'maxNumber': 'You can have a maximum of {int} lots that require power.'
    }
}

const content = require('../../../json/content/content.json')

class ManualCapacity extends Component {
    constructor(props) {
        super(props)

        this.hasNewConnection = getCheckedFields(this.props.connections).includes('electricity')

        this.validationErrorFields = {}

        if (this.isAlteration())
        {
            this.validationRules = {
                alteration: {
                    riskCategory: ['required'],
                    description: ['required']
                }
            }

            this.validationErrorFields = {
                alteration_riskCategoryErrors: [],
                alteration_descriptionErrors: []
            }
        }
        else if (this.isProject())
        {
            this.validationRules = {
                project: {
                    description: ['required']
                }
            }

            this.validationErrorFields = {
                project_descriptionErrors: []
            }
        }
        else if (this.isSubdivision())
        {
            this.validationRules = {
                subdivision: {
                    connectionPoints: [{'maxNumber': 1000}]
                }
            }

            this.validationErrorFields = {
                subdivision_connectionPointsErrors: []
            }
        }

        this.state = {
            ...this.validationErrorFields
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    isAlteration = () => {
        return this.props.connections.alterationIsChecked
    }

    isProject = () => {
        return this.props.connections.projectIsChecked
    }

    isSubdivision = () => {
        return this.props.connections.newSubdivisionIsChecked
    }

    isBallparkEstimate = () => {
        return this.props.connections.ballparkEstimateIsChecked
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        if (this.isAlteration() && !this.validate())
        {
            this.props.removeProgress({'path': '/manual-capacity'})
            return false
        }

        if (this.isProject() || this.isSubdivision())
        {
            if (!this.validate())
            {
                this.props.removeProgress({'path': '/manual-capacity'})
                return false
            }

            this.props.addProgress({'path': '/manual-capacity'})

            return '/other-information'
        }

        this.props.addProgress({'path': '/manual-capacity'})

        if (this.props.connections.onsiteGenerationIsChecked)
        {
            return '/distributed-generation-capacity'
        }

        const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)

        return nextNonConnectionService !== null ? nextNonConnectionService : '/other-information'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/manual-capacity'})

            if (this.isProject() || this.isSubdivision() || this.isBallparkEstimate())
            {
                this.props.updateLocation('/roles-hub', null, 'back')

                return true
            }

            const connections = this.props.connections
            if (connections.electricityIsChecked || connections.onsiteGenerationIsChecked || connections.newSubdivisionIsChecked || connections.alterationIsChecked || connections.btsIsChecked)
            {
                this.props.updateLocation('/approved-contractor', null, 'back')

                return true
            }

            this.props.updateLocation('/retailer', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validateAndGetNextLocation())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="manual-capacity" className="section-margin-bottom">
                <Header
                    title="Capacity and work requirements"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <div>
                                If you’re able to tell us your estimated electricity capacity and demand requirements please enter the information below. Your electrician can help you with this. <b>If you don’t know just press Next.</b>
                            </div>
                        } />

                        {this.hasNewConnection ?
                            <Alert type="description" text="Northpower will advise what phasing is available at this location." />
                        : null}

                        <div className="wrapper">

                            {this.isAlteration() ?
                                <CheckboxRadio
                                    label={<>Is the alteration work classed as high, general or low <span className='non-breaking'><MandatoryIndicator>risk?</MandatoryIndicator><Tooltip
                                        content={<>
                                            <ul className='no-bullets'>
                                                <li><b>Low risk work</b> - Replacement for repair or maintenance of a fitting in an existing installation. This work does not require an inspection.</li>
                                                <li><b>General risk work</b> - Usually this is installation work, including new additions, alterations, rewires, new and extended circuits. Sometimes this work requires inspection.</li>
                                                <li><b>High risk work</b> - Includes mains work, PV photovoltaic, medical, hazardous, mining and other specialty areas. All work of this nature requires an inspection.</li>
                                            </ul>
                                            <span>Read more about risk levels <Link text="here" link={links.alterationRiskLevels} />.</span>
                                        </>}
                                    /></span></>}
                                    keyString="alteration.riskCategory"
                                    updateStoreValue={this.props.updateStoreValue}
                                    value={this.props.alteration.riskCategory}
                                    checkboxes={
                                        [
                                            {'label': 'High', 'value': 'High'},
                                            {'label': 'General', 'value': 'General'},
                                            {'label': 'Low', 'value': 'Low'}
                                        ]
                                    }
                                    initialTitleColor='gray'
                                    parentErrorKey="alteration_riskCategoryErrors"
                                    setParentState={this.setParentState}
                                    validationMessages={validationMessages.alteration_riskCategory}
                                    validationErrorsFromParent={this.state.alteration_riskCategoryErrors}
                                />
                            : null}

                            {!this.isProject() && !this.isSubdivision() ?
                                <CheckboxRadio
                                    label="Service main"
                                    keyString="electricity.serviceMain"
                                    optional={true}
                                    updateStoreValue={this.props.updateStoreValue}
                                    value={this.props.electricity.serviceMain}
                                    checkboxes={
                                        [
                                            {'label': 'Overhead', 'value': 'Overhead'},
                                            {'label': 'Underground', 'value': 'Underground'}
                                        ]
                                    }
                                    initialTitleColor='gray'
                                />
                            : null}

                            <Input
                                label="Maximum Demand (kW) Total Estimate"
                                type="number"
                                maxLength={6}
                                keyString={"electricity.maximumDemandKW"}
                                value={this.props.electricity.maximumDemandKW}
                                updateStoreValue={this.props.updateStoreValue}
                            />

                            <Input
                                label="Maximum Capacity (Amps) Total Estimate"
                                type="number"
                                maxLength={6}
                                keyString={"electricity.maximumDemandAmps"}
                                value={this.props.electricity.maximumDemandAmps}
                                updateStoreValue={this.props.updateStoreValue}
                            />

                            {this.isProject() ?
                                <Textarea
                                    label="Description of work"
                                    mandatoryIndicator={true}
                                    keyString="project.description"
                                    parentErrorKey="project_descriptionErrors"
                                    value={this.props.project.description}
                                    validationMessages={validationMessages.project_description}
                                    validationErrorsFromParent={this.state.project_descriptionErrors}
                                    validationRules={this.validationRules.project.description}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />
                            : null}

                            {this.isSubdivision() ?
                                <Input
                                    label="How many lots require power?"
                                    keyString={"subdivision.connectionPoints"}
                                    parentErrorKey="subdivision_connectionPointsErrors"
                                    value={this.props.subdivision.connectionPoints}
                                    validationMessages={validationMessages.subdivision_connectionPoints}
                                    validationErrorsFromParent={this.state.subdivision_connectionPointsErrors}
                                    validationRules={this.validationRules.subdivision.connectionPoints}
                                    updateStoreValue={this.props.updateStoreValue}
                                    type="number"
                                    maxLength={3}
                                    setParentState={this.setParentState}
                                />
                            : null}

                            {!this.isProject() && !this.isSubdivision() ?
                                <Checkbox
                                    label="Controlled Load Required"
                                    keyString="electricity.controlledLoad"
                                    updateStoreValue={this.props.updateStoreValue}
                                    isChecked={this.props.electricity.controlledLoad}
                                />
                            : null}

                            {this.props.propertyType !== 'Caravan' && !this.isProject() && !this.isSubdivision() ?
                                <Dropdown
                                    label="Fuse ratings"
                                    keyString="electricity.fuseCurrentRatings"
                                    updateStoreValue={this.props.updateStoreValue}
                                    selectedOption={this.props.electricity.fuseCurrentRatings}
                                    options={
                                        [
                                            {'label': '30', 'value': '30'},
                                            {'label': '60', 'value': '60'},
                                            {'label': '80', 'value': '80'},
                                            {'label': '100', 'value': '100'},
                                            {'label': '160', 'value': '160'},
                                            {'label': '200', 'value': '200'},
                                            {'label': '250', 'value': '250'},
                                            {'label': '315', 'value': '315'},
                                            {'label': '400', 'value': '400'},
                                            {'label': '500', 'value': '500'},
                                            {'label': '630', 'value': '630'},
                                            {'label': '800', 'value': '800'},
                                            {'label': '1000', 'value': '1000'},
                                            {'label': '1250', 'value': '1250'},
                                            {'label': '1600', 'value': '1600'}
                                        ]
                                    }
                                    isSearchable={true}
                                    setParentState={this.setParentState}
                                />
                            : null}

                            {this.isAlteration() ?
                                <>
                                    <Dropdown
                                        label="Work Type"
                                        keyString="alteration.workType"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.alteration.workType}
                                        options={
                                            [
                                                {'label': 'Pole Top Connection', 'value': 'Pole Top Connection'},
                                                {'label': 'Overhead to Underground Conversion', 'value': 'Overhead to Underground Conversion'},
                                                {'label': 'Relocate Meter Station', 'value': 'Relocate Meter Station'},
                                                {'label': 'Upgrade/Relocate Switchboard', 'value': 'Upgrade/Relocate Switchboard'},
                                                {'label': 'Line Maintenance', 'value': 'Line Maintenance'},
                                                {'label': 'Inspection Only', 'value': 'Inspection Only'},
                                                {'label': 'Other', 'value': 'Other'}
                                            ]
                                        }
                                        isSearchable={true}
                                        setParentState={this.setParentState}
                                    />
                                    <Textarea
                                        label="Description of work required"
                                        keyString="alteration.description"
                                        mandatoryIndicator={true}
                                        value={this.props.alteration.description}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                        parentErrorKey="alteration_descriptionErrors"
                                        validationMessages={validationMessages.alteration_description}
                                        validationErrorsFromParent={this.state.alteration_descriptionErrors}
                                    />
                                </>
                            : null}
                        </div>
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <ManualCapacity ref={ref} {...props} />
    })
)