import React, {Component} from 'react'
import Button from "../../common/button"
class ContactRow extends Component {
    render(){
        let className = "contact-row"
        if (this.props.className)
        {
            className += " "+className
        }

        const type = this.props.type
        const hideEditButton = typeof this.props.hideEditButton !== 'undefined' && this.props.hideEditButton
        const showEdit = (type !== "your-details" || (type === "your-details" && this.props.detailsValidated)) && !hideEditButton
        const showAddYourDetails = type === 'your-details' && !showEdit

        if (showAddYourDetails)
        {
            className += ' has-add-your-details'
        }

        const editUrl = this.props.contactId === '0' ? '/roles-hub/contact-details/edit-your-details' : '/roles-hub/contact-details/edit-contact'
        const hideDeleteButton = typeof this.props.hideDeleteButton !== 'undefined' && this.props.hideDeleteButton

        return (
            <div className={className}>
                <div className="name-and-roles-wrapper">
                    <span className="name">{this.props.name}</span>
                </div>
                <div className="buttons-wrapper">
                    {showEdit ?
                        <Button text="Edit" action={() => this.props.goToContactDetailsSection(editUrl, this.props.contactId, this.props.role)} />
                    : null}

                    {this.props.handleDeleteClick && !hideDeleteButton ?
                        <Button text="Delete" className="delete" action={() => this.props.handleDeleteClick()} />
                    : null}

                    {showAddYourDetails ?
                        <Button text="Add your contact details" action={() => this.props.goToContactDetailsSection('/roles-hub/contact-details/your-details', this.props.contactId, this.props.role)} />
                    : null}
                </div>
            </div>
        )
    }
}

export default ContactRow