import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import doesHaveElectricalConnectionChecked from "../../../helpers/does-have-electrical-connection-checked"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Input from "../../common/input/input"
import Dropdown from "../../common/dropdown"
import Header from "../../common/header"
import Link from "../../../components/common/link"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import MandatoryIndicator from "../../common/mandatory-indicator"
import Tooltip from "../../common/tooltip"
import getIcpNumberHelperText from "../../../helpers/get-icp-number-helper-text"
import IcpNumber from "../../common/icp-number"
import shouldPreventNextIcpTriggerNavigation from "../../../helpers/should-prevent-next-icp-trigger-navigation"
import getCheckedFields from "../../../helpers/get-checked-fields"
import convertArrayToDropdownFormat from "../../../helpers/convert-array-to-dropdown-format"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: existsGet(state, 'services', null),
        electricity: {
            retailer: existsGet(state, 'electricity.retailer', null),
            electricityRetailerCustomerNumber: existsGet(state, 'electricity.electricityRetailerCustomerNumber', ''),
            icpNumber: existsGet(state, 'electricity.icpNumber', ''),
        },
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        content: {
            preferredRetailerPicklist: convertArrayToDropdownFormat(state.content.preferredRetailerPicklist, 'name')
        },        // start icp-number requirements
        icpNumbersApproved: existsGet(state, 'icpNumbersApproved', []),
        icpNumbersDenied: existsGet(state, 'icpNumbersDenied', []),
        icpNumberPendingApprovalLatest: existsGet(state, 'icpNumberPendingApprovalLatest', [])
        // end icp-number requirements
    }
}

const validationMessages = {
    electricityRetailer: {
        'required': 'Please select your electricity retailer.'
    }
}

const content = require('../../../json/content/content.json')

class Retailer extends Component {
    constructor(props) {
        super(props)

        this.validationRules = this.getValidationRules()

        this.validationErrorFields = {
            electricity_retailerErrors: [],
            electricity_icpNumberErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            render: false,
            // start icp-number requirements
            loadingForward: false,
            triggerIcpValidation: false,
            pendingIcpTriggerNavigation: false
            // end icp-number requirements
        }

        // start icp-number requirements
        this.preventNextIcpTriggerNavigation = false
        this.direction = 'next'
        // end icp-number requirements
    }

    componentDidMount() {
        const retailer = this.props.electricity.retailer
        if (retailer)
        {
            const hasValidRetailer = this.props.content.preferredRetailerPicklist.some(item => item.value === retailer)
            if (!hasValidRetailer)
            {
                this.props.updateStoreValue('electricity.retailer', null)
            }
        }
    }

    componentDidUpdate(prevProps) {
        // start icp-number requirements
        if (this.state.loadingForward)
        {
            const changeableValues = [
                'electricity.icpNumber',
                'electricity.retailer',
                'electricity.electricityRetailerCustomerNumber'
            ]

            if (shouldPreventNextIcpTriggerNavigation(changeableValues, prevProps, this.props))
            {
                this.preventNextIcpTriggerNavigation = true
            }
        }

        if (
            !this.preventNextIcpTriggerNavigation &&
            this.state.pendingIcpTriggerNavigation &&
            prevProps.icpNumbersApproved.length !== this.props.icpNumbersApproved.length
        )
        {
            this.setState({pendingIcpTriggerNavigation: false}, () => {
                this.handleNavigation(this.direction)
            })
        }

        if (this.state.pendingIcpTriggerNavigation && this.preventNextIcpTriggerNavigation)
        {
            this.setState({pendingIcpTriggerNavigation: false})
            this.preventNextIcpTriggerNavigation = false
        }
        // end icp-number requirements
    }

    getValidationRules = () => {
        let result = {}

        if (doesHaveElectricalConnectionChecked(this.props.connections))
        {
            result.electricity = {
                retailer: ['required']
            }
        }

        return result
    }

    // start icp-number requirements -- note addition of callback
    setParentState = (obj, callback = null) => {
        this.setState(obj, callback)
    }
    // end icp-number requirements

    shouldShowIcpNumber = () => {
        return this.props.connections.alterationIsChecked || this.props.connections.onsiteGenerationIsChecked
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()

        // start icp-number requirements
        if (
            this.shouldShowIcpNumber() &&
            (this.props.electricity.icpNumber.length > 0 || this.icpNumberRequired()) &&
            !this.props.icpNumbersApproved.includes(this.props.electricity.icpNumber)
        )
        {
            this.setState({triggerIcpValidation: true})
            this.props.removeProgress({'path': '/temporary-disconnection'})
            return false
        }
        // end icp-number requirements

        if (!passedParentValidation)
            return false

        // start icp-number requirements
        if (this.direction === 'summary')
            return '/summary'
        // end icp-number requirements

        return '/approved-contractor'
    }

    validateAndNavigate = () => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.addProgress({'path': '/retailer'})
            this.props.updateLocation(location, null, this.direction)

            return true
        }

        this.props.removeProgress({'path': '/retailer'})

        return false
    }

    handleNavigation = (direction) => {
        // start icp-number requirements
        this.direction = direction

        if (this.direction === 'next' || this.direction === 'summary' || this.direction === 'forward')
        {
            return this.validateAndNavigate()
        }
        // end icp-number requirements

        if (this.direction === 'back')
        {
            this.props.removeProgress({'path': '/retailer'})

            this.props.updateLocation('/roles-hub', null, 'back')

            return true
        }

        return false
    }

    icpNumberRequired = () => {
        const checkedFields = getCheckedFields(this.props.connections)
        return checkedFields.includes('alteration') // icpNumber is conditional on shouldShowIcpNumber() which currently only checks for alteration or onsiteGeneration, onsiteGeneration has icpNumber as optional.
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="retailer" className="section-margin-bottom">
                <Header
                    title="Retailer"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <div>
                                To help speed up your connection it’s helpful if we have information about your retailer. If you do not have a retailer you can use the <Link text='Powerswitch website' link={links.whatsMyNumber} /> to compare providers.
                            </div>
                        } />

                        <div className="wrapper">

                            <MandatoryText />
                            {doesHaveElectricalConnectionChecked(this.props.connections) ?
                                <>
                                    <Dropdown
                                        label={<>Electricity <MandatoryIndicator>Retailer</MandatoryIndicator></>}
                                        keyString={"electricity.retailer"}
                                        parentErrorKey="electricity_retailerErrors"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.electricity.retailer}
                                        options={this.props.content.preferredRetailerPicklist}
                                        isSearchable={true}
                                        validationMessages={validationMessages.electricityRetailer}
                                        validationErrorsFromParent={this.state.electricity_retailerErrors}
                                        validationRules={this.validationRules.electricity.retailer}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Electricity Retailer Customer Number"
                                        keyString={"electricity.electricityRetailerCustomerNumber"}
                                        value={this.props.electricity.electricityRetailerCustomerNumber}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />
                                </>
                            : null}

                            {this.shouldShowIcpNumber() ?
                                <IcpNumber
                                    label={<>ICP Number{this.icpNumberRequired() ? <MandatoryIndicator /> : null}<Tooltip content={<p>
                                        {getIcpNumberHelperText()}
                                    </p>} /></>}
                                    keyString={"electricity.icpNumber"}
                                    parentErrorKey="electricity_icpNumberErrors"
                                    value={this.props.electricity.icpNumber}
                                    required={this.icpNumberRequired()}
                                    validationErrorsFromParent={this.state.electricity_icpNumberErrors}
                                    validationRules={this.validationRules.electricity.icpNumber}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                    parentHasErrors={showValidationError}
                                    loadingForward={this.state.loadingForward}
                                    triggerIcpValidation={this.state.triggerIcpValidation}
                                    icpNumbersApproved={this.props.icpNumbersApproved}
                                    icpNumbersDenied={this.props.icpNumbersDenied}
                                    icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                                />
                            : null}
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <Retailer ref={ref} {...props} />
    })
)