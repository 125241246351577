export default (date) => {

    // often called in render (close-approach-consent & temporary disconnection), but date is unselectable so can be null
    if (typeof date === 'undefined' || date === null)
    {
        return ''
    }

    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ]

    const day = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return day + ' ' + monthNames[monthIndex] + ' ' + year
}