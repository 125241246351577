import React from "react"
import ReactDOM from "react-dom/client"
import App from "./components/app"
import configureStore from './store/create-store'

const store = configureStore()

__webpack_public_path__ = "http://localhost:8080/"

if (window.browserIsSupported)
{
    console.info('Version: '+process.appVersion)

    const root = ReactDOM.createRoot(document.getElementById("app"));
    root.render(<App store={store} />);

    if (module.hot) {
        module.hot.accept('./components/app', () => {
            const NextApp = require('./components/app').default
            const root = ReactDOM.createRoot(document.getElementById("app"));
            root.render(<NextApp store={store} />);
        })
    }
}
else
{
    // main.scss sets overflowY to hidden, but the browser not supported html needs it set to auto
    document.body.style.overflowY = "auto"
}
