import getAllRoles from "./get-all-roles"
import PropTypes from 'prop-types'

const getRemainingRoles = (roles, connectionTypes) => {
    let roleNames = getAllRoles(connectionTypes)
    if (roles.length === 0)
    {
        return roleNames
    }

    Object.keys(roles).map((contactId) => {
        const contactRoles = roles[contactId]
        contactRoles.map((roleName) => {
            roleNames = roleNames.filter((name) => name !== roleName)
        })
    })

    return roleNames
}

getRemainingRoles.propTypes = {
    roles: PropTypes.object.isRequired,
    connectionTypes: PropTypes.array.isRequired
}

export default getRemainingRoles