import getCheckedFields from "./get-checked-fields"
import checkIsIrrelevantService from "./check-is-irrelevant-service"

export default (fields) => {
    let result = []
    const checkedFields = getCheckedFields(fields)

    if (checkedFields.length === 0)
    {
        return result
    }

    result = checkedFields.filter(name => !checkIsIrrelevantService(name))

    return result
}