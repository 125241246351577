import React, {PureComponent} from 'react'
import PropTypes from "prop-types"

class StandardTable extends PureComponent {
    constructor(props) {
        super(props)
    }

    generateKey = (type, index) => {
        return this.props.uniqueTableName+'_'+type+'_'+index
    }

    render(){
        let mainClass = 'table'

        let tableHeaderClass = ''
        let tableFootersClass = ''
        let tableRowClass = ''

        if (typeof this.props.table.classes !== 'undefined')
        {
            mainClass = this.props.table.classes
        }

        if (typeof this.props.tableHeaders.classes !== 'undefined')
        {
            tableHeaderClass = this.props.tableHeaders.classes
        }

        if (typeof this.props.tableRows.classes !== 'undefined')
        {
            tableRowClass = this.props.tableRows.classes
        }

        if (this.props.tableFooters && typeof this.props.tableFooters.classes !== 'undefined')
        {
            tableFootersClass = this.props.tableFooters.classes
        }

        return (
            <table className={mainClass}>
                <thead className={tableHeaderClass}>
                    <tr>
                        {this.props.tableHeaders.headers.map((title, index) => {
                            return <th key={this.generateKey('table_header', index)} scope="col">{title}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {this.props.tableRows.rows.map((rows, tableRowIndex) => {
                        return <tr className={tableRowClass} key={this.generateKey('table_row', tableRowIndex)}>
                            {rows.map((row, tableDataIndex) => {
                                return <td key={this.generateKey('table_data', ""+tableRowIndex+tableDataIndex)}>{row}</td>
                            })}
                        </tr>
                    })}
                </tbody>
                <tfoot className={tableFootersClass}>
                    {this.props.tableFooters ?
                        <tr>
                            {this.props.tableFooters.rows.map((title, index) => {
                                return <th key={this.generateKey('bottom_table_row', index)} scope="col">{title}</th>
                            })}
                        </tr>
                    : null}
                </tfoot>
            </table>
        )
    }
}

StandardTable.propTypes = {
    uniqueTableName: PropTypes.string.isRequired,
    tableHeaders: PropTypes.object.isRequired,
    tableRows: PropTypes.object.isRequired,
}
export default StandardTable