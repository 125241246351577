export default (date) => {
    if (date === null)
    {
        return null
    }

    // done manually for better browser compatibility
    const dateDay = date.getDate()
    const day = ("0" + dateDay).slice(-2)
    const dateMonth = date.getMonth()+1
    const month = ("0" + dateMonth).slice(-2)
    const year = date.getFullYear()

    return day+'/'+month+'/'+year
}