import React from 'react'

export default <div>
    <ol>
        <li>
            <b>Purpose of application:</b> your application gives us information to assess whether and on what conditions we can connect your electrical installation to our electricity distribution network. It is important that you complete all questions with as much detail as possible. If in doubt, ask your electrician to assist or contact us for guidance.  We do not sell and supply electricity to you so you will need to separately contact an electricity retailer to arrange for that. Northpower will issue you an ICP number. You can call your retailer to arrange metering and quote this ICP number. Northpower will also supply this ICP number to your retailer.
        </li>
        <li>
            <b>Privacy:</b> the information you provide to us may be passed on to the electricity retailer that you nominate. We will comply with our privacy statement which is available on our website.
        </li>
        <li>
            <b>Our response:</b> we aim to respond to applications within 10 working days.  This is an indicative timeframe only and it can vary depending on our workload at the time and what other information we might need from you. If your application is non-standard (for example if it will require us to upgrade or extend our network), then we will explain in our response any additional steps and arrangements that you will need to undertake.
        </li>
        <li>
            <b>Our requirements:</b> if we accept your application, we will tell you what our requirements and other terms and conditions are and you will have six (6) months from the date of approval to arrange the connection.  One of our requirements is that your electrical installation is safe and complies with all current laws, regulations, including the Electricity Act 1992, the Electricity (Safety) Regulations 2010 and AS/ANZ 3000:2018 Australian/New Zealand Wiring Rules.
        </li>
        <li>
            <b>Your feedback:</b> once your new connection is complete, we may contact you to ask if you are happy with the service you received and your new connection.
        </li>
    </ol>
</div>