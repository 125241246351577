import send from "./send"
import getCheckedFieldsRelevantServices from "../../helpers/get-checked-fields-relevant-services"
import formatDateStandard from "../../helpers/date/format-date-standard"
import showShowPropertyType from "../../helpers/should-show-property-type"
import getCheckedFields from "../../helpers/get-checked-fields"
import formatTimepickerTime from "../../helpers/format-timepicker-time"
import getExpectedUploadNames from "../../helpers/get-expected-upload-names";

function getServiceData(serviceName, service){
    let result = {}

    if (serviceName === 'vegetationControl')
    {
        result['lineType'] = service['lineType']
        result['issue'] = service['issue']
        result['previousWorkCompleted'] = service['previousWorkCompleted']
    }
    else if (serviceName === 'closeApproachConsent')
    {
        result['description'] = service['description']
        result['preferredWorkStartTime'] = formatTimepickerTime(service['preferredWorkStartTime'], true)
        result['preferredWorkEndTime'] = formatTimepickerTime(service['preferredWorkEndTime'], true)
        result['workEndDateRequested'] = formatDateStandard(service['workEndDateRequested'])
    }
    else if (serviceName === 'cableLocation')
    {
        result['beforeUDigReferenceNumber'] = service['beforeUDigReferenceNumber']
        result['description'] = service['description']
        result['serviceToLocateFibre'] = service['serviceToLocateFibre'] ? 'Yes' : 'No'
        result['serviceToLocateElectricity'] = service['serviceToLocateElectricity'] ? 'Yes' : 'No'
    }
    else if (serviceName === 'safetyDisconnection')
    {
        result['electricalWork'] = service['electricalWork']
        if (service['electricalWork'] === 'Yes')
        {
            result['electricalWorkWho'] = service['electricalWorkWho']
            result['electricalWorkRisk'] = service['electricalWorkRisk']
        }
        result['description'] = service['description']
        result['reconnectionDateRequested'] = formatDateStandard(service['reconnectionDateRequested'])
        result['preferredDisconnectionTime'] = formatTimepickerTime(service['preferredDisconnectionTime'], true)
        result['preferredReconnectionTime'] = formatTimepickerTime(service['preferredReconnectionTime'], true)
        result['electricianName'] = service['electricianName']
        result['electricianPhone'] = service['electricianPhone']
        result['icpNumber'] = service['icpNumber']
    }
    else if (serviceName === 'generalEnquiry')
    {
        result['detailsOfEnquiry'] = service['detailsOfEnquiry']
        result['typeOfEnquiry'] = service['typeOfEnquiry']
        result['icpNumber'] = service['icpNumber']
    }

    return result
}

export default (props) => {
    const data = props.data
    const yourDetails = data.contacts[0]

    const checkedConnections = getCheckedFields(data['connections'])

    const {
        firstName,
        lastName,
        phoneNumber,
        mobileNumber,
        emailAddress,
        companyName,
        preferredMethodOfContact
    } = yourDetails

    const {
        unit,
        number,
        street,
        suburb,
        city,
        postcode,
        dairyNumber,
        lotNumber,
        dpNumber,
        legalDescription,
        geolocationLatitude,
        geolocationLongitude,
        enquiryNotRelatedToSiteLocation
    } = data.serviceAddress

    let mailingAddress = {}

    if (yourDetails.addressNumber) {
        mailingAddress = {
            number: yourDetails.addressNumber,
            street: yourDetails.addressStreet,
            suburb: yourDetails.addressSuburb,
            city: yourDetails.addressCity,
            postcode: yourDetails.addressPostcode
        }
    }

    let payload = {
        version: process.appVersion,
        yourDetails: {
            firstName,
            lastName,
            phoneNumber,
            mobileNumber,
            emailAddress,
            companyName,
            preferredMethodOfContact,
            mailingAddress
        },
        serviceAddress: {
            unit: unit,
            number: number,
            street: street,
            suburb: suburb,
            city: city,
            postcode: postcode,
            dairyNumber: dairyNumber,
            lotNumber: lotNumber,
            dpNumber: dpNumber,
            geolocationLatitude: geolocationLatitude,
            geolocationLongitude: geolocationLongitude,
            legalDescription: legalDescription,
            propertyType: showShowPropertyType(checkedConnections) ? data.propertyType : '',
            enquiryNotRelatedToSiteLocation: enquiryNotRelatedToSiteLocation
        },
        otherInformation: {},
        service: {}
    }

    if (typeof data.services.generalEnquiryIsChecked === 'undefined')
    {
        payload.otherInformation = {
            locationInformation: data.otherInformation && data.otherInformation.locationInformation ? data.otherInformation.locationInformation : ''
        }
    }
    else
    {
        payload.otherInformation.expectedUploadNames = getExpectedUploadNames(data)
    }

    const checkedFields = getCheckedFieldsRelevantServices(data.services)

    checkedFields.map((serviceName) => {
        let serviceData = getServiceData(serviceName, data[serviceName])

        if (typeof data.services[serviceName+'DateRequested'] !== 'undefined')
        {
            serviceData['dateRequested'] = formatDateStandard(data.services[serviceName+'DateRequested'])
        }

        payload.service[serviceName] = serviceData
    })

    return send(process.env.CTP_ENDPOINT+'/service/non-connection', payload)
}