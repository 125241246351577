export default (value) => {
    const mobilePhone = /^(0|64)?(20|21|22|26|27|28|29)[0-9]{6,8}$/
    const standardPhone = /^(0|64)?(3|4|6|7|9)[0-9]{7}$/
    const freePhone = /^(0508|0800)[0-9]{6,7}$/

    if (value === null)
    {
        return false
    }

    const phoneValue = value.replace(/[^0-9]/g, '')
    return standardPhone.test(phoneValue) || freePhone.test(phoneValue) || mobilePhone.test(phoneValue)
}