import React, {Component} from "react"
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import Header from "../../common/header"
import sendNonConnectionPayload from "../../../services/endpoint/send-non-connection-payload"
import sendConnectionPayload from "../../../services/endpoint/send-connection-payload"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Spinner from "../../../assets/svgs/indicators/spinner-large.svg"
import getCheckedFieldsRelevantServices from "../../../helpers/get-checked-fields-relevant-services"
import Alert from "../../common/alert"
import Button from "../../common/button"
import sendUploadPayload from "../../../services/endpoint/send-upload-payload"
import getServiceCheckboxes from "../../../services/general/get-service-checkboxes-data"

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

class Submit extends Component {
    constructor(props){
        super(props)

        this.checkedConnectionServices = getCheckedFields(this.props.data.connections)

        this.hasConnectionService = this.checkedConnectionServices.length > 0
        this.hasNonConnectionService = getCheckedFieldsRelevantServices(this.props.data.services).length > 0

        this.jobUuid = null
        this.jobUuidsForUploads = []
        this.paymentLinkUuid = null

        let pendingUploadIds = []
        if (this.hasUploadObject() && (this.hasConnectionService || this.isGeneralEnquiry()))
        {
            pendingUploadIds = Object.keys(this.props.data.otherInformation.uploads)
        }

        let pendingPayloads = []

        if (this.hasConnectionService)
        {
            pendingPayloads.push('connections')
        }

        if (this.hasNonConnectionService)
        {
            pendingPayloads.push('services')
        }

        this.state = {
            pendingUploadIds: pendingUploadIds,
            hasNonConnectionServiceError: false,
            hasConnectionServiceError: false,
            pendingPayloads: pendingPayloads,
            failedUploadIds: [],
            alreadyPaid: false
        }
    }

    componentDidMount() {
        this.submit()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.pendingPayloads.length !== this.state.pendingPayloads.length || prevState.pendingUploadIds.length !== this.state.pendingUploadIds.length) && this.state.pendingPayloads.length === 0 && this.state.pendingUploadIds.length === 0)
        {
            const onlyProject = this.checkedConnectionServices.includes('project') && this.checkedConnectionServices.length === 1

            if (!this.hasConnectionService || onlyProject)
            {
                this.props.addProgress({'path': '/complete'})
                this.props.updateLocation('/complete')
            }
            else
            {
                this.props.addProgress({'path': '/pay'})
                this.props.updateLocation('/pay/'+this.paymentLinkUuid)
            }
        }
    }

    hasUploadObject = () =>
    {
        return this.props.data.otherInformation && typeof this.props.data.otherInformation.uploads === "object" && this.props.data.otherInformation.uploads !== null && Object.keys(this.props.data.otherInformation.uploads).length > 0
    }

    handleNavigation = (direction) => {
        if (direction === 'back')
        {
            this.props.updateLocation('/summary')
        }
    }

    sendUploads = () => {
        // failedUploadIds set must be here, as there's a check for failedUploads in this.submit()
        this.setState({failedUploadIds: []}, () => {
            if (this.hasUploadObject() && this.jobUuidsForUploads.length > 0 && Object.keys(this.state.pendingUploadIds).length > 0)
            {
                this.state.pendingUploadIds.forEach((uploadId) => {
                    const upload = this.props.data.otherInformation.uploads[uploadId]
                    sendUploadPayload(upload, this.jobUuidsForUploads).then((res) => {
                        if (res.success)
                        {
                            this.setState((state) => {
                                let updatedState = state.failedUploadIds.includes(uploadId) ? {failedUploadIds: state.failedUploadIds.filter(id => id !== uploadId)} : {}
                                return {...updatedState, pendingUploadIds: state.pendingUploadIds.filter(uploadKey => uploadKey !== uploadId)}
                            })
                        }
                        else
                        {
                            this.addFailedUploadId(uploadId)
                        }
                    }).catch(() => {
                        this.addFailedUploadId(uploadId)
                    })
                })
            }
        })
    }

    isGeneralEnquiry = () => {
        return typeof this.props.data.services.generalEnquiryIsChecked !== 'undefined' && this.props.data.services.generalEnquiryIsChecked
    }

    addFailedUploadId = (uploadId) => {
        if (!this.state.failedUploadIds.includes(uploadId))
        {
            this.setState({failedUploadIds: [...this.state.failedUploadIds, uploadId]})
        }
    }

    getServiceIndividualList = (type) => {
        const service = getCheckedFieldsRelevantServices(this.props.data[type])
        const serviceCheckboxesData = getServiceCheckboxes(this.props.data)

        return <>
            {
                service.map((shortName, i) => {
                    const longName = serviceCheckboxesData[type][shortName]['label']
                    return <li key={'sil_'+i}>{longName}</li>
                })
            }
        </>
    }

    getServiceList = () => {
        let services = null
        let connections = null
        if (this.hasConnectionService)
        {
            connections = this.getServiceIndividualList( 'connections')
        }

        if (this.hasNonConnectionService)
        {
            services = this.getServiceIndividualList('services')
        }

        return {
            connections: connections,
            services: services
        }
    }

    handleRetry = () => {
        // note: failedUploadIds gets reset in this.sendUploads(), can't be here because submit() runs a check
        this.setState({hasConnectionServiceError: false, hasNonConnectionServiceError: false}, () => this.submit())
    }

    handleConnectionServicePayload = () => {
        sendConnectionPayload(this.props).then((res) => {
            if (res.success)
            {
                this.jobUuidsForUploads = [res.jobUuid] // only ever one jobUuid, but needs to be an array
                this.paymentLinkUuid = res.paymentLinkUuid
                this.jobUuid = res.jobUuid

                if (
                    this.props.data.uploadsModifiedAfterSubmitPage === null ||
                    this.props.data.uploadsModifiedAfterSubmitPage === true
                )
                {
                    // failed uploads are handled elsewhere
                    if (!this.hasFailedUploads())
                    {
                        this.sendUploads()
                    }
                }
                else
                {
                    this.setState({pendingUploadIds: []})
                }

                // defaults to null, and is null before this is set, there's a null check in the sendConnectionPayload function
                this.props.updateStoreValue('uploadsModifiedAfterSubmitPage', false)

                this.setState({pendingPayloads: this.state.pendingPayloads.filter(status => status !== 'connections'), hasConnectionServiceError: false})
            }
            else
            {
                let updatedState = {}
                if (!this.state.hasConnectionServiceError)
                    updatedState.hasConnectionServiceError = true

                if (typeof res.alreadyPaid !== 'undefined' && res.alreadyPaid === 1)
                    updatedState.alreadyPaid = true

                this.setState(updatedState)
            }
        }).catch((reason) => {
            console.info('Connection payload submission exception')
            console.info(reason)
            if (!this.state.hasConnectionServiceError)
            {
                this.setState({hasConnectionServiceError: true})
            }

            this.props.bugsnagNotify('Connection payload submission exception', this.state, reason)
        })
    }

    handleNonConnectionServicePayload = () => {
        sendNonConnectionPayload(this.props).then((res) => {
            if (res.success)
            {
                if (this.isGeneralEnquiry())
                {
                    this.jobUuidsForUploads = res.jobUuids // as general enquiry is solo, we're not going to run into race conditions etc.

                    // failed uploads are handled elsewhere
                    if (!this.hasFailedUploads())
                    {
                        this.sendUploads()
                    }
                }

                this.setState({pendingPayloads: this.state.pendingPayloads.filter(status => status !== 'services'), hasNonConnectionServiceError: false})
            }
            else if (!this.state.hasNonConnectionServiceError)
            {
                this.setState({hasNonConnectionServiceError: true})
            }
        }).catch((reason) => {
            console.info('Enquiry payload submission exception')
            console.info(reason)
            if (!this.state.hasNonConnectionServiceError)
            {
                this.setState({hasNonConnectionServiceError: true})
            }

            this.props.bugsnagNotify('Enquiry payload submission exception', this.state, reason)
        })
    }

    submit = () => {
        if (this.hasNonConnectionService && this.state.pendingPayloads.includes('services'))
        {
            this.handleNonConnectionServicePayload()
        }

        if (this.hasConnectionService && this.state.pendingPayloads.includes('connections'))
        {
           this.handleConnectionServicePayload()
        }

        if (this.state.pendingPayloads.length === 0 && this.hasFailedUploads())
        {
            this.sendUploads()
        }
    }

    hasFailedUploads = () => {
        return this.state.pendingUploadIds.length > 0 && this.state.failedUploadIds.length === this.state.pendingUploadIds.length
    }

    hasPayloadError = () => {
        const noConnectionServicePayloadError = !this.hasConnectionService || !this.state.hasConnectionServiceError
        const noNonConnectionServicePayloadError = !this.hasNonConnectionService || !this.state.hasNonConnectionServiceError

        // wont say there's an error until all pending jobs/enquiries have completed
        let jobErrorCount = () => {
            let result = 0
            if (!noConnectionServicePayloadError)
            {
                result++
            }

            if (!noNonConnectionServicePayloadError)
            {
                result++
            }

            return result
        }

        const pendingPayloadsCount = this.state.pendingPayloads.length

        return pendingPayloadsCount > 0 && jobErrorCount() === pendingPayloadsCount && (this.state.hasConnectionServiceError || this.state.hasNonConnectionServiceError || this.state.pendingUploadIds.length === 0 || this.hasFailedUploads())
    }

    getErrorInfo = (hasConnectionServiceOrNonConnectionServiceError) => {
        const retryText = hasConnectionServiceOrNonConnectionServiceError ? 'We were unable to process your submission, please check your internet connection and try the Retry button below to resubmit.' : 'Please check your internet connection and try the Retry button below to resubmit.'
        const standardMarkup = <>If the problem continues please call us on <span>Phone: <a href="tel:0800 667 847" title="Call Northpower - Freephone" target="_blank">0800 667 847</a></span>.</>

        return <Alert type='info'>
            <div>
                {retryText} {standardMarkup}
            </div>
        </Alert>
    }

    getErrorMessages = () => {
        const serviceList = this.getServiceList()
        const hasFailedUploads = this.hasFailedUploads()
        const hasUploads = this.hasUploadObject()

        const hasNonConnectionServiceAndNoError = this.hasNonConnectionService && !this.state.hasNonConnectionServiceError
        const hasConnectionServiceAndNoError = this.hasConnectionService && !this.state.hasConnectionServiceError
        const hasConnectionServiceOrNonConnectionServiceError = this.state.hasConnectionServiceError || this.state.hasNonConnectionServiceError
        const hasAnyError = hasConnectionServiceOrNonConnectionServiceError || (hasUploads && hasFailedUploads)

        return <>
            {this.state.hasConnectionServiceError || this.state.hasNonConnectionServiceError ?
                <Alert type='danger'>
                    There was an issue submitting the following:
                    <ul>
                        {this.state.hasConnectionServiceError ?
                            serviceList['connections']
                        : null}
                        {this.state.hasNonConnectionServiceError ?
                            serviceList['services']
                        : null}
                    </ul>
                </Alert>
            : null}

            {/* This is only relevant if there's both connection & non-connection services, i.e. with standard/BTS + a non-connection service. */}
            {hasNonConnectionServiceAndNoError || hasConnectionServiceAndNoError ?
                <Alert type="warning">
                    {hasConnectionServiceAndNoError ?
                        <>
                            <span>{!hasFailedUploads ? 'The following services require payment before being processed:' : 'The following services require uploads to be submitted before being processed:'}</span>
                            <ul className={hasNonConnectionServiceAndNoError ? 'mb-default' : null}>
                                {serviceList['connections']}
                            </ul>
                        </>
                    : null}
                    {hasNonConnectionServiceAndNoError ?
                        <>
                            <span>{!hasFailedUploads ? 'The following services were successfully submitted:' : 'The following services were successfully submitted, but some uploaded files are missing:'}</span>
                            <ul>
                                {serviceList['services']}
                            </ul>
                        </>
                    : null}
                </Alert>
            : null}

            {hasFailedUploads ?
                <Alert type='danger'>
                    <span>The following {this.state.failedUploadIds.length > 1 ? 'files' : 'file'} failed to upload:</span>
                    <ul>
                        {this.state.failedUploadIds.map((id) => {
                            return <li key={id}>{this.props.data.otherInformation.uploads[id]['name']}</li>
                        })}
                    </ul>
                </Alert>
            : null}

            {this.state.alreadyPaid ?
                <Alert type='info'>
                    You have already paid for this application so you cannot modify it, you will need to submit a new application for any new jobs.
                </Alert>
            : null}

            {!this.state.alreadyPaid && hasAnyError ?
                this.getErrorInfo(hasConnectionServiceOrNonConnectionServiceError)
            : null}
        </>
    }

    getTitle = () => {
        if (this.hasPayloadError())
        {
            return "Submission failed"
        }

        if (this.hasFailedUploads())
        {
            return "File upload failure"
        }

        const onlyProject = this.checkedConnectionServices.includes('project') && this.checkedConnectionServices.length === 1
        if (this.hasConnectionService && !onlyProject)
        {
            return "Make a payment"
        }

        return "Loading"
    }

    render() {
        const hasPayloadError = this.hasPayloadError()
        const hasFailedUploads = this.hasFailedUploads()

        return (
            <div id="submit">
                <Header
                    title={this.getTitle()}
                />

                <div className="section">
                    <div className="container single-col">
                        {!hasPayloadError && !hasFailedUploads ?
                            <div className="submit-icon-container submit-icon-container--is-spinning">
                                <Spinner />
                            </div>
                        : null}

                        {hasPayloadError || hasFailedUploads ?
                            <>
                                <div className="validation-top-wrapper">
                                    <Alert text='There was an issue with your submission.' type="danger" />
                                </div>
                                <div className="wrapper">
                                    {this.getErrorMessages()}
                                    {!this.state.alreadyPaid ?
                                        <div className="button-wrapper">
                                            <Button text="Retry" btnType="btn-primary" action={this.handleRetry} />
                                        </div>
                                    : null}
                                </div>
                            </>
                        : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <Submit ref={ref} {...props} />
    })
)