export default (checkedConnections) => {
    if (checkedConnections.length === 1 && (checkedConnections.includes('newSubdivision') || checkedConnections.includes('bts')))
    {
        return false
    }
    else if (checkedConnections.length === 2 && (checkedConnections.includes('newSubdivision') && checkedConnections.includes('bts')))
    {
        return false
    }

    return true
}