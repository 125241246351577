import React, {Component} from 'react'

class Map extends Component {
    constructor(props){
        super(props)

        this.map = null
        this.marker = null

        this.markerListener = null
        this.mapRef = React.createRef()
        this.defaultLatLng = {lat: -35.720974, lng: 174.324893}
    }

    componentDidMount = () => {
        const zoom = this.getZoom()
        const latLng = this.getLatLng()
        const lat = latLng.lat
        const lng = latLng.lng

        const mapStyles = [
            {
                "featureType": "transit",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi",
                "stylers": [
                    { "visibility": "off" }
                ]
            }
        ]

        let mapSettings = {
            center: {lat: lat, lng: lng},
            zoom: zoom,
            streetViewControl: false,
            clickableIcons: false,
            styles: mapStyles,
            draggable: true,
            disableDefaultUI: false,
            gestureHandling: 'cooperative'
        }

        this.map = new google.maps.Map(this.mapRef.current, mapSettings)

        if (!this.isDefaultLatLng(lat, lng))
        {
            this.initializeMarker()
        }
    }

    componentWillUnmount() {
        if (this.map !== null)
        {
            google.maps.event.clearInstanceListeners(this.map)
        }

        if (this.markerListener !== null)
        {
            google.maps.event.removeListener(this.markerListener)
        }
    }

    componentDidUpdate(prevProps) {
        const zoom = this.getZoom()

        if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng)
        {
            const latLng = this.getLatLng()
            const lat = latLng.lat
            const lng = latLng.lng
            const googleLatLng = new google.maps.LatLng(lat, lng)

            if (!this.isDefaultLatLng(lat, lng))
            {
                if (this.marker === null)
                {
                    this.map.setZoom(zoom)
                    this.initializeMarker()
                }

                this.marker.setPosition(googleLatLng)
                this.map.panTo(googleLatLng)
            }
        }
    }

    initializeMarker = () => {
        const latLng = this.getLatLng()
        const lat = latLng.lat
        const lng = latLng.lng
        const googleLatLng = new google.maps.LatLng(lat, lng)

        this.marker = new google.maps.Marker({
            position: googleLatLng,
            draggable: true,
            animation: google.maps.Animation.DROP,
            map: this.map
        })

        this.markerListener = google.maps.event.addListener(this.marker, 'dragend', () => {
            const position = this.marker.getPosition()
            const latLng = {lat: position.lat(), lng: position.lng()}

            this.props.updateStoreValue('serviceAddress', {
                ...this.props.serviceAddress,
                geolocationLatitude: latLng.lat,
                geolocationLongitude: latLng.lng
            })
        })
    }

    getLatLng = () => {
        const lat = parseFloat(this.props.lat) || this.defaultLatLng.lat
        const lng = parseFloat(this.props.lng) || this.defaultLatLng.lng
        return {lat, lng}
    }

    isDefaultLatLng = (lat, lng) => {
        return lat === this.defaultLatLng.lat && lng === this.defaultLatLng.lng
    }

    getZoom = () => {
        const latLng = this.getLatLng()
        const lat = latLng.lat
        const lng = latLng.lng

        let baseZoom = this.props.isProject ? 14 : 16

        return !this.isDefaultLatLng(lat, lng) ? baseZoom : 10
    }

    render() {
        return (
            <div id="map" ref={this.mapRef} />
        )
    }
}

export default Map