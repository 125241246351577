import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../reducers'
import defaultReduxState from './default-redux-state'

const store = configureStore({
    reducer: rootReducer,
    preloadedState: defaultReduxState,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
        serializableCheck: false,
    })
})

export default () => {
    if (module.hot) {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(rootReducer)
        })
    }

    return store
}