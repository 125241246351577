import removeItemsFromArray from '../helpers/remove-items-from-array'
import existsGet from "../helpers/exists-get"
import getProgressWithoutNonConnectionServices from "../helpers/get-progress-without-non-connection-services"
import {produce} from "immer"

export default (state = {}, action) => {
    let value = existsGet(action, 'payload.value', false)
    const keepUnchanged = existsGet(action, 'payload.keepUnchanged')

    // e.g. for serviceAddress where we want to keep what was set in create-store so manual-address-input doesn't error out
    if (keepUnchanged)
    {
        value = Object.assign({}, state[keepUnchanged], action.payload.value)
    }

    switch (action.type) {
        /**
         * The UPDATE_STORE reducer utilizes Immer to update the store's state.
         *
         * It can be passed nested objects that share the same structure as the target.
         * Instead of completely replacing nested objects (e.g., layer 1 or layer 2 objects),
         * it updates the values of specific keys within those objects while keeping the rest intact.
         *
         * Standard arrays and values will be directly replaced.
         *
         * */
        case 'UPDATE_STORE':
            return produce(state, draft => {
                Object.keys(action.payload).forEach(key => {
                    if (typeof action.payload[key] === 'object' && action.payload[key] !== null && !Array.isArray(action.payload[key])) {
                        // recursively update nested keys
                        Object.keys(action.payload[key]).forEach(nestedKey => {
                            if (!draft[key] || typeof draft[key] !== 'object') {
                                draft[key] = {}
                            }

                            draft[key][nestedKey] = action.payload[key][nestedKey]
                        })
                    } else {
                        // direct assignment for non-nested values
                        draft[key] = action.payload[key]
                    }
                })
            })
        case 'UPDATE_LEVEL_ONE':
            return Object.assign({}, state, {
                [action.payload.levelOneKey]: value
            })
        case 'UPDATE_LEVEL_TWO':
            return Object.assign({}, state, {
                [action.payload.levelOneKey]: {
                    ...state[action.payload.levelOneKey],
                    [action.payload.levelTwoKey]: value
                }
            })
        case 'UPDATE_LEVEL_THREE':
            return Object.assign({}, state, {
                [action.payload.levelOneKey]: {
                    ...state[action.payload.levelOneKey],
                    [action.payload.levelTwoKey]: {
                        ...state[action.payload.levelOneKey][action.payload.levelTwoKey],
                        [action.payload.levelThreeKey]: value
                    }
                }
            })
        case 'UPDATE_LEVEL_FOUR':
            return Object.assign({}, state, {
                [action.payload.levelOneKey]: {
                    ...state[action.payload.levelOneKey],
                    [action.payload.levelTwoKey]: {
                        ...state[action.payload.levelOneKey][action.payload.levelTwoKey],
                        [action.payload.levelThreeKey]: {
                            ...state[action.payload.levelOneKey][action.payload.levelTwoKey][action.payload.levelThreeKey],
                            [action.payload.levelFourKey]: value
                        }
                    }
                }
            })
        case 'UPDATE_LEVEL_FIVE':
            return Object.assign({}, state, {
                [action.payload.levelOneKey]: {
                    ...state[action.payload.levelOneKey],
                    [action.payload.levelTwoKey]: {
                        ...state[action.payload.levelOneKey][action.payload.levelTwoKey],
                        [action.payload.levelThreeKey]: {
                            ...state[action.payload.levelOneKey][action.payload.levelTwoKey][action.payload.levelThreeKey],
                            [action.payload.levelFourKey]: {
                                ...state[action.payload.levelOneKey][action.payload.levelTwoKey][action.payload.levelThreeKey][action.payload.levelFourKey],
                                [action.payload.levelFiveKey]: value
                            }
                        }
                    }
                }
            })
        case 'REMOVE_CONTACT_ID':
            let contacts = {}

            Object.keys(state.contacts).forEach((contactId) => {
                if (contactId !== action.payload.contactId)
                {
                    let contact = Object.assign({}, state.contacts[contactId])
                    if (contact.copyFromContactId === action.payload.contactId)
                    {
                        contact = state.contacts[action.payload.contactId] // copy over the most recent details from the contact that's about to be deleted to any contact that copied the data previously
                    }

                    contacts[contactId] = contact
                }
            })

            let roles = {}
            Object.keys(state.roles).filter((contactId) => contactId !== action.payload.contactId).map((contactId) => {
                roles[contactId] = state.roles[contactId]
            })

            return Object.assign({}, state, {
                contacts,
                roles,
                historyData: {
                    ...state.historyData,
                    state: {
                        ...state.historyData.state,
                        'removeContactId': null
                    }
                }
            })
        case 'REMOVE_NON_CONNECTION_SERVICES':
            let updatedState = Object.assign({}, state)
            updatedState.progress = getProgressWithoutNonConnectionServices(updatedState['progress'])

            // handles redirect to /summary
            updatedState.historyData = Object.assign({}, state.historyData, {location: '/summary'})

            updatedState.services = {}

            const keysToDelete = [
                'safetyDisconnection',
                'vegetationControl',
                'cableLocation',
                'closeApproachConsent'
            ]

            keysToDelete.forEach((key) => {
                if (typeof updatedState[key] !== 'undefined')
                    delete updatedState[key]
            })

            return updatedState
        case 'ADD_PROGRESS':
            return !state.progress.includes(action.payload.path) ? Object.assign({}, state, {
                    'progress': [...state.progress, action.payload.path]
                }) : state
        case 'REMOVE_PROGRESS':
            return Object.assign({}, state, {
                'progress': removeItemsFromArray(state.progress, action.payload.path)
            })
        case 'REPLACE_STORE':
            return action.payload
        case 'UPDATE_HISTORY_LOCATION':
            return Object.assign({}, state, {
                'historyData': {
                    'location': action.payload.location,
                    'state': action.payload.state,
                    'action': action.payload.action
                }
            })
        default:
            return state
    }
}