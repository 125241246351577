import React, {Component, createRef} from 'react'

import { connect } from "react-redux"
import defaultReduxState from "../store/default-redux-state"

import { createBrowserHistory } from 'history'

import mapDispatchToProps from '../helpers/map-dispatch-to-props'
import existsGet from "../helpers/exists-get"
import getQueryParameterByName from "../helpers/get-query-parameter-by-name"
import serviceChanged from "../helpers/service-changed"
import getServiceCheckboxesData from "../services/general/get-service-checkboxes-data"

import WelcomeAndServices from "./sections/welcome-and-services"
import VegetationControl from "./sections/vegetation-control"
import NameAndAddress from "./sections/name-and-address"
import PropertyType from "./sections/property-type"
import Prerequisites from "./sections/prerequisites"
import RolesHub from "./sections/roles-hub"
import Retailer from "./sections/retailer"
import ContactDetails from "./sections/roles-hub/contact-details"
import ApprovedContractor from "./sections/approved-contractor"
import ManualCapacity from "./sections/manual-capacity"
import DistributedGenerationCapacity from "./sections/distributed-generation-capacity"
import OtherInfo from "./sections/other-information"
import Summary from "./sections/summary"
import TemporaryDisconnection from "./sections/temporary-disconnection"
import CloseApproachConsent from "./sections/close-approach-consent"
import CableLocation from "./sections/cable-location"
import PreferredDates from "./sections/preferred-dates/preferred-dates"
import ConfirmLocation from "./sections/confirm-location"
import EnquiryDetails from "./sections/enquiry-details"
import Submit from "./sections/submit"
import Complete from "./sections/complete"
import Pay from "./sections/pay"

import Sidebar from "./sidebar"

import HeaderBar from "./common/header-bar"
import Footer from "./common/footer"
import Header from "./common/header"
import Alert from "./common/alert"
import Button from "./common/button"

import SpinnerNav from "../assets/svgs/indicators/spinner-white-small.svg"
import Spinner from "../assets/svgs/indicators/spinner-large.svg"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action', null),
        historyState: existsGet(state, 'historyData.state', null),
        isNewOrder: existsGet(state, 'isNewOrder', false),
        dataRequestIsLoading: existsGet(state, 'dataRequestIsLoading', false),
        googleLibsAreLoading: existsGet(state, 'googleLibsAreLoading', true),
        historyInitialized: existsGet(state, 'historyInitialized', false),
        historyBackInitialized: existsGet(state, 'historyBackInitialized', false),
        content: existsGet(state, 'content', null),
        appWrapperShouldRender: existsGet(state, 'appWrapperShouldRender', false),
        fromServiceDeeplink: existsGet(state, 'fromServiceDeeplink', false),
        connections: existsGet(state, 'connections', null),
        services: existsGet(state, 'services', null),
    }
}

const history = createBrowserHistory()

function versionToFloat(versionArray) {
    return parseFloat(versionArray[0] + '.' + versionArray[1] + versionArray[2])
}

function isLegacyFrontend(minVersion) {
    const versionParts = process.appVersion.split('.')
    const minSupportedVersionParts = minVersion.split('.')

    // versions should always be using "semantic versioning", otherwise this function is essentially bypassed
    if (versionParts.length !== 3 || minSupportedVersionParts.length !== 3) {
        return false
    }

    const versionFloat = versionToFloat(versionParts)
    const minSupportedVersionFloat = versionToFloat(minSupportedVersionParts)

    return versionFloat < minSupportedVersionFloat
}

class AppWrapper extends Component {
    isMounted = false

    constructor(props) {
        super(props)

        this.sectionWrapperRef = createRef()
        this.sectionComponentRef = createRef()

        // history handling
        this.servicesChanged = false
        this.lastActiveHistoryLocationKey = undefined
        this.historyOrderLocationKeys = [undefined] // this is the default value on the initial load
        this.historyUnlisten = null
        // end history handling

        this.state = {
            sidebarOpen: false,
            sidebarClassName: null,
            showBottomNavigation: false,
            showHamburger: true,
            hasDataRequestError: false,
            softForceRefresh: false
        }
    }

    componentDidMount = () => {
        this.isMounted = true

        // note: If the user doesn't interact with the page, this will never fire - this behaviour is most prevalent if you hit back multiple times on a payment URL from Windcave
        window.onbeforeunload = function () {
            return ""
        }

        window.navigationObserver = new IntersectionObserver(
            ([entry]) => {
                if (this.isMounted)
                {
                    this.setState({showBottomNavigation: !entry.isIntersecting})
                }
            },
            { threshold: 0.2 }
        )

        if (!window.googleLibsHaveLoaded)
        {
            const ctpRequestLibsLoadedChecker = setInterval(() => {
                if (window.googleLibsHaveLoaded) {
                    clearInterval(ctpRequestLibsLoadedChecker)
                    this.props.updateStore({'googleLibsAreLoading': false})
                }
            }, 1000) // set to a second so that if a user does see the name and address page loading indicator, it doesn't "flash" for like 50ms
            setTimeout(() => {
                clearInterval(ctpRequestLibsLoadedChecker)
                this.props.updateStore({'googleLibsAreLoading': false})
            }, 6000)
        }
        else
        {
            this.props.updateStore({'googleLibsAreLoading': false})
        }

        // need to hydrate the history items otherwise direction won't work properly with browser nav buttons
        const sessionStorageHistoryOrderLocationKeys = sessionStorage.getItem('historyOrderLocationKeys')
        if (sessionStorageHistoryOrderLocationKeys !== null && sessionStorageHistoryOrderLocationKeys !== 'null')
        {
            this.historyOrderLocationKeys = JSON.parse(sessionStorageHistoryOrderLocationKeys)

            // undefined gets converted to null, undefined is the default value for "/"
            if (this.historyOrderLocationKeys.length && this.historyOrderLocationKeys[0] === null)
                this.historyOrderLocationKeys[0] = undefined
        }

        const hasServiceDeeplink = this.hasServiceDeeplink()
        if (!hasServiceDeeplink)
        {
            // deep links handle this after navigating
            this.props.updateStoreValue('appWrapperShouldRender', true)
        }

        // initial request data done in index.html, to improve performance
        // pay url triggers componentDidMount for the success message, so we don't want the data loaded (it's not loaded in index.html either)
        // for deep links we want to handle the data request differently
        if (!this.isPayURI() && !hasServiceDeeplink)
        {
            // resets to start if for example the "/name-and-address" URL reloaded or accessed directly
            if (history.location.pathname !== '/')
            {
                this.handleHistoryItem('replace', '/')
            }

            this.handleDataRequest(true)
        }
        else
        {
            this.initHistory()
        }

        this.historyUnlisten = history.listen((data, action) => {
            if (action === 'POP') {
                // there's no back button in summary review pages, it's probably a better UX to just "block" the event rather than navigating
                if (
                    (
                        this.props.historyState !== null &&
                        this.props.historyState.fromSummary &&
                        !(this.props.historyLocation === '/' && this.servicesChanged)
                    ) ||
                    ['/complete', '/submit'].includes(this.props.historyLocation)
                )
                {
                    this.handleHistoryItem('replace', this.props.historyLocation)
                }
                // we don't want contact-details to be in history, so replacing it when navigating back (note: this isn't applicable when the delete prompt blocks this)
                else if (
                    !history.location.pathname.includes('contact-details') &&
                    this.props.historyLocation.includes('contact-details') &&
                    this.sectionComponentRef.current.handleNavigation('back')
                )
                {
                    this.handleHistoryItem('replace', '/roles-hub')
                }
                else if (
                    this.sectionComponentRef.current &&
                    (
                        typeof this.sectionComponentRef.current.showBackButton === 'undefined' ||
                        (
                            typeof this.sectionComponentRef.current.showBackButton === 'function' && this.sectionComponentRef.current.showBackButton()
                        )
                    )
                )
                {
                    const previousKeyIndex = this.historyOrderLocationKeys.indexOf(this.lastActiveHistoryLocationKey)
                    const nextKeyIndex = this.historyOrderLocationKeys.indexOf(history.location.key)

                    const direction = nextKeyIndex < previousKeyIndex ? 'back' : 'forward'

                    // we don't want someone navigating forward to accidentally submit
                    if (this.props.historyLocation === '/summary' && direction === 'forward')
                    {
                        this.handleHistoryItem('replace', this.props.historyLocation)
                    }
                    else if (!this.sectionComponentRef.current.handleNavigation(direction))
                    {
                        this.handleHistoryItem('replace', this.props.historyLocation)
                    }
                }
            }
        })
    }

    componentDidUpdate(prevProps) {
        this.servicesChanged = (
            this.props.historyLocation === '/' &&
            this.props.historyState !== null &&
            this.props.historyState.fromSummary &&
            serviceChanged(prevProps, this.props)
        )

        if (prevProps.historyAction !== this.props.historyAction && this.props.historyAction === 'reloadDataRequest')
        {
            this.reloadDataRequest()
            this.props.updateStoreValue('historyData.action', null)
        }

        if (prevProps.fromServiceDeeplink !== this.props.fromServiceDeeplink && this.props.fromServiceDeeplink)
        {
            this.handleDataRequest(false)
        }

        if (prevProps.historyLocation !== this.props.historyLocation)
        {
            this.handleLocationChange(prevProps)
        }
    }

    componentWillUnmount() {
        if (this.historyUnlisten)
            this.historyUnlisten()

        this.isMounted = false
        window.navigationObserver = null
    }

    handleDataRequest(shouldHandleInit) {
        const dataRequestHandled = () => {
            if (window.ctpRequestDataResponse !== null) {
                this.handleDataResponse(window.ctpRequestDataResponse)

                if (shouldHandleInit)
                    this.initHistory()

                return true
            } else if (window.ctpRequestDataHasError) {
                this.handleDataRequestError()
                return true
            }

            return false
        }

        if (!dataRequestHandled()) {
            this.props.updateStore({'dataRequestIsLoading': true})

            // this waits until the request done in index.html is complete (will return true on error or good response), using this instead of events to prevent race conditions
            const ctpRequestGlobalChecker = setInterval(() => {
                if (dataRequestHandled()) {
                    clearInterval(ctpRequestGlobalChecker)
                }
            }, 10)
        }
    }

    handleDataResponse = (res) => {
        if (isLegacyFrontend(res.minVersion))
        {
            // will show a reload message on the frontend
            this.setState({softForceRefresh: true, hasDataRequestError: false}, () => {
                this.props.updateStore({
                    'appWrapperShouldRender': false,
                    'dataRequestIsLoading': false
                })
            })
            return
        }

        this.setState({hasDataRequestError: false}, () => {
            this.props.updateStore({
                'appWrapperShouldRender': true,
                'dataRequestIsLoading': false,
                'content': {
                    'inverterManufacturerPicklist': res.inverterManufacturerPicklist,
                    'preferredRetailerPicklist': res.preferredRetailerPicklist
                }
            })
        })
    }

    reloadDataRequest = () =>
    {
        this.props.updateStore({'dataRequestIsLoading': true})
        fetchWithTimeout(process.env.CTP_ENDPOINT + '/data', {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        }, 5000).then(res => {
            if (res.status !== 200)
            {
                throw new Error("Request data response code not 200. Status: "+res.status)
            }

            return res.json()
        }).then(res => {
            if (typeof res.minVersion === 'undefined' || res.minVersion === null || res.minVersion.length === 0)
            {
                throw new Error("Min version not defined or empty")
            }

            this.handleDataResponse(res)
        }).catch((reason) => {
            this.handleDataRequestError()

            console.info('Request data fetch failed')
            console.info(reason)
        })
    }

    handleDataRequestError()
    {
        this.setState({hasDataRequestError: true}, () => {
            this.props.updateStore({
                'dataRequestIsLoading': false,
                'appWrapperShouldRender': false
            })
        })
    }

    handleLocationChange = (prevProps) => {
        document.getElementById('app').scrollTop = 0

        if (this.props.historyLocation.indexOf('pay') !== -1 || this.props.historyLocation === '/submit' || this.props.historyLocation === '/complete')
        {
            this.setState({showHamburger: false, sidebarOpen: false})
        }
        else if (!this.state.showHamburger)
        {
            this.setState({showHamburger: true})
        }

        if (prevProps.historyLocation === '/' && !this.props.historyBackInitialized)
        {
            this.props.updateStoreValue('historyBackInitialized', true)
        }

        if (
            this.props.historyAction === 'back' ||
            this.props.historyLocation.includes('submit') ||
            this.props.historyLocation.includes('contact-details') ||
            this.props.historyAction === 'forward'
        )
        {
            this.handleHistoryItem('replace', this.props.historyLocation)
        }
        else
        {
            this.handleHistoryItem('push', this.props.historyLocation)
        }
    }

    handleHistoryItem = (type, location) => {
        if (type === 'push')
        {
            history.push(location)
            this.historyOrderLocationKeys.push(history.location.key)
        }
        else if (type === 'replace')
        {
            const previousLocationKeyIndex = this.historyOrderLocationKeys.indexOf(history.location.key)
            history.replace(location)
            this.historyOrderLocationKeys[previousLocationKeyIndex] = history.location.key
        }

        sessionStorage.setItem('historyOrderLocationKeys', JSON.stringify(this.historyOrderLocationKeys));

        this.lastActiveHistoryLocationKey = history.location.key
    }

    isPayURI = () => {
        return window.location.href.indexOf("/pay/") !== -1
    }

    getDeeplinkMap = () => {
        return {
            'new-electricity-connection': {
                type: 'connections',
                name: 'electricity'
            },
            'builders-temporary-supply': {
                type: 'connections',
                name: 'bts'
            },
            'new-electricity-connection-and-bts': {
                type: 'connections',
                name: 'electricity+bts'
            },
            'alteration-to-existing': {
                type: 'connections',
                name: 'alteration'
            },
            'alternative-energy': {
                type: 'connections',
                name: 'onsiteGeneration'
            },
            'new-subdivision': {
                type: 'connections',
                name: 'newSubdivision'
            },
            'projects': {
                type: 'connections',
                name: 'project'
            },
            'ballpark-estimate': {
                type: 'connections',
                name: 'ballparkEstimate'
            },
            'safety-disconnection': {
                type: 'services',
                name: 'safetyDisconnection'
            },
            'vegetation-control': {
                type: 'services',
                name: 'vegetationControl'
            },
            'cable-location-request': {
                type: 'services',
                name: 'cableLocation'
            },
            'working-near-lines': {
                type: 'services',
                name: 'closeApproachConsent'
            },
            'general-enquiry': {
                type: 'services',
                name: 'generalEnquiry'
            }
        }
    }

    getDeeplinkOptions = () => {
        return Object.keys(this.getDeeplinkMap())
    }

    hasServiceDeeplink = () => {
        const path = history.location.pathname
        const deepLinkKey = path.replace('/service/', '')
        const deeplinkOptions = this.getDeeplinkOptions()

        return path.indexOf('service') !== -1 && deeplinkOptions.includes(deepLinkKey)
    }

    initHistory = () => {
        if (process.env.APP_ENV === 'dev' && this.props.historyInitialized)
            return false

        const getLabel = (type, name) => {
            const serviceCheckboxData = getServiceCheckboxesData()

            return serviceCheckboxData[type][name]['label']
        }

        const deeplinkMap = this.getDeeplinkMap()
        const path = history.location.pathname
        const deepLinkKey = path.replace('/service/', '')

        if (this.isPayURI())
        {
            const windcaveSessionId = getQueryParameterByName('sessionId')
            if (history.location.pathname === '/pay/success')
            {
                this.props.updateLocation('/pay/success')
            }
            else if (history.location.pathname === '/pay/failed')
            {
                this.props.updateLocation('/pay/failed', {windcaveSessionId})
            }
            else if (history.location.pathname === '/pay/cancelled')
            {
                this.props.updateLocation('/pay/cancelled', {windcaveSessionId})
            }
            else if (this.isPayURI() && history.location.pathname !== '/pay')
            {
                // this handles /pay/{uuid}, but not /pay
                this.props.updateLocation(location.pathname)
            }
        }
        else if (this.hasServiceDeeplink())
        {
            const type = deeplinkMap[deepLinkKey]['type']
            const name = deeplinkMap[deepLinkKey]['name']

            let nameAndAddressHeaderTitleOverrideText
            let checkedOptions = null
            if (name !== 'electricity+bts')
            {
                nameAndAddressHeaderTitleOverrideText = getLabel(type, name)
                checkedOptions = {
                    [name+'IsChecked']: true
                }
            }
            else {
                nameAndAddressHeaderTitleOverrideText = getLabel(type, 'electricity') + ' & ' + getLabel(type, 'bts')
                checkedOptions = {
                    electricityIsChecked: true,
                    btsIsChecked: true,
                }
            }

            let location = '/name-and-address'
            if (type === 'services' && name !== 'generalEnquiry')
            {
                location = '/preferred-dates'
            }

            const storeValue = Object.assign({}, defaultReduxState, {
                [type]: checkedOptions,
                historyData: {
                    location: location
                },
                progress: [
                    '/'
                ],
                appWrapperShouldRender: true,
                fromServiceDeeplink: true,
                nameAndAddressHeaderTitleOverrideText: nameAndAddressHeaderTitleOverrideText,
                content: this.props.content
            })

            this.props.replaceStore(storeValue)
        }

        // used to stop issues with hot reload system
        if (process.env.APP_ENV === 'dev')
        {
            this.props.updateStoreValue('historyInitialized', true)
        }

        return true
    }

    getCurrentSection = () => {
        if (this.props.historyLocation === '/')
        {
            return <WelcomeAndServices ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation.indexOf('/pay') !== -1)
        {
            return <Pay ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} historyOrderLocationKeys={this.historyOrderLocationKeys} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/preferred-dates')
        {
            return <PreferredDates ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/vegetation-control')
        {
            return <VegetationControl ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/temporary-disconnection')
        {
            return <TemporaryDisconnection ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/close-approach-consent')
        {
            return <CloseApproachConsent ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/cable-location')
        {
            return <CableLocation ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/name-and-address')
        {
            // this.props.googleLibsAreLoading change will trigger a re-render, so no need to use state
            if (window.googleLibsHaveLoaded)
            {
                return <NameAndAddress ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
            }
            else
            {
                return <div id="name-and-address">
                    <Header
                        title='Loading...'
                    />
                    <div className='container name-and-address-loading'>
                        <div className="name-and-address-spinner-icon-container name-and-address-spinner-icon-container--is-spinning">
                            <Spinner/>
                        </div>
                    </div>
                </div>;
            }
        }
        else if (this.props.historyLocation === '/confirm-location')
        {
            return <ConfirmLocation ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/enquiry-details')
        {
            return <EnquiryDetails ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/property-type')
        {
            return <PropertyType ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/prerequisites')
        {
            return <Prerequisites ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/roles-hub')
        {
            return <RolesHub ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if
        ([
            '/roles-hub/contact-details/new-contact',
            '/roles-hub/contact-details/edit-contact',
            '/roles-hub/contact-details/your-details',
            '/roles-hub/contact-details/edit-your-details'
        ].includes(this.props.historyLocation))
        {
            return <ContactDetails ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/retailer')
        {
            return <Retailer ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/approved-contractor')
        {
            return <ApprovedContractor ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/manual-capacity')
        {
            return <ManualCapacity ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/distributed-generation-capacity')
        {
            return <DistributedGenerationCapacity ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/other-information')
        {
            return <OtherInfo ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/summary')
        {
            return <Summary ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} />
        }
        else if (this.props.historyLocation === '/submit')
        {
            return <Submit ref={this.sectionComponentRef} sectionWrapperRef={this.sectionWrapperRef} showBottomNavigation={this.state.showBottomNavigation} bugsnagNotify={this.props.bugsnagNotify} />
        }
        else if (this.props.historyLocation === '/complete')
        {
            return <Complete />
        }

        console.error('Section slug not found.')

        return null
    }

    handleHamburgerClick = () => {
        if (!this.state.sidebarOpen)
        {
            this.setState({sidebarClassName: 'prepare', sidebarOpen: true})
        }
        else
        {
            this.setState({sidebarOpen: false}, () => setTimeout(() => this.setState({sidebarClassName: null}),600))
        }
    }

    reloadPage = (excludeLastReloadAttempt = false) => {
        window.onbeforeunload = null; // stops blocking of reload

        if (!excludeLastReloadAttempt)
        {
            const timestamp = new Date().getTime().toString()
            localStorage.setItem('lastReloadAttempt', timestamp)
        }

        const rootUrl = window.location.protocol + '//' + window.location.hostname
        if (process.env.APP_ENV !== 'dev')
        {
            window.location = rootUrl + '?reloaded=' + new Date().getTime()
        }
        else
        {
            window.location = rootUrl + ':' + window.location.port + '?reloaded=' + new Date().getTime()
        }
    }

    shouldShowReloadAttemptedMessage = () => {
        const lastReloadAttemptTimestamp = parseInt(localStorage.getItem('lastReloadAttempt'))
        if (isNaN(lastReloadAttemptTimestamp))
            return false

        const lastReloadAttemptedMilliseconds = parseInt(new Date().getTime().toString()) - lastReloadAttemptTimestamp

        // was attempted less than or equal to 2 minutes ago
        return lastReloadAttemptedMilliseconds / 1000 <= (60*2)
    }

    reloadOriginalPage = () => {
        window.onbeforeunload = null; // stops blocking of reload
        if (window.originalPage)
        {
            window.location = window.originalPage
        }
        else
        {
            this.reloadPage(true)
        }
    }

    // instead of having the wrapper inside the render function, we're using this as it gives control over when it gets rendered
    wrapper = (child) => {
        return <div id="main" className={this.state.sidebarOpen ? 'sidebar-active' : null}>
            <div className='wrapper'>
                <HeaderBar sidebarOpen={this.state.sidebarOpen} onHamburgerClick={this.handleHamburgerClick} showHamburger={this.state.showHamburger} />
                {child}
                <Footer updateLocation={this.props.updateLocation} />
            </div>
            <Sidebar className={this.state.sidebarClassName} />
        </div>
    }

    render() {
        const showGoogleLibsError = !window.googleLibsHaveLoaded && !this.props.googleLibsAreLoading && !this.state.hasDataRequestError && !this.state.softForceRefresh && !this.isPayURI()

        return <>
            {!showGoogleLibsError && !this.state.hasDataRequestError && this.props.appWrapperShouldRender ?
                this.wrapper(this.getCurrentSection())
            : null}
            {!this.state.hasDataRequestError && this.state.softForceRefresh ?
                this.wrapper(<div id="welcome-and-services" className="section-margin-bottom">
                    <Header
                        title='Reload required'
                        />
                        <div className='container app-wrapper-error-container'>
                            <div className='app-wrapper-error app-wrapper-error--data-request-error'>
                                <Alert type='info'
                                       text={
                                            <span>
                                                We have updated our website, please use the reload button below in order to continue. {this.props.isNewOrder ? <>You will need to re-enter your data for your next order.</> : null}
                                                {this.shouldShowReloadAttemptedMessage() ? <><br/><br/>If you keep seeing this message, please force refresh the page or clear your browser cache and try again.</> : null}
                                            </span>
                                        }
                                />
                                <Button btnType="btn-primary" className="mt-default center-button" text="Reload" action={this.reloadPage}/>
                            </div>
                        </div>
                    </div>)
            : null}
            {this.state.hasDataRequestError ?
                this.wrapper(<div id="welcome-and-services" className="section-margin-bottom">
                    <Header
                        title='There was an error'
                    />
                    <div className='container app-wrapper-error-container'>
                        <div className="app-wrapper-error app-wrapper-error--data-request-error">
                            <Alert type="danger"
                                   text="Sorry, something went wrong while fetching the data for this page."/>
                            <button type="button" className="btn btn-primary mt-default center-button"
                                    onClick={this.reloadDataRequest}>
                                {this.props.dataRequestIsLoading ? <div className="spinner-nav-container"><SpinnerNav/></div> : 'Try Again'}
                            </button>
                        </div>
                    </div>
                </div>)
            : null}
            {showGoogleLibsError ?
                this.wrapper(<div id="welcome-and-services" className="section-margin-bottom">
                    <Header
                        title='There was an error'
                    />
                    <div className='container app-wrapper-error-container'>
                        <div className="app-wrapper-error app-wrapper-error--data-request-error">
                            <Alert type="danger"
                                   text="This page couldn't be fully loaded. Please retry using the button below. If the problem continues, try updating your browser or switching to a different one."/>
                            <button type="button" className="btn btn-primary mt-default center-button"
                                    onClick={this.reloadOriginalPage}>Retry</button>
                        </div>
                    </div>
                </div>)
                :null}
        </>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)
