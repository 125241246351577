function removeItemsFromArray(array, items)
{
    if (!Array.isArray(items))
    {
        items = Object.assign([], [items])
    }

    return array.filter((value) => {
         return typeof value !== 'undefined' && !items.includes(value)
    })
}

export default removeItemsFromArray