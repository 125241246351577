import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import Map from "./map"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import Alert from "../../common/alert"
import existsGet from "../../../helpers/exists-get"
import getCheckedFields from "../../../helpers/get-checked-fields"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import shouldShowPropertyType from "../../../helpers/should-show-property-type"
import GoogleAddress from "../name-and-address/google-address"
import validator from "../../../services/validator/validator"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        serviceAddress: existsGet(state, 'serviceAddress', null),
        connections: existsGet(state, 'connections', null),
        google: {
            serviceAddress: existsGet(state, 'google.serviceAddress', null)
        },
        lat: existsGet(state, 'serviceAddress.geolocationLatitude'),
        lng: existsGet(state, 'serviceAddress.geolocationLongitude'),
        readyPropertyIsChecked: existsGet(state, 'fibre.readyPropertyIsChecked', false),
        fibreStatus: existsGet(state, 'fibre.status', null),
        services: {
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
        }
    }
}

const validationMessages = {
    google_serviceAddress: {
        required: 'Please enter and select an approximate site location.'
    }
}

class ConfirmLocation extends Component {
    constructor(props){
        super(props)

        this.validationErrorFields = {
            google_serviceAddressErrors: []
        }

        this.state = {
            ...this.validationErrorFields
        }
    }

    isProject = () => {
        return typeof this.props.connections.projectIsChecked !== 'undefined' && this.props.connections.projectIsChecked
    }

    isGeneralEnquiry = () => {
        return this.props.services.generalEnquiryIsChecked
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    getNextLocation = () => {
        if (this.isGeneralEnquiry())
        {
            return '/enquiry-details'
        }

        if (this.isProject())
        {
            return '/roles-hub'
        }

        const checkedConnections = getCheckedFields(this.props.connections)
        if (!shouldShowPropertyType(checkedConnections))
        {
            return '/prerequisites'
        }

        const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
        if (checkedConnections.length === 0 && nextNonConnectionService !== null)
        {
            return nextNonConnectionService
        }

        return '/property-type'
    }

    validateAndGetNextLocation = () => {
        const validationRules = {
            google: {
                serviceAddress: ['required']
            }
        }

        const validation = validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules,
            validationErrorFields: this.validationErrorFields
        })
        if (validation)
        {
            return this.getNextLocation()
        }

        return false
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.updateLocation('/name-and-address', null, 'back')

            return true
        }

        if (direction === 'summary')
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    render() {
        return (
            <div id="confirm-location">
                <Header
                    title={this.isProject() ? "Approximate Location" : "Please Confirm Your Location"}
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className='section'>
                    <div className="container">
                        <Alert type="description" >
                            <span>Please enter a site location, this can be <b>approximate</b> (e.g. Whangarei) and drag the marker as close to the location as possible.</span>
                        </Alert>
                        {this.isProject() ?
                            <GoogleAddress
                                updateStore={this.props.updateStore}
                                updateStoreValue={this.props.updateStoreValue}
                                serviceAddress={this.props.serviceAddress}
                                googleServiceAddress={this.props.google.serviceAddress}
                                latLng={{lat: this.props.lat, lng: this.props.lng}}
                                validationErrorsFromParent={this.state.google_serviceAddressErrors}
                                validationMessages={validationMessages.google_serviceAddress}
                                setParentState={this.setParentState}
                                fibreStatus={this.props.fibreStatus}
                                readyPropertyIsChecked={this.props.readyPropertyIsChecked}
                                showServiceAddressFields={false}
                                doFibreLookup={true}
                                hideManualAddressPrompt={true}
                                isProject={this.isProject()}
                            />
                        : null}
                    </div>
                    {(!this.isProject() || (this.isProject() && this.props.lat !== null && this.props.lng !== null)) ?
                        <Map serviceAddress={this.props.serviceAddress} lat={this.props.lat} lng={this.props.lng} updateStoreValue={this.props.updateStoreValue} isProject={this.isProject()} />
                    : null}
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation className='bottom-navigation' hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <ConfirmLocation ref={ref} {...props} />
    })
)
