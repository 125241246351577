import React, {Component} from 'react'
import TextInputWrapper from '../../../hocs/text-input-wrapper'
import MandatoryIndicator from "../mandatory-indicator/mandatory-indicator"

class Input extends Component {
    render(){
        const type = typeof this.props.type !== 'undefined' ? this.props.type : 'text'
        const step = typeof this.props.step !== 'undefined' ? this.props.step : null

        let min = null
        if (type === 'number')
            min = 0

        const onWheel = (e) => {
            if (type === 'number')
                e.target.blur()
        }

        return (
            <>
                <label htmlFor={this.props.id}>{this.props.mandatoryIndicator ? <MandatoryIndicator>{this.props.label}</MandatoryIndicator> : this.props.label}</label>

                {this.props.description ? this.props.description : null}
                <input type={type} id={this.props.id} className="form-control" onChange={this.props.handleChange} placeholder={this.props.placeholder} step={step} min={min} value={this.props.value} onWheel={onWheel} />
            </>
        )
    }
}

export default TextInputWrapper(Input)