import React, {Component} from 'react'
import Select from 'react-select'
import ValidationAlert from "../validation-alert"

class TimePicker extends Component {
    constructor(props){
        super(props)

        this.meridianDefault = {'label': 'AM', 'value': 'AM'}
    }

    removeErrors = () => {
        if (typeof this.props.parentErrorKey !== 'undefined')
        {
            this.props.setParentState({[this.props.parentErrorKey]: []})
        }
    }

    onChange = (value, type) => {
        let minute = null
        let hour = null
        let meridian = this.meridianDefault

        if (this.props.value !== null)
        {
            hour = this.props.value.hour
            minute = this.props.value.minute
            meridian = this.props.value.meridian
        }

        if (type === 'minute')
        {
            minute = value
        }
        else if (type === 'hour')
        {
            hour = value
        }
        else if (type === 'meridian')
        {
            meridian = value
        }

        this.props.updateStoreValue(this.props.keyString, {'hour': hour, 'minute': minute, 'meridian': meridian})

        this.removeErrors()
    }

    getValue = (type) => {
        const valueBase = this.props.value

        if (valueBase === null) {
            if (type === 'meridian') {
                return this.meridianDefault
            }

            return null
        }

        return valueBase[type]
    }

    onClear = () => {
        this.props.updateStoreValue(this.props.keyString, null)

        this.removeErrors()
    }

    render() {
        const validationErrors = this.props.validationErrorsFromParent
        const hasValidationError = validationErrors.length > 0

        let className = 'timepicker-wrapper'

        if (hasValidationError)
        {
            if (validationErrors.includes('time-hour'))
            {
                className += ' error--time-hour'
            }

            if (validationErrors.includes('time-minute'))
            {
                className += ' error--time-minute'
            }
        }

        return (
            <div className={className}>
                <label>{this.props.label}</label>
                <div className='timepicker'>
                    <Select
                        className="react-select timepicker--hour"
                        classNamePrefix="react-select"
                        id={'timepicker--hour'}
                        value={this.getValue('hour')}
                        onChange={(value) => {this.onChange(value, 'hour')}}
                        options={
                            [
                                {
                                    'label': '01',
                                    'value': '1'
                                },
                                {
                                    'label': '02',
                                    'value': '2'
                                },
                                {
                                    'label': '03',
                                    'value': '3'
                                },
                                {
                                    'label': '04',
                                    'value': '4'
                                },
                                {
                                    'label': '05',
                                    'value': '5'
                                },
                                {
                                    'label': '06',
                                    'value': '6'
                                },
                                {
                                    'label': '07',
                                    'value': '7'
                                },
                                {
                                    'label': '08',
                                    'value': '8'
                                },
                                {
                                    'label': '09',
                                    'value': '9'
                                },
                                {
                                    'label': '10',
                                    'value': '10'
                                },
                                {
                                    'label': '11',
                                    'value': '11'
                                },
                                {
                                    'label': '12',
                                    'value': '12'
                                }
                            ]
                        }
                        placeholder='--'
                        isSearchable={false}
                        components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null
                        }}
                    />
                    <span className='timepicker--colon'>:</span>
                    <Select
                        className="react-select timepicker--minute"
                        classNamePrefix="react-select"
                        id={'timepicker--minute'}
                        value={this.getValue('minute')}
                        onChange={(value) => {this.onChange(value, 'minute')}}
                        options={
                            [
                                {
                                    'label': '00',
                                    'value': '00'
                                },
                                {
                                    'label': '05',
                                    'value': '05'
                                },
                                {
                                    'label': '10',
                                    'value': '10'
                                },
                                {
                                    'label': '15',
                                    'value': '15'
                                },
                                {
                                    'label': '20',
                                    'value': '20'
                                },
                                {
                                    'label': '25',
                                    'value': '25'
                                },
                                {
                                    'label': '30',
                                    'value': '30'
                                },
                                {
                                    'label': '35',
                                    'value': '35'
                                },
                                {
                                    'label': '40',
                                    'value': '40'
                                },
                                {
                                    'label': '45',
                                    'value': '45'
                                },
                                {
                                    'label': '50',
                                    'value': '50'
                                },
                                {
                                    'label': '55',
                                    'value': '55'
                                },
                            ]
                        }
                        placeholder='--'
                        isSearchable={false}
                        components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null
                        }}
                    />
                    <Select
                        className="react-select timepicker--meridian"
                        classNamePrefix="react-select"
                        id={'timepicker--meridian'}
                        value={this.getValue('meridian')}
                        onChange={(value) => {this.onChange(value, 'meridian')}}
                        options={
                            [
                                {
                                    'label': 'AM',
                                    'value': 'AM'
                                },
                                {
                                    'label': 'PM',
                                    'value': 'PM'
                                }
                            ]
                        }
                        placeholder='--'
                        isSearchable={false}
                        components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null
                        }}
                    />
                    <span className='timepicker--clear' onClick={this.onClear}>clear</span>
                </div>
                {hasValidationError ?
                    <ValidationAlert validationErrors={this.props.validationErrorsFromParent} validationMessages={this.props.validationMessages} />
                : null}
            </div>
        )
    }
}

export default TimePicker