import React, {PureComponent} from "react"
import ReactDOM from 'react-dom'
import CloseIcon from "../../../assets/svgs/modal/close.svg"

const domNode = document.getElementById('modal-root')

class Modal extends PureComponent {
    constructor(props)
    {
        super(props)

        this.wrapperRef = React.createRef()
        this.modalBodyRef = React.createRef()
        this.modalHeaderRef = React.createRef()
        this.modalBodyWrapperRef = React.createRef()

        this.state = {
            modalHeight: 'auto',
            modalBodyWrapperHeight: 'auto'
        }

        this.pageResizeTimeout = null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && !this.props.show)
        {
            window.removeEventListener('resize', this.handleResize)

            this.setState({modalHeight: 'auto', modalBodyHeight: 'auto'})
        }

        if (prevProps.show !== this.props.show && this.props.show)
        {
            this.calculateModalHeight()

            window.addEventListener('resize', this.handleResize)
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("no-scroll")
        window.removeEventListener('resize', this.handleResize)
        window.clearTimeout(this.pageResizeTimeout)
    }

    handleResize = () => {
        if (this.pageResizeTimeout !== null)
        {
            window.clearTimeout(this.pageResizeTimeout)
        }

        this.pageResizeTimeout = window.setTimeout(() => {
            this.calculateModalHeight()
        }, 50)
    }

    calculateModalHeight = () => {
        const documentHeight = window.innerHeight
        const documentWidth = document.documentElement.clientWidth
        const modalBodyHeight = this.modalBodyRef.current.clientHeight
        const modalHeaderHeight = this.modalHeaderRef.current.clientHeight

        if (typeof modalBodyHeight !== 'undefined' && modalBodyHeight !== null && typeof modalHeaderHeight !== 'undefined' && modalHeaderHeight !== null)
        {
            const contentHeight = (modalBodyHeight + modalHeaderHeight) + 58 + 12 // 58px padding/margin, 12 buffer needed for scroll

            let modalHeightDeduction = 0
            const modalHeightContentDifference = documentHeight - contentHeight

            if (modalHeightContentDifference > 1 && modalHeightContentDifference < 30)
            {
                modalHeightDeduction = 30 - modalHeightContentDifference
            }

            if (modalHeightContentDifference < 0 && documentWidth >= 400)
            {
                modalHeightDeduction = 30
            }

            let modalHeight = (documentHeight - modalHeightDeduction) + (documentWidth <= 400 ? 30 : 0)
            this.modalBodyWrapperRef.current.style.overflowY = "auto"
            if (contentHeight < documentHeight && documentWidth >= 400)
            {
                this.modalBodyWrapperRef.current.style.overflowY = "hidden"
                modalHeight = (contentHeight - modalHeightDeduction) // 70px padding/margin
            }

            const modalBodyWrapperHeight = modalHeight - modalHeaderHeight - 38 // 30px padding/margin, 2px buffer for browsers

            this.setState({modalHeight: modalHeight, modalBodyWrapperHeight: modalBodyWrapperHeight})
        }
    }

    handleClose = () => {
        document.body.classList.remove("no-scroll")
        this.props.closeAction()
    }

    render(){
        let className = 'modal fade show'
        if (this.props.className)
        {
            className += ' '+this.props.className
        }

        let modalBodyClassName = "modal-body"

        const modalStyle = {height: this.state.modalHeight}
        const modalBodyWrapperStyle = this.props.className !== 'confirm' ? {height: this.state.modalBodyWrapperHeight} : null

        return ReactDOM.createPortal(
            <>
            {this.props.show ?
                <>
                    <div style={modalStyle} ref={this.wrapperRef} className={className} tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                {typeof this.props.title !== 'undefined' || typeof this.props.closeAction !== 'undefined' ?
                                    <div className="modal-header" ref={this.modalHeaderRef}>
                                        {typeof this.props.title !== 'undefined' ?
                                            <div>
                                                <h5 className="modal-title">{this.props.title}</h5>
                                            </div>
                                        : null}
                                        {this.props.closeAction ?
                                            <CloseIcon className="modal-close" onClick={this.handleClose} />
                                        : null}
                                    </div>
                                : null}
                                <div className={modalBodyClassName} style={modalBodyWrapperStyle} ref={this.modalBodyWrapperRef}>
                                    <div ref={this.modalBodyRef}>
                                        {typeof this.props.content !== 'undefined' ?
                                            this.props.content
                                        : null}

                                        {typeof this.props.component !== 'undefined' ?
                                            this.props.component
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" />
                </>
                : null}
            </>,
            domNode
        )
    }
}

export default Modal