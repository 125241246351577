import React, {Component} from 'react'
import Button from "../../common/button"

class SummaryItem extends Component {
    handleClick = (path = this.props.changePath) => {
        let historyState = {fromSummary: true}

        if (this.props.contactId !== null)
        {
            historyState = Object.assign({}, historyState, {contactId: this.props.contactId})
        }

        this.props.setScrollTop()
        this.props.updateLocation(path, historyState)
    }

    getChangeButton = (isChangeableServiceOrDate) => {
        if (isChangeableServiceOrDate)
        {
            return  <>
                <Button text='Change Services' action={() => this.handleClick('/')}/>
                {this.props.type === 'services' ?
                    <Button text="Change Dates" action={() => this.handleClick('/preferred-dates')}/>
                : null}
            </>
        }

        return  <Button text="Change" action={this.handleClick}/>
    }

    render() {
        let className = this.props.className ? this.props.className+' summary-item clearfix' : 'summary-item clearfix'
        const isChangeableService = typeof this.props.type !== 'undefined' && ['connections', 'services'].includes(this.props.type)

        if (isChangeableService)
        {
            className += ' service-and-date-buttons'
        }

        let contentClasses = "content clearfix"
        if (this.props.contentLong)
            contentClasses += " content--long"

        return (
            <div className={className}>
                <div className={contentClasses}>
                    <h2>{this.props.title}</h2>
                    {this.props.content}
                </div>
                {this.props.showChangeButton !== false ?
                    <div className="change-button-wrapper service-buttons">
                        {this.getChangeButton(isChangeableService)}
                    </div>
                : null}
            </div>
        )
    }
}

export default SummaryItem