export default (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&')

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)

    if (!results || !results[2])
        return null

    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}