import React, {Component} from 'react'
import { Provider } from 'react-redux'
import AppWrapper from './appWrapper'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {bugsnagClient, notify} from '../lib/bugsnag'

Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()]
})


import '../assets/fonts/lineto-typ1451-medium.woff'
import '../assets/fonts/AtlasGrotesk-Regular-Web.woff'
import '../assets/fonts/AtlasGrotesk-Medium-Web.woff'

import '../assets/scss/app.scss'

class App extends Component {
    render() {
        const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

        return (
            <ErrorBoundary>
                <Provider store={this.props.store}>
                    <AppWrapper bugsnagNotify={notify} />
                </Provider>
            </ErrorBoundary>
        )
    }
}

export default App
