import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import Link from "../../../components/common/link"
import {getNextNonConnectionService, getPreviousSectionSlug} from "../../../services/router/non-connection-service"
import Textarea from "../../common/textarea/textarea"
import Input from "../../common/input/input"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import Alert from "../../common/alert"
import MandatoryIndicator from "../../common/mandatory-indicator"
import Checkbox from "../../common/checkbox"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        services: {
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            closeApproachConsentDateRequested: existsGet(state, 'services.closeApproachConsentDateRequested', null),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked')
        },
        cableLocation: {
            description: existsGet(state, 'cableLocation.description', ''),
            beforeUDigReferenceNumber: existsGet(state, 'cableLocation.beforeUDigReferenceNumber', ''),
            serviceToLocateFibre: existsGet(state, 'cableLocation.serviceToLocateFibre', false),
            serviceToLocateElectricity: existsGet(state, 'cableLocation.serviceToLocateElectricity', false)
        },
        propertyType: existsGet(state, 'propertyType', null),
        serviceAddress: existsGet(state, 'serviceAddress', null)
    }
}

const validationMessages = {
    cableLocation_description: {
        'required': 'Please enter a description.'
    },
    cableLocation_beforeUDigReferenceNumber: {
        'required': 'Please enter your before you dig sequence number.',
        'min': 'This is not a valid sequence number. The number can be found on the beforeUdig Response. If you have not received a response or are yet to register your job please visit their website beforeudig.co.nz or contact them on 0800 248 344',
        'max': 'This is not a valid sequence number. The number can be found on the beforeUdig Response. If you have not received a response or are yet to register your job please visit their website beforeudig.co.nz or contact them on 0800 248 344',
        'numeric': 'This is not a valid sequence number. The number can be found on the beforeUdig Response. If you have not received a response or are yet to register your job please visit their website beforeudig.co.nz or contact them on 0800 248 344'
    }
}

const content = require('../../../json/content/content.json')

class CableLocation extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            cableLocation: {
                description: ['required'],
                beforeUDigReferenceNumber: ['required', 'numeric', {'min': 7}, {'max': 9}],
                serviceToLocateFibre: ['oneRequired'],
                serviceToLocateElectricity: ['oneRequired']
            }
        }

        this.validationErrorFields = {
            cableLocation_descriptionErrors: [],
            cableLocation_beforeUDigReferenceNumberErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            cableLocation_serviceToLocateHasError: false,
            oneRequiredError: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.cableLocation_serviceToLocateHasError && (this.props.cableLocation.serviceToLocateFibre || this.props.cableLocation.serviceToLocateElectricity))
        {
            this.setState({cableLocation_serviceToLocateHasError: false, oneRequiredError: false})
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/cable-location'})

            const previousSectionSlug = getPreviousSectionSlug(this.props.services, this.props.connections, this.props.propertyType, this.props.historyLocation, this.props.serviceAddress)
            this.props.updateLocation(previousSectionSlug, null, 'back')

            return true
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validate = () => {
        if (!this.props.serviceToLocateFibre && !this.props.serviceToLocateElectricity)
        {
            this.setState({cableLocation_serviceToLocateHasError: true})
        }
        else if (this.state.cableLocation_serviceToLocateHasError)
        {
            this.setState({cableLocation_serviceToLocateHasError: false})
        }

        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()
        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/cable-location'})
            return false
        }

        this.props.addProgress({'path': '/cable-location'})

        return getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields) || this.state.oneRequiredError

        return (
            <div id="close-approach" className="section-margin-bottom">
                <Header
                    title="Need to know a cable location?"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <div>
                                <p>In most cases we offer a free service to help you to locate underground cables, pipes and ducts before you begin digging or excavating. If power or fibre cables are struck and damaged, it can knock out important electrical or communications supplies. It could also result in an electric shock, explosion or death.</p>
                                <p>Find out more about cable location requests on the <Link text="Northpower website" link={links.cableLocation} />.</p>
                                <div>You will need to register your job with Before U Dig before you start any work. Visit the  <Link text="Before U Dig website" link={links.beforeUDig} /> or call 0800 248 344.</div>
                            </div>
                        } />

                        <div className="wrapper">

                            <MandatoryText />

                            <Input
                                label="Before you dig sequence number"
                                type='number'
                                mandatoryIndicator={true}
                                keyString="cableLocation.beforeUDigReferenceNumber"
                                parentErrorKey="cableLocation_beforeUDigReferenceNumberErrors"
                                value={this.props.cableLocation.beforeUDigReferenceNumber}
                                validationMessages={validationMessages.cableLocation_beforeUDigReferenceNumber}
                                validationErrorsFromParent={this.state.cableLocation_beforeUDigReferenceNumberErrors}
                                validationRules={this.validationRules.cableLocation.beforeUDigReferenceNumber}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <div className={!this.state.cableLocation_serviceToLocateHasError ? 'form-group multi-checkbox' : 'form-group multi-checkbox error'}>
                                <div className='multi-checkbox__wrapper'>
                                    <p>Please tell us what services you want located:<MandatoryIndicator/></p>

                                    <Checkbox
                                        keyString='cableLocation.serviceToLocateElectricity'
                                        label="Electricity"
                                        updateStoreValue={this.props.updateStoreValue}
                                        isChecked={this.props.cableLocation.serviceToLocateElectricity}
                                    />

                                    <Checkbox
                                        keyString='cableLocation.serviceToLocateFibre'
                                        label="Fibre"
                                        updateStoreValue={this.props.updateStoreValue}
                                        isChecked={this.props.cableLocation.serviceToLocateFibre}
                                    />

                                    {this.props.cableLocation.serviceToLocateFibre ?
                                        <Alert type="description" text='For non-residential Fibre Cable Locates, a minimum fee of $174 excl. GST applies.' />
                                    : null}
                                </div>
                                {this.state.cableLocation_serviceToLocateHasError ?
                                    <Alert text='Please select at least one service you want located.' type="danger"/>
                                : null}
                            </div>

                            <Textarea
                                label="Let us know as much information as you can about the work you are intending to do."
                                mandatoryIndicator={true}
                                keyString="cableLocation.description"
                                parentErrorKey="cableLocation_descriptionErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.cableLocation.description}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.cableLocation_description}
                                validationErrorsFromParent={this.state.cableLocation_descriptionErrors}
                            />
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <CableLocation ref={ref} {...props} />
    })
)