export default (upload, jobUuids) => {
    const endpoint = process.env.CTP_ENDPOINT+'/upload'

    let formData = new FormData()

    formData.append('name', upload.name)
    formData.append('file', new Blob([upload.data], {type: "text/plain"}))
    formData.append('jobUuids', JSON.stringify(jobUuids))
    formData.append('version', process.appVersion)

    return fetch(endpoint, {
        method: 'post',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    })
    .then(res => res.json())
    .then(
        (res) => {return res}
    )
}