import React from 'react'
import Link from "../../components/common/link/link"

const links = require('../../json/links/links.json')

export default {
    'electricityRetailer': <><b>Electricity retailer</b> - if you do not have a retailer you can use the <Link text="Powerswitch website" link={links.whatsMyNumber}/> to compare providers.</>,
    'electricityRetailAccountHolder': <><b>Electricity retail account holder</b> contact details</>,
    'billPayer': <><b>Bill payer contact details</b> - if there is a cost associated with this work then the quote and invoice will be issued to this person</>,
    'electrician': <><b>Electrician</b> contact details</>,
    'systemProviderAndInstaller': <>System provider and installer contact details</>,
    'surveyor': <><b>Surveyor</b> contact details</>,
    'developer': <><b>Developer</b> contact details</>,
    'generationType': <>The type of alternative generation, your generating and storage capacity, and the make and model of your inverter</>,
    'icp': <><b>ICP Number</b> - an ICP is a number identifying your property's Installation Control Point. You'll find this 15 digit number on your power bill</>,
    'subdivisionPlans': <>The plans for your subdivision, you'll need to upload these at the end of this application process</>
}