import React, {PureComponent} from 'react'
import Logo from '../../../assets/svgs/header-logo.svg'
import HamburgerIcon from '../../../assets/svgs/hamburger.svg'

class HeaderBar extends PureComponent {
    render(){
        return (
            <>
                <div id="header-bar">
                    <Logo id="logo" />
                    {this.props.showHamburger ?
                        <HamburgerIcon id="hamburger" style={{float: 'right'}} className={this.props.sidebarOpen ? 'open' : null} onClick={this.props.onHamburgerClick} />
                    : null}
                </div>
            </>
        )
    }
}

export default HeaderBar