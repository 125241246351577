import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Textarea from "../../common/textarea/textarea"
import Header from "../../common/header"
import Upload from "../../common/upload"
import Dropdown from "../../common/dropdown"
import typeOfEnquiryOptions from "../../../json/general/general-enquiry-type-of-enquiry-data.json"
import validator from "../../../services/validator/validator"
import content from "../../../json/content/content.json"
import hasError from "../../../helpers/has-error"
import hasCoordinates from "../../../helpers/has-coordinates"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: {
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
        },
        otherInformation: {
            uploads: existsGet(state, 'otherInformation.uploads', [])
        },
        generalEnquiry: {
            detailsOfEnquiry: existsGet(state, 'generalEnquiry.detailsOfEnquiry', ''),
            typeOfEnquiry: existsGet(state, 'generalEnquiry.typeOfEnquiry', null)
        },
        serviceAddress: existsGet(state, 'serviceAddress', null)
    }
}

const validationMessages = {
    generalEnquiry_typeOfEnquiry: {
        'required': 'Please select the enquiry type.'
    },
    generalEnquiry_detailsOfEnquiry: {
        'required': 'Please enter your enquiry.'
    }
}

class EnquiryDetails extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            generalEnquiry: {
                typeOfEnquiry: ['required'],
                detailsOfEnquiry: ['required']
            }
        }

        this.validationErrorFields = {
            generalEnquiry_typeOfEnquiryErrors: [],
            generalEnquiry_detailsOfEnquiryErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            uploadRequiredError: false
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    newUpload = (fileName, fileSize, binaryStr) => {
        if (this.state.uploadRequiredError)
        {
            this.setState({uploadRequiredError: false})
        }

        this.props.updateStoreValue('otherInformation.uploads', [...this.props.otherInformation.uploads, {name: fileName, data: binaryStr, size: fileSize}])
    }

    removeUploadedFile = (id) => {
        const uploads = this.props.otherInformation.uploads.filter((file, i) => i !== id)

        this.props.updateStoreValue('otherInformation.uploads', uploads)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'summary' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.serviceAddress.addressManuallyAdded || this.props.serviceAddress.enquiryNotRelatedToSiteLocation || !hasCoordinates(this.props.serviceAddress) ? this.props.updateLocation('/name-and-address', null, 'back') : this.props.updateLocation('/confirm-location', null, 'back')

            return true
        }

        return false
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()
        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/enquiry-details'})

            return false
        }

        this.props.addProgress({'path': '/enquiry-details'})

        return '/summary'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="other-information" className="section-margin-bottom">
                <Header
                    title="Your Enquiry"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        {this.state.uploadRequiredError ?
                            <div className="validation-top-wrapper">
                                <Alert text='Please upload your plan.' type="danger" />
                            </div>
                        : null}

                        <div className="wrapper">
                            <Dropdown
                                label={"Type of Enquiry"}
                                mandatoryIndicator={true}
                                keyString={"generalEnquiry.typeOfEnquiry"}
                                parentErrorKey="generalEnquiry_typeOfEnquiryErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                selectedOption={this.props.generalEnquiry.typeOfEnquiry}
                                options={typeOfEnquiryOptions}
                                isSearchable={true}
                                validationMessages={validationMessages.generalEnquiry_typeOfEnquiry}
                                validationErrorsFromParent={this.state.generalEnquiry_typeOfEnquiryErrors}
                                validationRules={this.validationRules.generalEnquiry.typeOfEnquiry}
                                setParentState={this.setParentState}
                            />

                            <Textarea
                                label="Your Enquiry"
                                keyString="generalEnquiry.detailsOfEnquiry"
                                parentErrorKey="generalEnquiry_detailsOfEnquiryErrors"
                                value={this.props.generalEnquiry.detailsOfEnquiry}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                                mandatoryIndicator={true}
                                validationMessages={validationMessages.generalEnquiry_detailsOfEnquiry}
                                validationErrorsFromParent={this.state.generalEnquiry_detailsOfEnquiryErrors}
                            />

                            <Upload
                                newUpload={this.newUpload}
                                currentFiles={this.props.otherInformation.uploads}
                                removeUploadedFile={this.removeUploadedFile}
                                uploadRequiredError={this.state.uploadRequiredError}
                            />
                        </div>

                        {this.props.showBottomNavigation && this.state.uploadRequiredError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text='Please upload your plan.' type="danger" />
                            </div>
                        : null}

                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <EnquiryDetails ref={ref} {...props} />
    })
)
