import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ValidationAlert from "../../../components/common/validation-alert"
import DropdownIcon from "../../../assets/svgs/dropdown/dropdown.svg"
import MandatoryIndicator from "../mandatory-indicator/mandatory-indicator"
import keyStringToId from "../../../helpers/key-string-to-id"

class Dropdown extends PureComponent {
    constructor(props) {
        super(props)

        this.id = keyStringToId(this.props.keyString)
        this.valueLabels = {}

        this.props.options.map((object) => {
            this.valueLabels[object.value] = object.label
        })
    }

    handleChange = (selectedOption) => {
        this.props.updateStoreValue(this.props.keyString, selectedOption.value)

        if (typeof this.props.parentErrorKey !== 'undefined')
        {
            this.props.setParentState({[this.props.parentErrorKey]: []})
        }

        if (this.props.hasOneRequired)
        {
            this.props.setParentState({oneRequiredError: false})
        }
    }

    render(){
        const { options, label, selectedOption } = this.props
        let validationErrors = []

        if (typeof this.props.validationErrorsFromParent !== 'undefined')
        {
            validationErrors = [...this.props.validationErrorsFromParent]
        }

        const hasErrors = validationErrors.length > 0

        const selectValue = {
            label: this.valueLabels[selectedOption],
            value: selectedOption
        }

        let className = "form-group"
        if (hasErrors)
        {
            className += " error"
        }

        return (
            <div className={className}>
                <label htmlFor={this.id}>{this.props.mandatoryIndicator ? <MandatoryIndicator>{label}</MandatoryIndicator> : label}</label>
                <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    id={this.id}
                    value={selectValue}
                    onChange={this.handleChange}
                    options={options}
                    isSearchable={this.props.isSearchable}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => <DropdownIcon />
                    }}
                />

                {hasErrors ?
                    <ValidationAlert validationRules={this.props.validationRules} validationErrors={validationErrors} validationMessages={this.props.validationMessages} />
                : null}
            </div>
        )
    }
}

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
}

export default Dropdown