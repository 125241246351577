import send from "./send"
import getCheckedFields from "../../helpers/get-checked-fields"
import doesHaveElectricalConnectionChecked from "../../helpers/does-have-electrical-connection-checked"
import doesHaveCopyFromContactId from "../../helpers/does-have-copy-from-contact-id"
import getAllRoles from "../roles/get-all-roles"
import showShowPropertyType from "../../helpers/should-show-property-type"
import existsGet from "../../helpers/exists-get"
import getProgressWithoutNonConnectionServices from "../../helpers/get-progress-without-non-connection-services"
import getExpectedUploadNames from "../../helpers/get-expected-upload-names";

function getElectricalTypes(checkedConnections){
    const types = ['electricity', 'alteration', 'bts', 'newSubdivision', 'onsiteGeneration', 'ballparkEstimate']
    const checkedElectricalTypes = checkedConnections.filter((connection) => types.includes(connection))

    return checkedElectricalTypes.map((connection) => {
        return connection === 'electricity' ? 'standard' : connection
    })
}

function getContactIdsWithCopyFromContactId(contacts) {
    const contactKeys = Object.keys(contacts)
    return contactKeys.filter(key => {
        const contact = contacts[key]
        return doesHaveCopyFromContactId(contact)
    }).reduce((result, contactId) => {
        result.push(contactId)
        return result
    }, [])
}

function getUpdatedRoles(originalRoles, originalContacts, contactsWithCopyContactIds) {
    let updatedRoles = {}

    const originalRoleKeys = Object.keys(originalRoles)

    originalRoleKeys.forEach(contactId => {
        const role = originalRoles[contactId][0]
        if (contactsWithCopyContactIds.includes(contactId))
        {
            const copyFromContactId = originalContacts[contactId]['copyFromContactId']

            if (typeof updatedRoles[copyFromContactId] !== 'undefined')
            {
                updatedRoles[copyFromContactId] = [
                    ...updatedRoles[copyFromContactId],
                    role
                ]
            }
            else
            {
                updatedRoles[copyFromContactId] = [
                    role
                ]
            }
        }
        else
        {
            if (typeof updatedRoles[contactId] === 'undefined')
            {
                updatedRoles[contactId] = originalRoles[contactId]
            }
            else
            {
                updatedRoles[contactId] = [
                    ...originalRoles[contactId],
                    ...updatedRoles[contactId]
                ]
            }
        }
    })

    return updatedRoles
}

function formatContacts(originalContacts, originalRoles) {
    let result = []

    const contactsWithCopyContactIds = getContactIdsWithCopyFromContactId(originalContacts)
    const roles = getUpdatedRoles(originalRoles, originalContacts, contactsWithCopyContactIds)

    const originalContactsKeys = Object.keys(originalContacts)
    const contacts = originalContactsKeys.filter((originalContactId) => {
        return !contactsWithCopyContactIds.includes(originalContactId)
    }).reduce((result, contactId) => {
        result[contactId] = originalContacts[contactId]
        return result
    }, {})

    let atLeastOneContactHasBillPayerRole = false
    Object.keys(roles).forEach((i) => {
        const contactRoles = roles[i]
        if (contactRoles.includes('Bill Payer'))
            atLeastOneContactHasBillPayerRole = true
    })

    // this is applicable to at least ballParkEstimate - where Bill Payer is optional
    // we manually add the Bill Payer role to the main contact, so that the payment PDF receipts is handled correctly
    if (!atLeastOneContactHasBillPayerRole)
        roles[0] = [...roles[0], 'Bill Payer']

    Object.keys(contacts).map((contactId) => {
        const contact = contacts[contactId]

        let contactDetails = {
            firstName: contact.firstName,
            lastName: contact.lastName,
            phoneNumber: contact.phoneNumber,
            mobilePhoneNumber: contact.mobileNumber,
            emailAddress: contact.emailAddress
        }

        if (contact.preferredMethodOfContact) {
            contactDetails.preferredMethodOfContact = contact.preferredMethodOfContact
        }

        if (contact.addressNumber) {
            contactDetails.mailingAddress = {
                number: contact.addressNumber,
                street: contact.addressStreet,
                suburb: contact.addressSuburb,
                city: contact.addressCity,
                postcode: contact.addressPostcode
            }
        }

        if (contact.companyName) {
            contactDetails.companyName = contact.companyName
        }

        if (contact.siteContact) {
            contactDetails.siteContact = contact.siteContact
        }

        contactDetails.roles = roles[contactId]

        result.push(contactDetails)
    })

    return result
}

function contactPassesFilter(roles, contactId, connectionTypes) {
    if (!getAllRoles(connectionTypes).includes(roles[contactId][0]))
    {
        return false
    }

    return true
}

function getFilteredContacts(contacts, roles, connectionTypes) {
    return Object.keys(contacts).filter(contactId => {
        return contactPassesFilter(roles, contactId, connectionTypes)
    }).reduce((result, contactId) => {
        result[contactId] = contacts[contactId]
        return result
    }, {})
}

function getConnectionServicesReduxData(data) {
    const dataKeys = Object.keys(data)

    let result = {}
    result.services = {}
    result.otherInformation = {}

    dataKeys.forEach((key) => {
        const unneededKeys = [
            'safetyDisconnection',
            'vegetationControl',
            'cableLocation',
            'closeApproachConsent',
            'historyData',
            'content',
            'services', // we're not copying this value, but it is set to {} above the loop
            'previousUploadsReset',
            'uploadsModifiedAfterSubmitPage'
        ]

        if (unneededKeys.includes(key))
            return

        if (key === 'progress') {
            result.progress = getProgressWithoutNonConnectionServices(data['progress'])
            return
        }

        if (key === 'otherInformation') {
            // iterating over it this way prevents potential memory issues, as we're not assigning uploads to a variable
            Object.keys(data['otherInformation']).forEach((key) => {
                if (key === 'uploads')
                    return

                result.otherInformation[key] = data['otherInformation'][key]
            })
            return
        }

        result[key] = {}
        result[key] = data[key]
    })

    return result
}

function getPreferredRetailerObject(retailerName, picklist)
{
    let result = {}
    if (retailerName.length === 0)
    {
        return {
            "code": "",
            "agreementType": "",
            "availableForNewICPs": ""
        }
    }

    picklist.some((retailer) => {
        if (retailer.name === retailerName)
        {
            result = {
                code: retailer.code,
                agreementType: retailer.agreementType,
                availableForNewICPs: retailer.availableForNewICPs
            }
            return true
        }
    })

    return result
}

function getFibrePayload(data)
{
    let fibrePayload = {
        prebuild: 0,
        available: 0
    }

    const fibreAvailable = data.fibre && data.fibre.status !== null

    fibrePayload.prebuild = fibreAvailable && data.fibre.readyPropertyIsChecked ? 1 : 0
    fibrePayload.available = fibreAvailable ? 1 : 0

    return fibrePayload
}

export default (props) => {
    let payload = {}
    payload.version = process.appVersion
    payload.service = {}
    payload.service.connection = {}

    let data = props.data

    const {connections} = data

    const checkedConnections = getCheckedFields(connections)
    const hasElectricalConnectionChecked = doesHaveElectricalConnectionChecked(connections)

    const filteredContacts = getFilteredContacts(data.contacts, data.roles, checkedConnections)

    payload.contactDetails = formatContacts(filteredContacts, data.roles, props)

    payload.serviceAddress = data.serviceAddress

    if (hasElectricalConnectionChecked)
    {
        const electricity = data.electricity
        const electricalTypes = getElectricalTypes(checkedConnections)
        const isBallparkEstimate = typeof data.connections.ballparkEstimateIsChecked !== 'undefined' && data.connections.ballparkEstimateIsChecked

        let electricityPayload = electricity || isBallparkEstimate ? {
            retailerDetails: {
                preferredRetailer: existsGet(electricity, 'retailer', ''),
                retailerConsumerNumber: existsGet(electricity, 'electricityRetailerCustomerNumber', ''),
                ...getPreferredRetailerObject(existsGet(electricity, 'retailer', ''), props.data.content.preferredRetailerPicklist)
            },
            networkApprovedContractor: existsGet(electricity, 'networkApprovedContractor', ''),
            serviceMain: existsGet(electricity, 'serviceMain', ''),
            propertyType: showShowPropertyType(checkedConnections) ? data.propertyType : '',
            icpNumber: checkedConnections.includes('onsiteGeneration') || checkedConnections.includes('alteration') ? electricity.icpNumber : '',
            maximumDemandKW: existsGet(electricity, 'maximumDemandKW', ''),
            maximumDemandAmps: existsGet(electricity, 'maximumDemandAmps', ''),
            controlledLoad: typeof electricity !== 'undefined' && electricity.controlledLoad ? 1 : 0,
            fuseCurrentRatings: typeof electricity !== 'undefined' && electricity.fuseCurrentRatings && data.propertyType !== 'Caravan' ? electricity.fuseCurrentRatings : ''
        } : {}

        if (electricalTypes.includes('bts'))
        {
            electricityPayload.bts = {}
            electricityPayload.bts.dateRequested = ''
        }

        if (electricalTypes.includes('standard'))
        {
            electricityPayload.standard = {}
            electricityPayload.standard.dateRequested = ''
            electricityPayload.standard.quoteRequired = connections['electricityQuoteRequired'] ? 1 : 0
        }

        if (electricalTypes.includes('alteration'))
        {
            electricityPayload.alteration = {}
            electricityPayload.alteration.riskCategory = data.alteration && data.alteration.riskCategory || ''
            electricityPayload.alteration.workType = data.alteration && data.alteration.workType || ''
            electricityPayload.alteration.description = data.alteration && data.alteration.description || ''
            electricityPayload.alteration.dateRequested = ''
        }

        if (electricalTypes.includes('newSubdivision'))
        {
            electricityPayload.subdivision = {}
            electricityPayload.subdivision.dateRequested = ''
            electricityPayload.subdivision.connectionPoints = existsGet(data, 'subdivision.connectionPoints', '') // manual-capacity
        }

        if (electricalTypes.includes('onsiteGeneration'))
        {
            electricityPayload.onsiteGeneration = {}
            electricityPayload.onsiteGeneration.dateRequested = ''
            electricityPayload.onsiteGeneration.connectionType = data.onsiteGeneration.connectionType
            electricityPayload.onsiteGeneration.generationCapacityKw = data.onsiteGeneration.generationCapacityKw
            electricityPayload.onsiteGeneration.phases = data.onsiteGeneration.phases
            electricityPayload.onsiteGeneration.connectionVoltage = data.onsiteGeneration.voltage
            electricityPayload.onsiteGeneration.generationType = data.onsiteGeneration.type
            electricityPayload.onsiteGeneration.storageType = data.onsiteGeneration.storageType
            electricityPayload.onsiteGeneration.storageCapacityKwh = data.onsiteGeneration.storageType !== 'No storage' ? data.onsiteGeneration.storageCapacityKwh : ''
            electricityPayload.onsiteGeneration.inverterManufacturer = data.onsiteGeneration.inverterManufacturer
            electricityPayload.onsiteGeneration.inverterModel = data.onsiteGeneration.inverterModel
            electricityPayload.onsiteGeneration.compliant = data.onsiteGeneration.compliant ? 1 : 0
        }

        if (electricalTypes.includes('ballparkEstimate'))
        {
            electricityPayload.ballparkEstimate = true
        }

        payload.service.connection.electricity = electricityPayload
    }
    else if (connections.projectIsChecked)
    {
        payload.service.connection.electricity = {
            maximumDemandKW: existsGet(data, 'electricity.maximumDemandKW', ''),
            maximumDemandAmps: existsGet(data, 'electricity.maximumDemandAmps', ''),
            project: {
                siteLocationDetails: existsGet(data, 'project.siteLocationDetails', ''), // name-and-address
                description: existsGet(data, 'project.description', '') // manual-capacity
            }
        }
    }

    payload.otherInformation = {}
    payload.otherInformation.notes = data.otherInformation && data.otherInformation.notes ? data.otherInformation.notes : ''
    payload.otherInformation.locationInformation = data.otherInformation && data.otherInformation.locationInformation ? data.otherInformation.locationInformation : ''
    payload.otherInformation.expectedUploadNames = getExpectedUploadNames(data)

    const shouldHaveFibrePayload = checkedConnections.includes('electricity') || checkedConnections.includes('bts') || checkedConnections.includes('newSubdivision') || checkedConnections.includes('alteration') || checkedConnections.includes('project')
    if (shouldHaveFibrePayload)
    {
        payload.service.connection.fibre = getFibrePayload(data)
    }

    // creates new copyFromContactId object, without copyFromContactId & termsAccepted
    const { copyFromContactId, termsAccepted, ...strippedContactObject } = data.contacts['0']

    payload.newOrderData = {
        yourDetails: strippedContactObject
    }

    payload.reduxData = getConnectionServicesReduxData(data)

    payload.previousPaymentLinkUuid = ''

    if (hasElectricalConnectionChecked && typeof data.previousPaymentLinkUuid !== 'undefined')
        payload.previousPaymentLinkUuid = data.previousPaymentLinkUuid

    payload.deleteExistingUploads = (
        (
            typeof data.uploadsModifiedAfterSubmitPage !== 'undefined' &&
            data.uploadsModifiedAfterSubmitPage === true
        )
        ||
        (
            data.previousUploadsReset &&
            (
                typeof data.otherInformation === 'undefined' ||
                typeof data.otherInformation.uploads === 'undefined' ||
                data.otherInformation.uploads.length === 0
            )
        )
    ) ? 1 : 0

    return send(process.env.CTP_ENDPOINT+'/service/connection', payload)
}
