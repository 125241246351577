import React, {Component} from 'react'
import TextInputWrapper from '../../../hocs/text-input-wrapper'
import MandatoryIndicator from "../../../components/common/mandatory-indicator"

class Textarea extends Component {
    render(){
        return (
            <>
                <label htmlFor={this.props.id}>{this.props.mandatoryIndicator ? <MandatoryIndicator>{this.props.label}</MandatoryIndicator> : this.props.label}</label>
                {this.props.description ? this.props.description : null}
                <textarea id={this.props.id} className="form-control" onChange={this.props.handleChange} value={this.props.value} rows="4" cols="50" />
            </>
        )
    }
}

export default TextInputWrapper(Textarea)