export default () => {
    return {
        "electricity": 100,
        "bts": 100,
        "ballparkEstimate": 100,
        "alteration": 100,
        "onsiteGeneration": {
            'Up to 10kW': 100,
            '11kW - 100kW': 500,
            '101kW - 1MW (1000kW)': 1000,
            'More than 1MW (1000kW)': 5000
        },
        "newSubdivision": 500,
        "ballParkEstimate": 100
    }
}