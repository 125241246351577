import React from 'react'

export default <div>
    <ol>
        <p className='text-uppercase'>Important notice for working near underground power cables</p>
        <li>
            <p>The Applicant or their contractor is required to be on site when the cables are being located and marked. This is to ensure that the marked out area is correct and the Applicant or their contractor fully understands the mark-out and their obligations. Northpower will contact you to make an appointment;</p>
        </li>
        <li>
            <p>If the Applicant or their contractor cancels within 2 hours of the agreed time OR is not on site within 15 minutes of the agreed time then the cable location will not be carried out and the Applicant will be charged a cancellation fee, no fee applies to a cancellation made at least two hours before the agreed time;</p>
        </li>
        <li>
            <p>Northpower is not responsible for identifying buried services of other utilities, the Applicant will need to ensure that all applicable utility owners are contacted to arrange for their assets to be located by them;</p>
        </li>
        <li>
            <p>The precise location of cables are prone to many variables and: a. The position is subject to reasonable tolerance and may not be directly below the marked area; and b. The depth of cover may also have changed since installation as ground levels are altered;</p>
        </li>
        <li>
            <p>It is the responsibility of the Applicant or their contractor to verify the position of cables before excavating; this must be done with careful potholing by hand digging. Northpower can produce a quote to provide this service;</p>
        </li>
        <li>
            <p>Where excavation is to be carried out within 1000mm of a Northpower cable a Northpower Safety Observer is required on site. Northpower can produce a quote to provide this service (both parties must comply with current health and safety requirements);</p>
        </li>
        <li>
            <p>The Northpower Safety Observer will ensure that the requirements within this Notice are met and will take control of the site should a cable strike occur. This does not absolve the Applicant of any responsibility for the damage to any Northpower asset including any repair costs;</p>
        </li>
        <li>
            <p>Machine excavation between 500mm and 1000mm of a Northpower cable is not recommended without positive confirmation of the cable route via potholing;</p>
        </li>
        <li>
            <p>Excavation within 500mm of a Northpower cable must be carried out by hand;</p>
        </li>
        <li>
            <p>If the Applicant or their contractor expose any Northpower cable (including via potholing) or cause any damage to a Northpower cable, no matter how minor, Northpower must be notified immediately. Any reinstatement around exposed cable must be carried out by Northpower to ensure correct reinstatement. Any damage to cables must be inspected and if necessary repaired by Northpower before reinstatement. The call out and inspection will be free of charge however any reinstatement or repair costs will be charged to the Applicant;</p>
        </li>
        <li>
            <p>DO NOT, for any reason, touch any power cable with any part of your body or with a tool. If you have any concerns please contact Northpower immediately for assistance. By entering and/or interfering with a network asset without expressed written consent from Northpower Network, you will have contravened Section 35 Clauses 1 and 2 of the Electricity (Safety) Regulations 2010 (Interference with or movement of works). This may lead to prosecution and penalties under the aforementioned Regulation;</p>
        </li>
        <li>
            <p>If you require any Northpower asset to be isolated, relocated or altered contact Northpower Faults (0800 10 40 40).</p>
        </li>
    </ol>
</div>