import React, {PureComponent} from 'react'
import Link from "../link"
import Button from "../button"

const links = require('../../../json/links/links.json')

class Footer extends PureComponent {
    render(){
        const date =  new Date()
        const year = date.getFullYear()

        return (
            <footer id="main-footer">
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <span className="orange">© Northpower Limited {year}</span>
                                <Link text='Terms of Use' link={links.termsOfUse} />
                                <Link text='Privacy' link={links.privacy} />
                                <Link text='Northpower' link={links.northpower} />
                            </div>
                            <div className="col-lg-4">
                                <div>
                                    <span>24 hour electricity faults & outages</span>
                                    <span className='mb-1'>Kaipara & Whangarei, call:</span>
                                    <a href="tel:0800104040">0800 10 40 40</a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div>
                                    <span>For questions or help with these forms, please call customer care on</span>
                                    <a href="tel:0800667847">0800 667 847</a>
                                    {process.env.APP_ENV === 'dev' ?
                                        <>
                                            <Button className="mt-default" text="Go back to start" action={() => {this.props.updateLocation('/')}} />
                                            <Button className="mt-default" text="Go to end" action={() => {this.props.updateLocation('/summary')}} />
                                        </>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer