const defaultContactObject = require('../json/defaults/default-contact-object.json')
const defaultServiceAddressObject = require('../json/defaults/default-service-address-object.json')

export default {
    'progress': [],
    'contacts': {
        0: defaultContactObject
    },
    'roles': {
        0: ['Applicant']
    },
    'serviceAddress': defaultServiceAddressObject,
    'historyLocation': '/',
    'historyAction': null,
    'connections': {},
    'services': {},
    'propertyType': null,
    'icpNumbersApproved': [],
    'icpNumbersDenied': [],
    'icpNumberPendingApprovalLatest': null,
    'previousPaymentLinkUuid': '',
    'previousUploadFileNames': [],
    'previousUploadsReset': false,
    'uploadsModifiedAfterSubmitPage': null,
    'appWrapperShouldRender': false,
    'dataRequestIsLoading': false,
    'googleLibsAreLoading': true,
    'isNewOrder': false,
    'content': null,
    'nameAndAddressHeaderTitleOverrideText': null
}
