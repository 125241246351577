import getCheckedFields from "../../helpers/get-checked-fields"
import hasCoordinates from "../../helpers/has-coordinates"

function getLastNonConnectionServiceSlug(nonConnectionNamesAndSlugs, servicesChecked){
    let result = null
    Object.keys(nonConnectionNamesAndSlugs).forEach((name) => {
        if (servicesChecked.includes(name))
        {
            result = name
        }
    })

    return nonConnectionNamesAndSlugs[result]
}

function getNextNonConnectionSlug(nonConnectionNamesAndSlugs, servicesChecked, historyLocation){
    let result = null
    const nonConnectionNames = Object.keys(nonConnectionNamesAndSlugs)
    let slugs = []

    for (let name of nonConnectionNames)
    {
        const slug = nonConnectionNamesAndSlugs[name]
        slugs.push(slug)
    }

    if (!slugs.includes(historyLocation))
    {
        return nonConnectionNamesAndSlugs[nonConnectionNames[0]]
    }

    const slugCount = slugs.length
    slugs.forEach((slug, i) => {
        if (slug === historyLocation && slugCount-1 > i && slugs[i+1])
        {
            result = slugs[i+1]
        }
    })

    return result
}

export function getNonConnectionNamesAndSlugs(servicesChecked)
{
    const raw = {
        vegetationControl: '/vegetation-control',
        safetyDisconnection: '/temporary-disconnection',
        closeApproachConsent: '/close-approach-consent',
        cableLocation: '/cable-location'
    }

    return Object.keys(raw).filter((key) => servicesChecked.includes(key)).reduce((obj, key) => {
        obj[key] = raw[key]
        return obj
    }, {})
}

function getPreviousNonConnectionSlug(services, historyLocation)
{
    const servicesChecked = getCheckedFields(services)
    const nonConnectionNamesAndSlugs = getNonConnectionNamesAndSlugs(servicesChecked)

    let previousNonConnectionSlug = null
    for (let name of Object.keys(nonConnectionNamesAndSlugs))
    {
        const slug = nonConnectionNamesAndSlugs[name]

        if (slug === historyLocation)
        {
            break
        }

        previousNonConnectionSlug = slug
    }

    return previousNonConnectionSlug
}

export function getPreviousSectionSlug(services, connections, propertyType, historyLocation, serviceAddress = null) {
    const checkedConnections = getCheckedFields(connections)
    const previousNonConnectionSlug = getPreviousNonConnectionSlug(services, historyLocation)

    const hasSingleConnectionChecked = checkedConnections.length === 1
    const hasNoConnectionChecked = checkedConnections.length === 0

    if (previousNonConnectionSlug !== null)
    {
        return previousNonConnectionSlug
    }

    if (hasNoConnectionChecked)
    {
        if (serviceAddress !== null && serviceAddress.addressManuallyAdded !== true && hasCoordinates(serviceAddress))
        {
            return '/confirm-location'
        }

        return '/name-and-address'
    }

    if (propertyType === 'Caravan' || (hasSingleConnectionChecked && checkedConnections.includes('bts')))
    {
        return checkedConnections.includes('onsiteGeneration') ? '/distributed-generation-capacity' : '/approved-contractor'
    }

    if (hasSingleConnectionChecked && checkedConnections.includes('newSubdivision'))
    {
        return '/roles-hub'
    }

    if (checkedConnections.includes('onsiteGeneration'))
    {
        return '/distributed-generation-capacity'
    }

    return '/manual-capacity'
}

export function getNextNonConnectionService(services, connections, historyLocation){
    const connectionsChecked = getCheckedFields(connections)
    const servicesChecked = getCheckedFields(services)
    let hasConnection = connectionsChecked.length > 0

    const nonConnectionNamesAndSlugs = getNonConnectionNamesAndSlugs(servicesChecked)
    if (Object.keys(nonConnectionNamesAndSlugs).length === 0)
    {
        return null
    }

    const lastNonConnectionName = getLastNonConnectionServiceSlug(nonConnectionNamesAndSlugs, servicesChecked)

    // handles if there's a connection and a non-connection service, but the only non-connection service is "irrelevant"
    if (lastNonConnectionName === null)
    {
        return null
    }

    if (lastNonConnectionName === historyLocation)
    {
        return hasConnection ? '/other-information' : '/summary'
    }

    return getNextNonConnectionSlug(nonConnectionNamesAndSlugs, servicesChecked, historyLocation)
}