import React, {PureComponent} from 'react'
import ValidationAlert from "../../common/validation-alert"
import DayPicker from "./day-picker"
import MandatoryIndicator from "../mandatory-indicator"
import checkDateSame from "../../../helpers/date/check-date-same-not-null"

const validationMessages = {
    'required': 'Please select your requested date for this service.'
}

class Calendar extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            focused: false,
            previousDate: this.props.dateRequested // used to prevent race conditions with handleFocusChange
        }
    }

    onDayClick = (date, modifiers = {}) => {
        if (modifiers.disabled) {
            return
        }

        let value = Object.assign(date)

        if (checkDateSame(date, this.props.dateRequested))
        {
            value = null
        }

        this.props.updateStoreValue(this.props.keyString + 'DateRequested', value)
        this.props.setParentState({[this.props.parentErrorKey]: []})

        this.props.setParentState({[this.props.parentErrorKey]: []})
    }

    leadTime = () => {
        let workDaysPast = 0
        let currentIterationDate = new Date()
        while (workDaysPast < this.props.leadTime)
        {
            if (![0, 6].includes(currentIterationDate.getDay())) {
                workDaysPast++
            }

            currentIterationDate.setDate(currentIterationDate.getDate()+1)
        }

        // don't let the day fall on a Sunday
        if (currentIterationDate.getDay() === 0)
        {
            currentIterationDate.setDate(currentIterationDate.getDate()+1)
        }

        // don't let the day fall on a Saturday
        if (currentIterationDate.getDay() === 6)
        {
            currentIterationDate.setDate(currentIterationDate.getDate()+2)
        }

        return currentIterationDate
    }

    render(){
        const firstDate = this.leadTime()

        const validationErrors = this.props.validationErrorsFromParent
        const hasError = validationErrors.length > 0

        let className = "calendar-wrapper"

        if (hasError)
        {
            className += ' has-error'
        }

        if (this.props.standalone)
        {
            className += ' standalone'
        }

        let landingMonth = Object.assign(firstDate)

        if (this.props.dateRequested !== null)
        {
            landingMonth = this.props.dateRequested
        }

        return (
         <div className={className}>
             {this.props.label ?
                 <h2>{this.props.mandatoryIndicator ? <MandatoryIndicator>{this.props.label}</MandatoryIndicator> : this.props.label}</h2>
             : null}
             {this.props.leadNotice ?
                 <div className="lead-notice">
                     {this.props.leadNotice}
                 </div>
             : null}
             <div className='calendar-day-picker-wrapper'>
                 <DayPicker
                     selectedDays={this.props.dateRequested}
                     onDayClick={this.onDayClick}
                     disabled={[{ before: firstDate }, { dayOfWeek: [0, 6] }]}
                     defaultMonth={landingMonth}
                     startMonth={firstDate}
                 />
             </div>
             {hasError ?
                 <ValidationAlert validationErrors={validationErrors} validationMessages={validationMessages} />
             : null}
         </div>
        )
    }
}

export default Calendar
