import React, {PureComponent} from 'react'
import Tick from "../../../assets/svgs/checkbox/tick.svg"
import ValidationAlert from "../validation-alert"

class Checkbox extends PureComponent {
    onClick = () => {
        this.props.updateStoreValue(this.props.keyString, !this.props.isChecked)

        if (typeof this.props.parentErrorKey !== 'undefined')
        {
            this.props.setParentState({[this.props.parentErrorKey]: []})
        }

        if (typeof this.props.hasOneRequired !== 'undefined')
        {
            this.props.setParentState({oneRequiredError: false})
        }
    }

    render(){
        const validationErrors = typeof this.props.validationErrorsFromParent !== 'undefined' ? this.props.validationErrorsFromParent : []
        const hasError = validationErrors.length > 0

        let className = 'clearfix checkbox-wrapper'
        if (hasError)
        {
            className += ' has-error'
        }

        return (
            <div className={className}>
                <div className="form-check" onClick={this.onClick}>
                    <div className="check">
                        {this.props.isChecked ?
                            <Tick />
                        : null}
                    </div>
                    <input type="checkbox" readOnly={true} checked={this.props.isChecked} />
                    <span className="title">{this.props.label}</span>
                </div>

                {hasError ?
                    <ValidationAlert validationRules={this.props.validationRules} validationErrors={validationErrors} validationMessages={this.props.validationMessages} />
                : null}
            </div>
        )
    }
}

export default Checkbox