import doesHaveCopyFromContactId from "./does-have-copy-from-contact-id"

export default (currentContactId, contacts) => {
    let result = false

    Object.keys(contacts).forEach((contactId) => {
        const contact = contacts[contactId]
        if (doesHaveCopyFromContactId(contact) && contact.copyFromContactId === currentContactId)
        {
            result = true
        }
    })

    return result
}