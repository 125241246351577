import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import React from 'react'

let bugsnagClientObject = null

function prepareStoreState(store) {
    const storeState = store.getState()

    if (!(storeState.otherInformation && storeState.otherInformation.uploads)) {
        return Object.assign({}, storeState, {
            ...storeState,
            content: null
        })
    }

    const uploadFileRemoved = storeState.otherInformation.uploads.reduce((uploads, upload) => {
        uploads.push({
            name: upload.name,
            size: upload.size / 1024 / 1024 + 'MB'
        })

        return uploads
    }, [])

    return Object.assign({}, storeState, {
        ...storeState,
        content: null,
        otherInformation: {
            ...storeState.otherInformation,
            uploads: uploadFileRemoved
        },
    })
}

export function notify(error, state = null, reason) {
    if (!bugsnagClientObject) {
        console.error('Bugsnag client is not initialized');
        return;
    }

    if (state !== null) {
        bugsnagClientObject.metaData = {
            ...bugsnagClientObject.metaData,
            state: state,
            reason: reason
        }
    }

    bugsnagClientObject.notify(new Error(error))
}

export function bugsnagClient(store) {
    if (!bugsnagClientObject)
    {
        // this gets used globally by the notify function so assigned it to a variable
        bugsnagClientObject = bugsnag({
            apiKey: process.env.BUGSNAG_API_KEY,
            appVersion: process.appVersion,
            overwrite: true,
            beforeSend: (report) => {
                report.updateMetaData('redux', prepareStoreState(store))
                if (process.env.APP_ENV === 'dev') {
                    console.error(report.originalError)
                    return false
                }
            },
            releaseStage: process.env.APP_ENV
        }).use(bugsnagReact, React)
    }

    return bugsnagClientObject;
}