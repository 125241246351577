export default (dispatch) => ({
    updateStoreValue: (string, value) => {
        const keys = string.split('.')
        let type = ''
        let payload = {'value': value}

        switch(keys.length)
        {
            case 1:
                type = 'UPDATE_LEVEL_ONE'
                payload.levelOneKey = keys[0]
                break
            case 2:
                type = 'UPDATE_LEVEL_TWO'
                payload.levelOneKey = keys[0]
                payload.levelTwoKey = keys[1]
                break
            case 3:
                type = 'UPDATE_LEVEL_THREE'
                payload.levelOneKey = keys[0]
                payload.levelTwoKey = keys[1]
                payload.levelThreeKey = keys[2]
                break
            case 4:
                type = 'UPDATE_LEVEL_FOUR'
                payload.levelOneKey = keys[0]
                payload.levelTwoKey = keys[1]
                payload.levelThreeKey = keys[2]
                payload.levelFourKey = keys[3]
                break
            case 5:
                type = 'UPDATE_LEVEL_FIVE'
                payload.levelOneKey = keys[0]
                payload.levelTwoKey = keys[1]
                payload.levelThreeKey = keys[2]
                payload.levelFourKey = keys[3]
                payload.levelFiveKey = keys[4]
                break
        }

        dispatch({type, payload})
    },
    updateStore: (payload) => {
        dispatch({type: 'UPDATE_STORE', payload})
    },
    replaceStore: (payload) => {
        dispatch({type: 'REPLACE_STORE', payload})
    },
    removeContact: (payload) => {
        dispatch({type: 'REMOVE_CONTACT_ID', payload})
    },
    removeNonConnectionServices: () => {
        dispatch({type: 'REMOVE_NON_CONNECTION_SERVICES'})
    },
    removeProgress: (payload) => {
        dispatch({type: 'REMOVE_PROGRESS', payload})
    },
    addProgress: (payload) => {
        dispatch({type: 'ADD_PROGRESS', payload})
    },
    updateLocation: (location, state = null, action = null) => {
        dispatch({type: 'UPDATE_HISTORY_LOCATION', payload: {location, state, action}})
    }
})