import React, {Component} from "react"
import Header from "../../common/header"
import {connect} from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import Button from "../../common/button"
import Spinner from "../../../assets/svgs/indicators/spinner-large.svg"
import PayFailedImg from "../../../assets/svgs/pay/pay-failed.svg"
import ForwardArrow from "../../../assets/svgs/navigation/forward-arrow.svg"
import ThumbsUp from "../../../assets/svgs/complete/thumbs-up.svg"
import Link from "../../common/link/link"
import StandardTable from "../../common/standard-table"
import Navigation from "../../common/navigation"
import existsGet from "../../../helpers/exists-get"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Alert from "../../common/alert"
import Modal from "../../common/modal"
import createNewOrderStore from "../../../store/create-new-order-store"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {

    return {
        historyData: state.historyData,
        connections: existsGet(state, 'connections', null),
        services: existsGet(state, 'services', null),
        previousPaymentLinkUuid: existsGet(state, 'previousPaymentLinkUuid', null)
    }
}

class Pay extends Component {

    constructor(props) {
        super(props)

        this.reduxData = null

        this.state = {
            backendErrorMessage: null,
            paymentFailedErrorMessage: null,
            show: false,
            reference: null,
            contact: null,
            paymentRates: null,
            paymentTotal: null,
            newOrderData: null,
            isLoading: false,
            type: null,
            windcaveUrl: null,
            expired: false,
            shouldBeSuccessPage: false,
            showConfirmNavigateHasNonConnectionServices: false,
            backendValidationError: false
        }
    }

    componentDidMount() {
        this.props.updateStoreValue('previousPaymentLinkUuid', this.getPaymentLinkUuid())

        this.setupDataViaFetch()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shouldBeSuccessPage && typeof prevProps.historyData.location !== 'undefined' && !prevProps.historyData.location.includes('/success') && typeof this.props.historyData !== 'undefined' && this.props.historyData.location.includes('/success'))
        {
            this.setState({isLoading: false, show: true})
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    getPaymentLinkUuid = () =>
    {
        return this.getUriSegmentValue('uuid')
    }

    getPaymentReference = () =>
    {
        return this.state.reference !== null && this.state.type === 'job' ? "CTP-"+this.state.reference : this.state.reference
    }

    getBackendErrorMessagePayloadFromResponsePayload = (res) =>
    {
        const hasErrorMessage = typeof res.errorMessage !== 'undefined' && res.errorMessage.length > 0

        if (!hasErrorMessage)
            return null

        if (res.type === 'job' && hasErrorMessage && this.getPayUrlType() !== 'failed') {
            return {
                backendErrorMessage: 'Unable to load payment page, please use the reload button below.',
                isLoading: false,
                show: true
            }
        }
        else if (hasErrorMessage)
        {
            return {
                paymentFailedErrorMessage: res.errorMessage
            }
        }

        return null
    }

    addContentObjectToReduxData = (res) => {
        if (typeof res.reduxData === 'undefined' || res.reduxData === null)
            return null

        if (typeof res.content === 'undefined' || res.content === null)
            return null

        return Object.assign({}, res.reduxData, {content: res.content})
    }

    setupDataViaFetch = () => {
        const payUrlType = this.getPayUrlType()

        const linkUuid = this.getPaymentLinkUuid()

        const queryParameters = new URLSearchParams({
            'version': process.appVersion,
            'fromSummary': this.fromSummary() ? 1 : 0,
            'previousPaymentLinkUuid': typeof this.props.previousPaymentLinkUuid !== 'undefined' && this.props.previousPaymentLinkUuid !== linkUuid ? this.props.previousPaymentLinkUuid : ''
        })

        this.setState({isLoading: true}, () => {
            fetch(process.env.CTP_ENDPOINT+'/payment/details/'+payUrlType+'/'+linkUuid+'?'+queryParameters, {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(
                (res) => {
                    if (typeof res.errors !== 'undefined')
                    {
                        this.setState({backendErrorMessage: 'Unable to load payment page, this is likely due to a bad payment URL being used.', isLoading: false, backendValidationError: true})
                    }
                    else if (typeof res.expired !== 'undefined' && res.expired === 1 && typeof res.approved !== 'undefined' && res.approved === 1)
                    {
                        this.setState({shouldBeSuccessPage: true}, () => {
                            this.props.updateLocation('/pay/'+linkUuid+'/success')
                        })
                    }
                    else if (typeof res.expired !== 'undefined' && res.expired === 1)
                    {
                        this.setState({expired: true, isLoading: false, show: true, backendErrorMessage: res.errorMessage})
                    }
                    else if (this.getPayUrlType() === 'cancelled')
                    {
                        let updatedState = {
                            reference: res.reference,
                            paymentRates: res.paymentRates,
                            paymentTotal: res.paymentTotal,
                            type: res.type,
                            windcaveUrl: res.windcaveUrl
                        }

                        this.reduxData = this.addContentObjectToReduxData(res)

                        const backendErrors = this.getBackendErrorMessagePayloadFromResponsePayload(res)

                        if (backendErrors === null)
                        {
                            this.setState(updatedState, () => this.setState({isLoading: false, show: true}))
                        }
                        else
                        {
                            this.setState(backendErrors, () => this.setState({isLoading: false, show: true}))
                        }
                    }
                    else if (typeof res.type !== 'undefined') {
                        let updatedState = {
                            reference: res.reference,
                            paymentRates: res.paymentRates,
                            paymentTotal: res.paymentTotal,
                            type: res.type,
                            windcaveUrl: res.windcaveUrl,
                            newOrderData: null
                        }

                        if (this.getPayUrlType() === 'index' && !this.fromSummary())
                        {
                            this.reduxData = this.addContentObjectToReduxData(res)
                        }
                        else if (this.getPayUrlType() === 'success' && res.newOrderData !== null && res.content !== null)
                        {
                            const newOrderStoreContactObject = {
                                contacts: {
                                    '0': Object.assign({'copyFromContactId': '', 'termsAccepted': ''}, res.newOrderData.yourDetails)
                                },
                                content: res.content
                            }

                            updatedState.newOrderData = createNewOrderStore(newOrderStoreContactObject)
                        }

                        const backendErrors = this.getBackendErrorMessagePayloadFromResponsePayload(res)
                        if (backendErrors === null)
                        {
                            this.setState(updatedState, () => this.setState({isLoading: false, show: true}))
                        }
                        else if (this.getPayUrlType() === 'failed')
                        {
                            const mergedData = Object.assign({}, updatedState, backendErrors)
                            this.setState(mergedData, () => this.setState({isLoading: false, show: true}))
                        }
                        else
                        {
                            this.setState(backendErrors, () => this.setState({isLoading: false, show: true}))
                        }
                    }
                    else
                    {
                        this.setState({backendErrorMessage: 'Unable to load payment page, please use the reload button below.', isLoading: false, show: true})
                    }
            })
            .catch((res) => {
                console.error(res)
                if (this.getPayUrlType() === 'success')
                {
                    this.setState({isLoading: false, show: true})
                }
                else
                {
                    this.setState({backendErrorMessage: 'Unable to load payment page, please check your internet connection and use the reload button below.', isLoading: false, show: true})
                }
            })
        })
    }

    handlePay = () => {
        // Disables prompt when payment redirect is attempted
        window.onbeforeunload = null

        // Pushes current location to browser history so when using the back button on Windcave it goes to the correct page
        history.pushState({}, "", window.location.href)

        // Redirect to Windcave
        window.location.replace(this.state.windcaveUrl)
    }

    getUriSegmentValue = (type) => {
        let index = null
        if (type === 'payment')
        {
            index = 3
        }
        else if (type === 'uuid')
        {
            index = 2
        }

        const uriSegments = this.props.historyData.location.split("/")
        return typeof uriSegments[index] !== 'undefined' ? uriSegments[index] : null
    }

    getPayUrlType = () => {
        const paymentStatusUri = this.getUriSegmentValue('payment')

        if (paymentStatusUri === null)
            return 'index'

        return paymentStatusUri
    }

    getTitle = () => {
        const payUrlType = this.getPayUrlType()

        if (this.state.backendValidationError)
        {
            return 'Bad Payment URL'
        }

        if (payUrlType === 'success')
        {
            const firstName = existsGet(this.state, 'newOrderData.contacts.0.firstName', false)
            const paymentMessage =  firstName ? 'Thanks for your payment, '+firstName+'!' : 'Thanks for your payment'
            return !this.state.show ? 'Loading...' : paymentMessage
        }

        return !this.state.show ? 'Loading...' : 'Make a payment'
    }

    handleStartNewOrder = () => {
        this.props.replaceStore(this.state.newOrderData)
    }

    handleReturnToNorthpower = () => {
        window.open(links.northpower)
    }

    generateNonConnectionServicesTable = () => {
        let formattedPaymentRates = []

        const checkedNonConnectionServices = this.props.services !== null ? getCheckedFields(this.props.services) : null
        if (checkedNonConnectionServices !== null)
        {
            const cleanNonConnectionServiceName = {
                'cableLocation': 'Cable Location Request',
                'closeApproachConsent': 'Working Near Lines Application',
                'safetyDisconnection': 'Safety (Temporary) Disconnection',
                'vegetationControl': 'Vegetation Control Application'
            }
            checkedNonConnectionServices.forEach(serviceName => {
                const cleanName = cleanNonConnectionServiceName[serviceName]
                formattedPaymentRates.push([cleanName])
            })
        }

        return <StandardTable
            uniqueTableName='non-connection-services-table'
            table={{
                classes: 'table table-bordered table-striped'
            }}
            tableHeaders={{
                classes: 'table-dark',
                headers: [
                    'Service'
                ]
            }}
            tableRows={{
                rows: formattedPaymentRates
            }}
        />
    }

    generateFeesTable = () => {
        let formattedPaymentRates = []

        this.state.paymentRates.forEach((data) => {
            const name = data[0]
            const value = data[1].toLocaleString("en-US")
            formattedPaymentRates.push([name, '$'+value])
        })

        return <StandardTable
            uniqueTableName='pay'
            table={{
                classes: 'table table-bordered table-striped'
            }}
            tableHeaders={{
                classes: 'table-dark',
                headers: [
                    'Service',
                    'Fee'
                ]
            }}
            tableRows={{
                rows: formattedPaymentRates
            }}
            tableFooters={{
                classes: 'table-dark',
                rows: ['Total', '$' + this.state.paymentTotal.toLocaleString("en-US")]
            }}
        />
    }

    handleNavigation = (direction) => {
        if (direction === 'back' && this.reduxData !== null)
        {
            // this will trigger a navigation event due to the historyData it has set in it from the backend
            // progress will be missing /pay so that doesn't need to be updated
            this.props.replaceStore(this.reduxData)

            return true
        }

        if (direction === 'back')
        {
            const hasNonConnectionServices = this.props.services !== null && getCheckedFields(this.props.services).length > 0

            if (this.fromSummary() && hasNonConnectionServices)
            {
                this.setState({showConfirmNavigateHasNonConnectionServices: true})

                return false
            }

            this.props.removeProgress({'path': '/pay'})
            this.props.updateLocation('/summary', null, 'back')

            return true
        }

        return false
    }

    handleReload = () => {
        this.setState({show: false, backendErrorMessage: null}, () => {
            this.setupDataViaFetch()
        })
    }

    getOtherServicesTableMessage() {
        const nonConnectionServicesCount = this.props.services === null ? 0 : getCheckedFields(this.props.services).length
        const connectionServicesCount = this.props.connections === null ? 0 : getCheckedFields(this.props.connections).length

        const nonConnectionServiceMessage = nonConnectionServicesCount > 1 ? "The above services have successfully been submitted." : "The above service has successfully been submitted."
        const connectionServicesMessage = connectionServicesCount > 1 ? "The below services require payment." : "The below service requires payment."

        return <>{nonConnectionServiceMessage}<br />{connectionServicesMessage}</>
    }

    fromSummary = () => {
        // it's from summary, if there's connections defined in props
        return Object.keys(this.props.connections).length > 0
    }

    showBackButton = () => {
        return this.fromSummary() || this.reduxData !== null
    }

    render() {
        const payUrlType = this.getPayUrlType()

        let containerClassName = 'container single-col'
        if (payUrlType === 'success')
            containerClassName += ' pay__success'

        return (
            <div id="pay" className="section-margin-bottom">
                <Header
                    title={this.getTitle()}
                />

                {this.showBackButton() ?
                    <Navigation handleNavigation={this.handleNavigation} hideNext={true} hideSummary={true} />
                : null}

                <Modal
                    closeAction={() => this.setState({showConfirmNavigateHasNonConnectionServices: false})}
                    component={<div>
                        <p>The Other Services you selected have already been submitted, so these will not be shown when navigating back.</p>
                        <Button text="Continue" action={this.props.removeNonConnectionServices} />
                    </div>}
                    className='confirm'
                    show={this.state.showConfirmNavigateHasNonConnectionServices}
                    setParentState={this.setParentState}
                />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className={containerClassName}>
                        {this.state.isLoading ?
                            <div className="submit-icon-container submit-icon-container--is-spinning">
                                <Spinner />
                            </div>
                        : null}
                        {this.state.backendValidationError ?
                            <div className="pay__transaction-status-wrapper">
                                <div className="pay__transaction-status">
                                    <PayFailedImg />
                                    <span>{this.state.backendErrorMessage}</span>
                                </div>
                            </div>
                        : null}
                        {this.state.show ?
                            <>
                            {!this.state.expired ?
                                    <>
                                        {['failed', 'cancelled'].includes(payUrlType) ?
                                            <div className="pay__transaction-status-wrapper">
                                                <div className='pay__transaction-status'>
                                                    <PayFailedImg />
                                                    <div className='h2-wrapper'>
                                                        {payUrlType === 'failed' ?
                                                            <h2>Your payment failed</h2>
                                                        : null}
                                                        {payUrlType === 'cancelled' ?
                                                            <h2>Your payment was cancelled</h2>
                                                        : null}
                                                    </div>
                                                    {this.state.backendErrorMessage ?
                                                        <>
                                                            <span>{this.state.backendErrorMessage}</span>
                                                            <button className="btn" onClick={this.handleReload}>
                                                                <div>
                                                                    <span>Reload</span>
                                                                </div>
                                                            </button>
                                                        </>
                                                    :
                                                        <>
                                                            {payUrlType === 'failed' ?
                                                                <span>{this.state.paymentFailedErrorMessage}</span>
                                                            : null}
                                                            <button className="btn" onClick={this.handlePay}>
                                                                <div>
                                                                    <span>Try again</span>
                                                                    <ForwardArrow />
                                                                </div>
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        : null}
                                        {payUrlType === 'success' ?
                                            <>
                                                <div className="submit-icon-container">
                                                    <ThumbsUp />
                                                </div>
                                                <div className="wrapper">
                                                    <p>We’ve received all of the information you have given us and your request has been generated in our system.</p>
                                                    <p>If you haven’t received your email within 10 minutes, please check your junk or spam email folder. If the email is still lost please contact us on <b>0800 667 847</b> or via the <Link text='Northpower website' link={links.contact} />.</p>
                                                    <div className="button-wrapper">
                                                        <div>
                                                            {this.state.newOrderData !== null ?
                                                                <Button text="Start New Order" btnType="btn-primary" action={this.handleStartNewOrder} />
                                                            : null}
                                                            <Button text="Return to Northpower" btnType="btn-primary" action={this.handleReturnToNorthpower} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        : null}
                                        {payUrlType === 'index' ?
                                            <>
                                                {!this.state.backendErrorMessage ?
                                                    <div className="wrapper">

                                                        <p>Payment Reference: <b>{this.getPaymentReference()}</b></p>

                                                        <p>You will be redirected to our payment provider Windcave to enter your payment information.</p>
                                                        <p>Feel free to bookmark this page in case there's any issues with the payment.</p>

                                                        {this.props.services !== null && getCheckedFields(this.props.services).length > 0 ?
                                                            <div className="mb-3">
                                                                <h3>Other Services</h3>
                                                                {this.generateNonConnectionServicesTable()}
                                                                <Alert text={this.getOtherServicesTableMessage()} type="info" />
                                                            </div>
                                                        : null}

                                                        <h3>Service Costs</h3>
                                                        {this.generateFeesTable()}

                                                        <div className="bottom-button-wrapper mt-default">
                                                            <Button text="Pay" btnType="btn-primary" action={this.handlePay} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="pay__transaction-status-wrapper">
                                                        <div className='pay__transaction-status'>
                                                            <PayFailedImg />
                                                            <div className='h2-wrapper'>
                                                                <h2>Something went wrong</h2>
                                                            </div>
                                                            <span>{this.state.backendErrorMessage}</span>
                                                            <button className="btn" onClick={this.handleReload}>
                                                                <div>
                                                                    <span>Reload</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        : null}
                                    </>
                                :
                                    <div className="pay__transaction-status-wrapper">
                                        <div className='pay__transaction-status'>
                                            <PayFailedImg />
                                            <div className='h2-wrapper'>
                                                <h2>{this.state.backendErrorMessage}</h2>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <Pay ref={ref} {...props} />
    })
)