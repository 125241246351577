import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

class Button extends PureComponent {
    render(){
        let className = 'btn'
        if (this.props.btnType)
        {
            className += ' '+this.props.btnType
        }

        if (this.props.className)
        {
            className += ' '+this.props.className
        }

        return (
            <button id={this.props.id} type="button" className={className} onClick={() => this.props.action()}>
                {this.props.text}
                {this.props.icon ?
                    this.props.icon
                : null}
            </button>
        )
    }
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
}

export default Button