import React, {PureComponent} from 'react'
import TooltipIcon from '../../../assets/svgs/tooltip.svg'
import ReactDOM from 'react-dom'

const domNode = document.getElementById('tooltip-content')

class Tooltip extends PureComponent {
    constructor(props)
    {
        super(props)

        this.state = {
            showTooltip: false,
            position: {
                y: 0,
                x: 0,
                height: null
            },
            arrowPosition: {
                y: 0,
                x: 0
            },
            type: 'none'
        }

        this.tooltipButton = React.createRef()
        this.tooltipArrow = React.createRef()
        this.tooltipInner = React.createRef()
    }

    componentDidMount() {
        window.addEventListener('resize', this.resetToolTip)
        document.getElementById("app").addEventListener('scroll', this.resetToolTip)
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('touchstart', this.handleClickOutside)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resetToolTip)
        document.getElementById("app").removeEventListener('scroll', this.resetToolTip)
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('touchstart', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (this.state.showTooltip && this.tooltipInner.current && !this.tooltipInner.current.contains(e.target) && this.tooltipButton.current && !this.tooltipButton.current.contains(e.target)) {
            this.toggleTooltip()
        }
    }

    toggleTooltip = () => {
        if (this.state.showTooltip)
        {
            return this.setState({showTooltip: false, position: {y: 0, x: 0, height: null}, arrowPosition: {y: 0, x: 0}})
        }

        this.setState({showTooltip: true}, () => {
            const rect = this.tooltipButton.current.getBoundingClientRect()

            const positionAndType = this.getTooltipPosition(rect)
            this.setState({type: positionAndType.type, position: {y: positionAndType.y, x: positionAndType.x, height: positionAndType.height}, arrowPosition: {y: positionAndType.arrowY, x: positionAndType.arrowX}})
        })
    }

    resetToolTip = () => {
        if (this.state.showTooltip)
        {
            this.setState({showTooltip: false, position: {y: 0, x: 0, height: null}, arrowPosition: {y: 0, x: 0}})
        }
    }

    getTooltipPosition(buttonRect) {

        // position the tooltip after showing it
        if (this.tooltipInner != null) {
            let x = 0, arrowX = 0, y = 0, height = null, arrowY = 0

            const docWidth = document.documentElement.clientWidth
            const docHeight = document.documentElement.clientHeight

            const buttonRectWidth = (buttonRect.width+5)

            let buttonLeftX = buttonRect.x || buttonRect.left, // most left x
                buttonTopY = buttonRect.y || buttonRect.top, // most top y
                buttonBottomRight = buttonTopY + buttonRect.height; // most bottom y

            let innerRect = this.tooltipInner.current.getBoundingClientRect()

            const innerRectHeight = innerRect.height
            const above = buttonTopY > (docHeight - buttonTopY)
            const innerRectWidth = (innerRect.width)

            let newState = {}

            x = buttonLeftX + (buttonRectWidth/2) - (innerRectWidth/2)

            const totalRightX = x + innerRectWidth

            if (totalRightX >= docWidth || x < 0)
            {
                x = docWidth - innerRectWidth
                const possibleBestPos = buttonLeftX-(buttonRectWidth+30)
                if (x > possibleBestPos)
                {
                    x = possibleBestPos
                }
            }

            if (above) {
                if (innerRectHeight > buttonTopY)
                {
                    height = buttonTopY - 30
                }

                y = buttonTopY - innerRectHeight
                if (height !== null)
                {
                    y = buttonTopY - height
                }

                arrowX = buttonLeftX + buttonRectWidth/2 - 2
                arrowY = buttonTopY - 2

                newState.type = "top"
            }
            else {
                y = buttonBottomRight

                arrowX = buttonLeftX + buttonRectWidth/2 - 2
                arrowY = buttonTopY + buttonRect.height*2 + 2

                if (y+innerRectHeight > docHeight)
                {
                    height = docHeight - y - 30
                }

                newState.type = "bottom"
            }

            newState = {...newState, x: x, y: y, height: height, arrowX: arrowX, arrowY: arrowY}

            return newState
        }
    }

    render()
    {
        let {state} = this

        let style = {
            left: state.position.x +'px',
            top: state.position.y+'px'
        }

        if (state.position.height !== 'undefined' && state.position.height !== null)
        {
            style.height = state.position.height+'px'
        }

        let arrowStyle = {
            left: ((state.arrowPosition.x) + 'px'),
            top: ((state.arrowPosition.y) + 'px')
        }

        let classNames = "tooltip "+this.state.type
        let innerWrapperClassNames = 'tooltip-inner-wrapper'
        innerWrapperClassNames += this.state.showTooltip ? " on" : " off"

        let arrowClassNames = "tooltip-arrow "+this.state.type

        return <>
            <span className='tooltip-wrapper'>
                <span className='tooltip-button' ref={this.tooltipButton} onClick={this.toggleTooltip}>
                    <TooltipIcon />
                </span>
                {
                    ReactDOM.createPortal(<div className={innerWrapperClassNames}>
                        <div ref={this.tooltipInner} style={style} className={classNames}>
                            <div className="tooltip-inner">
                                <div>
                                    {this.props.content}
                                </div>
                            </div>
                        </div>

                        <div style={arrowStyle} ref={this.tooltipArrow} className={arrowClassNames} />
                    </div>, domNode)
                }
            </span>
        </>
    }
}


export default Tooltip