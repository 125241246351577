import getRoleSettings from "./get-role-settings"
const rolesSettings = getRoleSettings()

export default (connectionTypes) => {
    let result = []
    connectionTypes.map((connectionType) => {
        const mandatoryRoles = [...rolesSettings[connectionType].mandatory]
        mandatoryRoles.map(mandatoryRole => result.push(mandatoryRole))
    })

    return [...new Set([...result])]
}