import React, {Component} from 'react'
import Tick from "../../../assets/svgs/checkbox/tick.svg"
import checkIsIrrelevantService from "../../../helpers/check-is-irrelevant-service"
import Irrelevant from "../../../assets/svgs/checkbox/irrelevant.svg"
import keyStringToId from "../../../helpers/key-string-to-id"

class WelcomeCheckbox extends Component {
    onClick = () => {
        if (this.props.nameAndAddressHeaderTitleOverrideText !== null)
            this.props.updateStoreValue('nameAndAddressHeaderTitleOverrideText', null)

        this.props.updateStoreValue(this.props.keyString+'IsChecked', !this.props.isChecked)

        this.props.setParentState({oneRequiredError: false})
    }

    render(){
        const isIrrelevantService = checkIsIrrelevantService(keyStringToId(this.props.keyString).replace('services_', ''))
        const irrelevantIsChecked = this.props.isChecked && isIrrelevantService
        const isChecked = this.props.isChecked && !isIrrelevantService

        let className = "orange checkbox-big checkbox-wrapper clearfix form-check checkbox-wrapper-standalone"
        if (isChecked)
        {
            className += " is-checked"
        }

        if (irrelevantIsChecked)
        {
            className += " irrelevant"
        }

        return (
            <div className={className} onClick={this.onClick}>
                <div className="wrapper">
                    <div className="check">
                        {isChecked ?
                            <Tick />
                        : null}
                        {irrelevantIsChecked ?
                            <Irrelevant />
                        : null}
                    </div>
                    <input type="checkbox" checked={this.props.isChecked} readOnly={true} />
                    <span className="title">{this.props.label}</span>
                </div>
                {this.props.helperContent !== null ?
                    <div className="helper-text-wrapper">
                        {this.props.helperContent}
                    </div>
                : null}
            </div>
        )
    }
}

export default WelcomeCheckbox