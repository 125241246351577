import React, {Component} from 'react'
import SummaryItem from "./summary-item"

class SummaryItems extends Component {
    render() {
        return (
            <>
                {this.props.items.map((item, index) => {
                    return <SummaryItem className={this.props.className} key={"summaryItem_"+this.props.keyName+'_'+index} title={item.title} changePath={item.changePath} content={item.content} updateLocation={this.props.updateLocation} contactId={item.contactId} setScrollTop={this.props.setScrollTop} />
                })}
            </>
        )
    }
}

export default SummaryItems