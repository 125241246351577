export default (role, roles) => {
    const roleKeys = Object.keys(roles)

    for (const i in roleKeys)
    {
        const contactId = roleKeys[i]
        const contactRoles = roles[contactId]

        if (contactRoles.includes(role))
        {
            return contactId
        }
    }

    return null
}