import React, {Component} from 'react'
import Dropzone from 'react-dropzone'
import TextButton from "../../common/text-button"
import Alert from "../../common/alert"

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

class Upload extends Component {
    constructor(props) {
        super(props)

        this.acceptedExtensions = [
            'doc',
            'docx',
            'pdf',
            'xls',
            'xlsx',
            'jpg',
            'png'
        ]

        this.state = {
            failedExtensions: [],
            failedSizesTooBig: [],
            failedSizesTooSmall: [],
            failedUploads: []
        }
    }

    onDrop = (files) => {
        let failedExtensions = []
        let failedSizesTooBig = []
        let failedSizesTooSmall = []
        let failedUploads = []

        files.forEach((file) => {
            const fileExtension = file.name.split('.').pop()

            if (!this.acceptedExtensions.includes(fileExtension))
            {
                failedExtensions.push(file.name)
                return
            }
            else if ((file.size/1024/1024) > 35)
            {
                failedSizesTooBig.push(file.name)
                return
            }
            else if (file.size === 0)
            {
                failedSizesTooSmall.push(file.name)
                return
            }

            let hasError = false
            const reader = new FileReader()
            reader.onabort = () => {
                hasError = true

                if (typeof file.name !== 'undefined' && file.name.length > 0)
                    failedUploads.push(file.name)

                console.info('file reading was aborted')
            }

            reader.onerror = () => {
                hasError = true

                if (typeof file.name !== 'undefined' && file.name.length > 0)
                    failedUploads.push(file.name)

                console.info('file reading has failed')
            }

            if (!hasError)
            {
                reader.onload = () => {
                    const binaryStr = reader.result.split(',')[1]
                    this.props.newUpload(file.name, file.size, binaryStr)
                }
                reader.readAsDataURL(file)
            }
        })

        this.setState({failedSizesTooBig, failedSizesTooSmall, failedExtensions, failedUploads})
    }

    render() {

        const files = this.props.currentFiles.map((file, id) => (
            <li key={'file_'+id}>
                <div>
                    <span>{file.name} - {formatBytes(file.size)} </span><TextButton className='orange bold' text='Delete' onClick={() => this.props.removeUploadedFile(id)} />
                </div>
            </li>
        ))

        const hasFailedUploads = this.state.failedExtensions.length > 0 || this.state.failedSizesTooBig.length > 0 || this.state.failedSizesTooSmall.length > 0 || this.state.failedUploads.length > 0

        let uploadRequiredErrorClassName = 'upload-error'
        if (hasFailedUploads)
        {
            uploadRequiredErrorClassName += ' has-failed-upload'
        }

        return (
            <div className='upload-wrapper'>
                <Dropzone onDrop={this.onDrop} >
                    {({getRootProps, getInputProps}) => (
                        <section className="dropzone-wrapper">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <span>{this.props.isProject ? 'Upload project files here' : 'Drag files here to upload or click to select files.' }</span>
                            </div>
                            <span className='file-formats'>We accept the following file formats: doc, docx, pdf, xls, xlsx, jpg, png</span>
                            <div className='upload-list'>
                                {files.length > 0 ?
                                    <>
                                        <ul>{files}</ul>
                                    </>
                                : null}
                            </div>

                            {this.state.failedExtensions.length > 0 ?
                                <div className='failed-upload-list'>
                                    <Alert text={<>
                                        <span><b>The following uploads failed due to invalid file extensions:</b></span>
                                        <ul>
                                            {
                                                this.state.failedExtensions.map((name) => {
                                                    return <li key={'failedExtensions_'+name}>{name}</li>
                                                })
                                            }
                                        </ul>
                                    </>} type="danger" />
                                </div>
                            : null}

                            {this.state.failedSizesTooBig.length > 0 ?
                                <div className='failed-upload-list'>
                                    <Alert text={<>
                                        <span><b>The following uploads failed due to being larger than 35MB:</b></span>
                                        <ul>
                                            {
                                                this.state.failedSizesTooBig.map((name) => {
                                                    return <li key={'failedSizesTooBig_'+name}>{name}</li>
                                                })
                                            }
                                        </ul>
                                    </>} type="danger" />
                                </div>
                            : null}

                            {this.state.failedSizesTooSmall.length > 0 ?
                                <div className='failed-upload-list'>
                                    <Alert text={<>
                                        <span><b>The following uploads failed due to being empty:</b></span>
                                        <ul>
                                            {
                                                this.state.failedSizesTooSmall.map((name) => {
                                                    return <li key={'failedSizesTooSmall_'+name}>{name}</li>
                                                })
                                            }
                                        </ul>
                                        <span style={{marginTop: 20, display: "block"}}>This could be caused if there was an issue when the file was originally downloaded or saved on external sites or software.</span>
                                    </>} type="danger" />
                                </div>
                            : null}

                            {this.state.failedUploads.length > 0 ?
                                <div className='failed-upload-list'>
                                    <Alert text={<>
                                        <span><b>The following uploads failed due to an unknown error:</b></span>
                                        <ul>
                                            {
                                                this.state.failedUploads.map((name) => {
                                                    return <li key={'failedUploads_'+name}>{name}</li>
                                                })
                                            }
                                        </ul>
                                    </>} type="danger" />
                                </div>
                            : null}
                        </section>
                    )}
                </Dropzone>

                {this.props.uploadRequiredError ?
                    <Alert className={uploadRequiredErrorClassName} text='Please upload a file.' type="danger" />
                : null}
            </div>
        )
    }
}

export default Upload