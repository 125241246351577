import PropTypes from "prop-types"
import getCheckedFields from "./get-checked-fields"

function onlyHasSingleOfferingChecked({offering, connections, services}) {
    const checkedConnections = getCheckedFields(connections)
    const checkedServices = getCheckedFields(services)

    const singleOnlyAndInCheckedConnections = checkedConnections.includes(offering) && checkedConnections.length === 1 && checkedServices.length === 0
    const singleOnlyAndInCheckedServices = checkedServices.includes(offering) && checkedServices.length === 1 && checkedConnections.length === 0

    return singleOnlyAndInCheckedConnections || singleOnlyAndInCheckedServices
}

onlyHasSingleOfferingChecked.propTypes = {
    offering: PropTypes.string.isRequired,
    connections: PropTypes.object.isRequired,
    services: PropTypes.object.isRequired
}

export default onlyHasSingleOfferingChecked