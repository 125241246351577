import React from 'react'

import Link from "../../components/common/link"
const links = require('../../json/links/links.json')

export default <div>
    <ul>
        <li>
            We&#39;ll give you permission to connect to our network once we receive a copy of the inspection report; notification from your energy retailer; and confirmation of compliance with <Link link={links.onsiteGenerationEaPart6} text="Part 6 of the Electricity Industry Participation Code" />.
            Unless a connection contract has been entered into, small scale distributed generation is generally on regulated terms in accordance with <Link link={links.onsiteGenerationEaPart62} text="Schedule 6.2 of Part 6 of the Code" />.
        </li>
        <li>
            I understand that this information is collected so that Northpower can design and/or connect my new installation to the network. As part of the connection requirements, the information contained on this form may be passed to the energy retailer that I have nominated on this form.
        </li>
        <li>
            I understand that this installation must comply with: Electricity (Safety) Regulations 2010 and AS / NZS 3000:2007, including any subsequent amendments to these documents. I understand that inverters must comply with AS/NZS 4777 Grid Connection of Energy Systems via Inverters, and photovoltaic arrays must comply with AS/NZS 5033 Installation and Safety Requirements for Photovoltaic Arrays.
        </li>
        <li>
            I acknowledge that I am also responsible for the maintenance and operation of the facility and recognise the responsibilities and obligations associated with connecting distributed generation to the Northpower distribution network.
        </li>
    </ul>
</div>