export default (obj, elementString, fallback) => {
    const elements = elementString.split('.')

    if (typeof fallback === 'undefined')
        fallback = false

    for (let i = 0; i < elements.length; i++) {
        if (!obj || !obj.hasOwnProperty(elements[i])) {
            return fallback
        }
        obj = obj[elements[i]]
    }

    return obj
}