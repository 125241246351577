function getCheckedFields(fields) {
    let result = []
    Object.keys(fields).forEach((key) => {
        if (key.indexOf('IsChecked') !== -1 && fields[key])
        {
            result.push(key.replace('IsChecked', ''))
        }
    })

    return result
}

export default getCheckedFields