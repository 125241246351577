import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Dropdown from "../../common/dropdown"
import Header from "../../common/header"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import Modal from "../../common/modal"
import Link from "../../../components/common/link"
import TextButton from "../../common/text-button"
import Tick from "../../../assets/svgs/approved-contractor/tick.svg"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: existsGet(state, 'services', null),
        propertyType: existsGet(state, 'propertyType', null),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
        },
        electricity: {
            networkApprovedContractor: existsGet(state, 'electricity.networkApprovedContractor', '')
        }
    }
}

const validationMessages = {
    'electricity_networkApprovedContractor': {
        'required': 'Please select your network approved contractor.'
    }
}

const content = require('../../../json/content/content.json')
const links = require('../../../json/links/links.json')

class ApprovedContractor extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            electricity: {
                networkApprovedContractor: ['required']
            }
        }

        this.validationErrorFields = {
            electricity_networkApprovedContractorErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            oneRequiredError: false,
            hasError: false,
            showModal: false
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/approved-contractor'})
            this.props.updateLocation('/retailer', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () =>
    {
        const passedParentValidation = this.validate()
        if (!passedParentValidation)
        {
            this.props.removeProgress({'path': '/approved-contractor'})
            return false
        }

        this.props.addProgress({'path': '/approved-contractor'})

        const checkedConnections = getCheckedFields(this.props.connections)

        const caravanOrOnlyBTS = this.props.propertyType === 'Caravan' || (checkedConnections.length === 1 && checkedConnections[0] === 'bts')

        if ((checkedConnections.length === 1 || caravanOrOnlyBTS) && checkedConnections.includes('onsiteGeneration'))
        {
            return '/distributed-generation-capacity'
        }

        if (caravanOrOnlyBTS)
        {
            const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
            return nextNonConnectionService !== null ? nextNonConnectionService : '/other-information'
        }

        return '/manual-capacity'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    render() {
        return (
            <div id="approved-contractor" className="section-margin-bottom">
                <Header
                    title="Your chosen contractor"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {this.state.oneRequiredError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text="Only specifically approved contractors are able to connect new meters, and lines to poles or pillars. If you’re unsure we can recommend an approved contractor in your area." />

                        <div className="wrapper">
                            <MandatoryText />

                            <Dropdown
                                label="Network approved contractor"
                                mandatoryIndicator={true}
                                keyString={"electricity.networkApprovedContractor"}
                                parentErrorKey="electricity_networkApprovedContractorErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                selectedOption={this.props.electricity.networkApprovedContractor}
                                options={
                                    [
                                        {
                                            label: 'EASL',
                                            value: 'EASL'
                                        },
                                        {
                                            label: 'Lal Electrix',
                                            value: 'Lal Electrix'
                                        },
                                        {
                                            label: 'Mr Electri City Ltd',
                                            value: 'Mr Electri City Ltd'
                                        },
                                        {
                                            label: 'Wells Instrument and Electrical Services Limited (Electrasafe)',
                                            value: 'Wells Instrument and Electrical Services Limited (Electrasafe)'
                                        },
                                        {
                                            label: 'Unknown/Unsure',
                                            value: 'Unknown/Unsure'
                                        }
                                    ]
                                }
                                isSearchable={true}
                                validationMessages={validationMessages.electricity_networkApprovedContractor}
                                validationErrorsFromParent={this.state.electricity_networkApprovedContractorErrors}
                                validationRules={this.validationRules.electricity.networkApprovedContractor}
                                setParentState={this.setParentState}
                            />

                             <Modal
                                title="Network Approved Contractors"
                                className="network-approved-contractor-modal"
                                component={<div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Inspections</th>
                                                    <th>Liven to Network</th>
                                                    <th>Meter Hanging</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Link text='Electrical & Alarm Services' link={links.easl} />
                                                        <span>David Walters</span>
                                                        <span>021 735 210</span>
                                                        <a href="mailto:dw@easl.co.nz">dw@easl.co.nz</a>
                                                    </td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>Lal Electrix</span>
                                                        <span>Nilesh Lal</span>
                                                        <span>021 325 258</span>
                                                        <a href="mailto:nileshaswin@gmail.com">nileshaswin@gmail.com</a>
                                                    </td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Link text='Mr Electri City Ltd' link={links.mrelectricity} />
                                                        <span>Jerrie Coetzee</span>
                                                        <span>09 435 3183</span>
                                                        <a href="mailto:office@mrelectricity.co.nz">office@mrelectricity.co.nz</a>
                                                    </td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Link text='Wells Instrument and Electrical Services Limited (Electrasafe)' link={links.wells} />
                                                        <span>0800 934 677</span>
                                                        <a href="mailto:service.requests@wells.co.nz">service.requests@wells.co.nz</a>
                                                    </td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                    <td className="tick"><Tick /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                show={this.state.showModal}
                                setParentState={this.setParentState}
                                closeAction={() => this.setState({showModal: false})}
                            />

                            <TextButton color="orange" text="Help me choose an approved contractor" onClick={() => this.setState({showModal: !this.state.showModal})} />
                        </div>

                        {this.props.showBottomNavigation && this.state.oneRequiredError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <ApprovedContractor ref={ref} {...props} />
    })
)