import doesHaveCopyFromContactId from "./does-have-copy-from-contact-id"
import React from "react"

export default (contactId, contacts, roles) => {
    const contact = contacts[contactId]
    if (typeof contact === 'undefined')
    {
        return null
    }

    const hasCopyFromContactId = doesHaveCopyFromContactId(contact)
    const contactIdForName = hasCopyFromContactId ? contact.copyFromContactId : contactId

    let name = contact.firstName+' '+contact.lastName
    if (hasCopyFromContactId)
    {
        const copyFromContactContact = contacts[contactIdForName]
        const roleString = typeof roles[contactIdForName] !== 'undefined' && roles[contactIdForName][0] ? ' - '+roles[contactIdForName][0] : ' - Applicant'
        name = <div><h4>Details copied from:</h4>{copyFromContactContact.firstName+' '+copyFromContactContact.lastName + roleString}</div>
    }

    return name
}