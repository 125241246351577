import serviceAddressFields from "../helpers/get-service-address-field-names"

export default (serviceAddress) => {
    let result = ''

    const serviceAddressFieldsLength = serviceAddressFields.length
    let unit = ''
    for (let i = 0; i < serviceAddressFieldsLength; i++) {
        const field = serviceAddressFields[i]
        const value = serviceAddress[field]

        if (field === 'unit')
        {
            unit = value
            continue
        }

        if (value.length === 0)
        {
            continue
        }

        if (i === 1 && unit.length > 0)
        {
            result += unit+'/'+value
        }
        else
        {
            result += value
        }

        if (i === 1)
        {
            result += ' '
        }
        else if (i+1 !== serviceAddressFieldsLength)
        {
            result += ', '
        }
    }

    return result.replace(/(, $)/g, "")
}