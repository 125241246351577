import defaultReduxState from "./default-redux-state"

export default (props) => {
    return Object.assign({}, defaultReduxState, {
        contacts: {
            0: props.contacts['0'],
        },
        content: typeof props.content !== 'undefined' ? props.content : null, // this is required for payment links
        roles: {
            0: ['Applicant']
        },
        appWrapperShouldRender: true,
        dataRequestIsLoading: true,
        googleLibsAreLoading: false, // setting to false resolves potential race conditions, but could happen before the timeout would
        isNewOrder: true,
        historyData: {
            location: '/',
            action: 'reloadDataRequest',
            state: null
        }
    })
}
