export default (destination, payload) => {
    return fetch(destination, {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: new Blob([JSON.stringify(payload)], {type : "application/json"})
    })
    .then(res => res.json())
    .then(
        (res) => {return res}
    )
}