import React, {PureComponent} from 'react'

class Header extends PureComponent {
    render(){
        return (
            <>
                <header id="main-header">
                    <h1>{this.props.title}</h1>
                    {this.props.description}
                </header>
            </>
        )
    }
}

export default Header