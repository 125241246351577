import React, {PureComponent} from 'react'
class MandatoryIndicator extends PureComponent {

    getResult = () => {
        if (typeof this.props.children === 'string')
        {
            const lastSpace = this.props.children.lastIndexOf(" ")
            if (lastSpace === -1)
            {
                return <>{this.props.children}<span className='red'>*</span></>
            }

            const lastWord = this.props.children.substring(lastSpace+1)
            const labelWithoutLastWord = this.props.children.substr(0, lastSpace)

            return <>{labelWithoutLastWord} <span className='non-breaking'>{lastWord}<span className='red'>*</span></span></>
        }

        return <span className='red'>*</span>
    }

    render() {
        return this.getResult()
    }
}

export default MandatoryIndicator