import getCheckedFields from "./get-checked-fields"

export default (prevProps, props) => {
    const prevCheckedServices = getCheckedFields(prevProps.services)
    const checkedServices = getCheckedFields(props.services)

    const prevCheckedConnections = getCheckedFields(prevProps.connections)
    const checkedConnections = getCheckedFields(props.connections)

    return checkedServices.length !== prevCheckedServices.length || checkedConnections.length !== prevCheckedConnections.length
}