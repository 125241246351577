import React, {Component} from 'react'
import { connect } from "react-redux"
import validator from "../../../services/validator/validator"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import Alert from "../../common/alert"
import Header from "../../common/header"
import Navigation from "../../common/navigation"
import getServiceCheckboxesData from "../../../services/general/get-service-checkboxes-data"
import existsGet from "../../../helpers/exists-get"
import getCheckedFields from "../../../helpers/get-checked-fields"
import checkIsIrrelevantService from "../../../helpers/check-is-irrelevant-service"
import hasConnectionAndNonConnectionServices from "../../../helpers/has-connection-and-non-connection-services"

import Calendar from "../../common/calendar"
import 'react-day-picker/dist/style.css'
import hasError from "../../../helpers/has-error"

const content = require('../../../json/content/content.json')

const validationRules = {
    'services': {
        'vegetationControlDateRequested': [{'requiredIf': 'vegetationControlIsChecked'}, 'date'],
        'safetyDisconnectionDateRequested': [{'requiredIf': 'safetyDisconnectionIsChecked'}, 'date'],
        'closeApproachConsentDateRequested': [{'requiredIf': 'closeApproachConsentIsChecked'}, 'date'],
        'cableLocationDateRequested': [{'requiredIf': 'cableLocationIsChecked'}, 'date']
    }
}

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: {
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked'),
            fibreDateRequested: existsGet(state, 'services.fibreDateRequested', null),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            safetyDisconnectionDateRequested: existsGet(state, 'services.safetyDisconnectionDateRequested', null),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            permanentDisconnectionDateRequested: existsGet(state, 'services.permanentDisconnectionDateRequested', null),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            vegetationControlDateRequested: existsGet(state, 'services.vegetationControlDateRequested', null),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            cableLocationDateRequested: existsGet(state, 'services.cableLocationDateRequested', null),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            closeApproachConsentDateRequested: existsGet(state, 'services.closeApproachConsentDateRequested', null),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            highLoadDateRequested: existsGet(state, 'services.highLoadDateRequested', null),
        },
        contacts: existsGet(state, 'contacts', {}),
        roles: existsGet(state, 'roles', null),
        fromServiceDeeplink: existsGet(state, 'fromServiceDeeplink', false),
        hasConnectionsAndServices: hasConnectionAndNonConnectionServices({services: state.services, connections: state.connections})
    }
}

class PreferredDates extends Component {
    constructor(props) {
        super(props)

        this.validationErrorFields = {
            services_vegetationControlDateRequestedErrors: [],
            services_permanentDisconnectionDateRequestedErrors: [],
            services_safetyDisconnectionDateRequestedErrors: [],
            services_closeApproachConsentDateRequestedErrors: [],
            services_cableLocationDateRequestedErrors: [],
            services_highLoadDateRequestedErrors: [],
            services_fibreDateRequestedErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            oneRequiredError: false,
            hideSummary: !this.props.fromSummary
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.state.hideSummary && this.checkShouldHideSummary(prevProps))
        {
            this.setState({hideSummary: true})
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    checkShouldHideSummary = (prevProps) => {
        const prevCheckedServices = getCheckedFields(prevProps.services)
        const prevCheckedServicesCount = prevCheckedServices.length
        const checkedServices = getCheckedFields(this.props.services)
        const checkedServicesCount = checkedServices.length
        const newServices = checkedServicesCount > prevCheckedServicesCount ? checkedServices.filter((name) => {
            if (!prevCheckedServices.includes(name) && !checkIsIrrelevantService(name))
            {
                return name
            }

            return null
        }) : []

        return newServices.length > 0
    }

    generateCalendars = (services, type) => {
        const serviceCheckboxesData = getServiceCheckboxesData(this.props)

        return services.map((key, i) => {

            let label = ''
            let checkboxContent = null

            if (type === 'services')
            {
                checkboxContent = serviceCheckboxesData.services

                label = checkboxContent[key]['label']
            }

            const dateRequested = this.props[type][key+'DateRequested']
            const errorKeyName = type+'_'+key+'DateRequestedErrors'
            const leadTime = checkboxContent[key]['leadTime']
            const leadNotice = checkboxContent[key]['leadNotice']
            const dateRequestedErrors = this.state[errorKeyName]

            return <Calendar
                key={type+'_'+i}
                keyString={type+'.'+key}
                parentErrorKey={errorKeyName}
                label={label}
                mandatoryIndicator={true}
                leadTime={leadTime}
                leadNotice={leadNotice}
                dateRequested={dateRequested}
                validationErrorsFromParent={dateRequestedErrors}
                updateStoreValue={this.props.updateStoreValue}
                setParentState={this.setParentState}
            />
        })
    }

    getCalendarGrids = () => {
        const checkedServices = getCheckedFields(this.props.services).filter((name) => {
            return !checkIsIrrelevantService(name)
        })

        const calendars = this.generateCalendars(checkedServices, 'services')
        const calendarCount = calendars.length

        if (calendarCount === 1)
        {
            return {
                calendars: <div className='row calendars-1'>
                    <div className='col-12 offset-lg-3 col-lg-6'>{calendars}</div>
                </div>,
                calendarCount: calendarCount
            }
        }

        if (calendarCount === 2)
        {
            return {
                calendars: <div className='row calendars-2'>
                    {calendars.map((calendar, i) => {
                        let className = 'col-12 col-lg-5'

                        if (i === 0){
                            className += ' offset-lg-1'
                        }

                        return <div key={'calendar_'+i} className={className}>{calendar}</div>
                    })}
                </div>,
                calendarCount: calendarCount
            }
        }

        return {
            calendars: <div className='row calendars-max'>
                {calendars.map((calendar, i) => {
                    let className = 'col-12 col-lg-4'

                    return <div key={'calendar_'+i} className={className}>{calendar}</div>
                })}
            </div>,
            calendarCount: calendarCount
        }
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () => {
        if (this.validate())
        {
            this.props.addProgress({'path': '/preferred-dates'})
            return '/name-and-address'
        }

        this.props.removeProgress({'path': '/preferred-dates'})
        return false
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/preferred-dates'})
            this.props.updateLocation('/', null, 'back')

            return true
        }

        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    getErrors = () => {
        if (this.state.oneRequiredError)
        {
            if (
                this.props.services.permanentDisconnectionIsChecked ||
                this.props.services.highLoadIsChecked ||
                this.props.services.fibreIsChecked
            )
            {
                return <Alert text='The service/s you have chosen need to be processed externally, please read the instructions underneath your selection.' type="danger" />
            }
            else
            {
                return <Alert text='Please select the service you’d like to get started with.' type="danger" />
            }
        }

        if (hasError(this.state, this.validationErrorFields) && !this.state.oneRequiredError)
        {
            return <Alert text={content.validation.hasError} type="danger"/>
        }

        return null
    }

    render() {
        const errors = this.getErrors()

        const calendarGrids = this.getCalendarGrids()
        const calendars = calendarGrids.calendars
        const calendarCount = calendarGrids.calendarCount

        const dateOrDates = calendarCount > 1 ? 'dates' : 'date'

        let validationTopWrapperClassName = 'validation-top-wrapper'

        if (calendarCount === 1)
        {
            validationTopWrapperClassName += ' col-12 offset-lg-3 col-lg-6'
        }

        if (calendarCount === 2)
        {
            validationTopWrapperClassName += ' col-12 offset-lg-1 col-lg-10'
        }

        if (calendarCount > 2)
        {
            validationTopWrapperClassName += ' col-12'
        }

        const renameBackNavLabelToServices = !this.props.hasConnectionsAndServices && this.props.fromServiceDeeplink

        return (
            <div id="preferred-dates" className="section-margin-bottom">
                <Header
                    title={'Please select your preferred '+dateOrDates+' for service'}
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation} renameBackNavLabelToServices={renameBackNavLabelToServices} />
                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container">

                        <div className='row'>
                            {errors !== null ?
                                <div className={validationTopWrapperClassName}>
                                    {errors}
                                </div>
                            : null}
                        </div>

                        {calendars}

                        {errors !== null && this.props.showBottomNavigation ?
                            <div className='validation-bottom-wrapper'>
                                {errors}
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation  hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation} renameBackNavLabelToServices={renameBackNavLabelToServices} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <PreferredDates ref={ref} {...props} />
    })
)
