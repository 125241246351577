import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import Navigation from "../../common/navigation"
import Button from "../../common/button"
import SummaryItems from "./summary-items"
import SummaryItem from "./summary-item"
import Header from "../../common/header"
import getServiceAddress from "../../../helpers/get-service-address"
import getContactDetails from "../../../services/summary/get-contact-details"
import getCheckedFields from "../../../helpers/get-checked-fields"
import doesHaveElectricalConnectionChecked from "../../../helpers/does-have-electrical-connection-checked"
import Modal from "../../common/modal"
import TextButton from "../../common/text-button"
import Checkbox from "../../common/checkbox"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import Alert from "../../common/alert"
import checkIsIrrelevantService from "../../../helpers/check-is-irrelevant-service"
import formatDateReadable from "../../../helpers/date/format-date-readable"
import getAllRoles from "../../../services/roles/get-all-roles"
import {getNonConnectionNamesAndSlugs} from "../../../services/router/non-connection-service"
import shouldShowPropertyType from "../../../helpers/should-show-property-type"
import formatTimepickerTime from "../../../helpers/format-timepicker-time"

import newConnectionTermsAndConditions from "../../../services/summary/new-connection-terms-and-conditions"
import closeApproachTermsAndConditions from "../../../services/summary/close-approach-consent-terms-and-conditions"
import cableLocationTermsAndConditions from "../../../services/summary/cable-location-terms-and-conditions"
import alternativeEnergyTermsAndConditions from "../../../services/summary/alternative-energy-terms-and-conditions"
import getServiceCheckboxes from "../../../services/general/get-service-checkboxes-data"
import hasError from "../../../helpers/has-error"

const mapStateToProps = (state) => {

    return {
        data: state,
        connectionsIsChecked: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        servicesIsChecked: {
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
        },
        historyLocation: existsGet(state, 'historyData.location', '/'),
        termsAndConditionsIsChecked: existsGet(state, 'termsAndConditionsIsChecked', false),
        summaryScrollPosition: existsGet(state, 'summaryScrollPosition', 0),
        project: {
            siteLocationDetails: existsGet(state, 'project.siteLocationDetails', ''),
            description: existsGet(state, 'project.description', '')
        }
    }
}

const validationMessages = {
    'termsAndConditionsIsChecked': {
        'required': 'Please accept our terms and conditions and any disclaimers.'
    }
}

class Summary extends Component {
    constructor(props) {
        super(props)

        this.validationErrorFields = {
            termsAndConditionsIsCheckedErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            showConnectionTerms: false,
            showCableLocationTerms: false,
            showCloseApproachConsentTerms: false,
            showOnsiteGenerationTerms: false
        }

        this.validationRules = !this.isOnlyVegetationControl() && !this.isGeneralEnquiry() ? {
            'termsAndConditionsIsChecked': ['required']
        } : null

        this.connectionTypes = getCheckedFields(this.props.connectionsIsChecked)
    }

    componentDidMount() {
        this.handleScrollTop()
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    isGeneralEnquiry = () => {
        return existsGet(this.props.data, 'services.generalEnquiryIsChecked')
    }

    handleScrollTop = () => {
        // setTimeout required as scroll won't work without it, DOM render time issue
        setTimeout(() => {
            document.getElementById("app").scrollTop = this.props.summaryScrollPosition
            this.props.updateStoreValue('summaryScrollPosition', 0)
        }, 0)
    }

    setScrollTop = () =>  {
        const scrollTop = document.getElementById("app").scrollTop

        this.props.updateStoreValue('summaryScrollPosition', scrollTop)
    }

    handleNavigation = (direction) => {
        if (direction === 'next'|| direction === 'forward')
        {
            return this.finished()
        }

        if (direction === 'back')
        {
            if (this.isGeneralEnquiry())
            {
                this.props.updateLocation('/enquiry-details', null, 'back')

                return true
            }

            this.props.removeProgress({'path': '/summary'})
            const checkedConnections = getCheckedFields(this.props.connectionsIsChecked)
            if (checkedConnections.length > 0)
            {
                this.props.updateLocation('/other-information', null, 'back')

                return true
            }

            const checkedServices = getCheckedFields(this.props.servicesIsChecked)
            const connectionNamesAndSlugs = getNonConnectionNamesAndSlugs(checkedServices)
            const connectionsNames = Object.keys(connectionNamesAndSlugs)
            const lastConnectionName = connectionsNames[connectionsNames.length-1]
            const lastConnectionSlug = connectionNamesAndSlugs[lastConnectionName]

            this.props.updateLocation(lastConnectionSlug, null, 'back')

            return true
        }

        return false
    }

    finished = () =>
    {
        if (this.validationRules !== null && !validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        }))
        {
            return false
        }

        this.props.addProgress({'path': '/summary'})
        this.props.updateLocation('/submit')

        return true
    }

    getRequestedServicesDetails = (type) => {
        const fieldsIsChecked = type === 'services' ? this.props.servicesIsChecked : this.props.connectionsIsChecked
        const serviceCheckboxesData = getServiceCheckboxes(this.props.data)

        return getCheckedFields(fieldsIsChecked).filter(name => {
            return type === 'connections' || !checkIsIrrelevantService(name) ? name : null
        }).map((field) => {
                if (type === 'connections')
                    return <span>{serviceCheckboxesData[type][field].label}</span>

                const dateRequested = this.props.data[type][field+'DateRequested']

                if (typeof dateRequested === 'undefined' || dateRequested === null)
                    return null

                const date = formatDateReadable(dateRequested)

                return <>
                    <span>{serviceCheckboxesData[type][field].label}</span>
                    <span>{date}</span>
                </>
            }
        )
    }

    contactsPassFilter = (contacts, contactId) => {
        const contact = contacts[contactId]

        if (this.connectionTypes.length > 0 && !getAllRoles(this.connectionTypes).includes(this.props.data.roles[contactId][0]))
        {
            return false
        }

        return contact
    }

    getContactItems = () => {
        const data = this.props.data
        const contacts = data.contacts
        const roles = data.roles

        return Object.keys(contacts).filter((contactId) => {
            return this.contactsPassFilter(contacts, contactId)
        }).map((contactId, index) => {

            const isYourDetails = contactId === '0'
            const contact = contacts[contactId]
            const contactRoles = roles[contactId]
            const hasConnections = getCheckedFields(this.props.connectionsIsChecked).length > 0

            const title = () => {
                if (isYourDetails)
                {
                    return 'Your Contact Details'
                }

                return contactRoles[0]+' Contact Details'
            }

            const changePath = () => {
                if (isYourDetails)
                {
                    return hasConnections ? '/roles-hub/contact-details/edit-your-details' : '/name-and-address'
                }

                return '/roles-hub/contact-details/edit-contact'
            }

            return {
                contactId: contactId,
                title: title(),
                changePath: changePath(),
                content: <div key={'contactDetail_'+index}>
                    {getContactDetails(contact, contactId, this.props.data.contacts, this.props.data.roles)}
                </div>
            }
        })
    }

    getDisclaimers = (checkedServices) => {
        let result = []

        if (checkedServices.includes('safetyDisconnection'))
        {
            result.push(
                <div key='safetyDisconnection'>
                    <h3>Safety (Temporary) Disconnection Application</h3>
                    <p>By submitting this form you confirm that the information, including property address for service, is correct. Northpower cannot take responsibility for disconnections at the wrong property if you have provided incorrect information.</p>
                </div>
            )
        }

        if (checkedServices.includes('closeApproachConsent'))
        {
            result.push(
                <div key='closeApproachConsent'>
                    <h3>Working Near Lines (Close Approach) Application</h3>
                    <p>In submitting this Close Approach Consent application I, as the applicant, agree that no work will occur outside the Consent dates and times, as stated on the Close Approach Consent Certificate issued.</p>
                    <span>You must have read, understood and agree to the Close Approach (Working Near Lines) Consent terms and conditions.</span>
                </div>
            )
        }

        return result
    }

    isOnlyVegetationControl = () => {
        const checkedServices = getCheckedFields(this.props.servicesIsChecked)
        const checkedConnections = getCheckedFields(this.props.connectionsIsChecked)
        return checkedConnections.length === 0 && checkedServices.length === 1 && checkedServices.includes('vegetationControl')
    }

    render() {
        const data = this.props.data

        const connectionsServiceDetails = this.getRequestedServicesDetails('connections')
        const nonConnectionServiceDetails = this.getRequestedServicesDetails('services')

        const hasElectricalConnectionChecked = doesHaveElectricalConnectionChecked(this.props.connectionsIsChecked)

        const checkedServices = getCheckedFields(this.props.servicesIsChecked)
        const checkedConnections = getCheckedFields(this.props.connectionsIsChecked)
        const disclaimers = this.getDisclaimers(checkedServices)

        const onlyProject = checkedConnections.includes('project') && checkedConnections.length === 1
        const showNextButton = getCheckedFields(this.props.connectionsIsChecked).length > 0 && !onlyProject

        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="summary" className="section-margin-bottom">
                <Header
                    title={<>Almost finished, {data.contacts[0].firstName}</>}
                />

                <Navigation isTop={true} setParentState={this.setParentState} handleNavigation={this.handleNavigation} hideNext={true} hideSummary={true} />

                <div className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text='Please accept all the terms and conditions' type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={
                            <>
                                Here is a summary of the information you have given us. Please check that everything is correct and change anything that’s not right. Select the Submit button when you’re happy!
                            </>
                        } />

                        <div className="wrapper">

                            {connectionsServiceDetails.length > 0 ?
                                <SummaryItem
                                    title={!this.props.connectionsIsChecked.ballparkEstimateIsChecked ? 'Connections requested' : 'Service requested'}
                                    type='connections'
                                    className='services'
                                    content={
                                        <>
                                            {connectionsServiceDetails.map((title, index) => <div key={'servicesRequested_'+index}>{title}</div>)}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {checkedConnections.includes('project') ?
                                <SummaryItem
                                    title='Connection Type'
                                    content={'Projects that affect Northpower Network Infrastructure'}
                                    showChangeButton={false}
                                    contentLong={true}
                                />
                            : null}

                            {nonConnectionServiceDetails.length > 0 ?
                                <SummaryItem
                                    title='Other services requested'
                                    type='services'
                                    className='services'
                                    content={
                                        <>
                                            {nonConnectionServiceDetails.map((title, index) => <div key={'otherServicesRequested_'+index}>{title}</div>)}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {hasElectricalConnectionChecked && data.propertyType && shouldShowPropertyType(checkedConnections) ?
                                <SummaryItem
                                    title='Property type'
                                    changePath='/property-type'
                                    content={data.propertyType === 'Residential Dwelling' ? 'House' : data.propertyType}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {data.serviceAddress.city.length > 0 ?
                                <SummaryItem
                                    title={checkedConnections.includes('project') ? 'Approximate site location' : 'Address for service'}
                                    changePath={checkedConnections.includes('project') ? '/confirm-location' : '/name-and-address'}
                                    content={getServiceAddress(data.serviceAddress)}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {this.isGeneralEnquiry() ?
                                <SummaryItem
                                    title='Contact Details'
                                    className='multi'
                                    changePath='/name-and-address'
                                    content={
                                        <>
                                            <div>
                                                <h3>First name</h3>
                                                <span>{data.contacts[0].firstName}</span>
                                            </div>
                                            <div>
                                                <h3>Last name</h3>
                                                <span>{data.contacts[0].lastName}</span>
                                            </div>
                                            {data.contacts[0].phoneNumber.length > 0 ?
                                                <div>
                                                    <h3>Phone</h3>
                                                    <span>{data.contacts[0].phoneNumber}</span>
                                                </div>
                                                : null}
                                            <div>
                                                <h3>Email Address</h3>
                                                <span>{data.contacts[0].emailAddress}</span>
                                            </div>
                                            {data.contacts[0].companyName ?
                                                <div>
                                                    <h3>Company Name</h3>
                                                    <span>{data.contacts[0].companyName}</span>
                                                </div>
                                                : null}
                                            {data.generalEnquiry.icpNumber ?
                                                <div>
                                                    <h3>ICP Number</h3>
                                                    <span>{data.generalEnquiry.icpNumber}</span>
                                                </div>
                                                : null}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {this.props.project.siteLocationDetails.length > 0 ?
                                <SummaryItem
                                    title='Site location details'
                                    changePath='/name-and-address'
                                    content={data.project.siteLocationDetails}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {(hasElectricalConnectionChecked || this.props.connectionsIsChecked.projectIsChecked) && data.contacts ?
                                <SummaryItems
                                    items={this.getContactItems()}
                                    className='contact'
                                    updateLocation={this.props.updateLocation}
                                    keyName='contact'
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {hasElectricalConnectionChecked && data.electricity && (data.electricity.retailer || data.electricity.electricityRetailerCustomerNumber) ?
                                <SummaryItem
                                    title='Retailer Details'
                                    className='retailer'
                                    changePath='/retailer'
                                    content={
                                        <>
                                            {data.electricity.retailer ?
                                                <div>
                                                    <h3>Electricity Retailer</h3>
                                                    <span>{data.electricity.retailer}</span>
                                                </div>
                                            : null}
                                            {data.electricity.electricityRetailerCustomerNumber ?
                                                <div>
                                                    <h3>Customer Number</h3>
                                                    <span>{data.electricity.electricityRetailerCustomerNumber}</span>
                                                </div>
                                            : null}
                                            {(data.connections.alterationIsChecked || data.connections.onsiteGenerationIsChecked) && data.electricity.icpNumber ?
                                                <div>
                                                    <h3>ICP Number</h3>
                                                    <span>{data.electricity.icpNumber}</span>
                                                </div>
                                            : null}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {hasElectricalConnectionChecked && data.electricity && data.electricity.networkApprovedContractor ?
                                <SummaryItem
                                    title='Network approved contractor'
                                    changePath='/approved-contractor'
                                    content={<div>
                                        <span>{data.electricity.networkApprovedContractor}</span>
                                    </div>}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {data.connections.onsiteGenerationIsChecked ?
                                <SummaryItem
                                    title='Alternative Energy Capacity'
                                    className='distributed-generation-capacity'
                                    changePath='/distributed-generation-capacity'
                                    content={<>
                                        <div>
                                            <h3>Alternative energy type</h3>
                                            <span>{data.onsiteGeneration.type}</span>
                                        </div>
                                        <div>
                                            <h3>Connection Type</h3>
                                            <span>{data.onsiteGeneration.connectionType}</span>
                                        </div>
                                        <div>
                                            <h3>Generating Capacity (kW)</h3>
                                            <span>{data.onsiteGeneration.generationCapacityKw}</span>
                                        </div>
                                        <div>
                                            <h3>Number of Phases Generating</h3>
                                            <span>{data.onsiteGeneration.phases}</span>
                                        </div>
                                        <div>
                                            <h3>Connection Voltage</h3>
                                            <span>{data.onsiteGeneration.voltage}</span>
                                        </div>
                                        <div>
                                            <h3>Energy storage type</h3>
                                            <span>{data.onsiteGeneration.storageType}</span>
                                        </div>
                                        {data.onsiteGeneration.storageType !== 'No storage' ?
                                            <div>
                                                <h3>Energy Storage Capacity (kWh)</h3>
                                                <span>{data.onsiteGeneration.storageCapacityKwh}</span>
                                            </div>
                                        : null}
                                        <div>
                                            <h3>Inverter Manufacturer</h3>
                                            <span>{data.onsiteGeneration.inverterManufacturer}</span>
                                        </div>
                                        <div>
                                            <h3>Inverter Model</h3>
                                            <span>{data.onsiteGeneration.inverterModel}</span>
                                        </div>
                                        <div>
                                            <h3>Compliant Inverter</h3>
                                            <span>{data.onsiteGeneration.compliant ? 'Yes' : 'No'}</span>
                                        </div>
                                    </>}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {
                                data.propertyType !== 'Caravan' &&
                                (checkedConnections.includes('electricity') || checkedConnections.includes('alteration')) &&
                                data.electricity &&
                                (
                                    this.props.data.connections.alterationIsChecked && typeof data.alteration !== 'undefined' && data.alteration.riskCategory ||
                                    data.electricity.serviceMain ||
                                    data.electricity.maximumDemandAmps ||
                                    data.electricity.maximumDemandKW ||
                                    data.electricity.controlledLoad ||
                                    data.electricity.fuseCurrentRatings ||
                                    this.props.data.connections.alterationIsChecked && typeof data.alteration !== 'undefined' && data.alteration.workType ||
                                    this.props.data.connections.alterationIsChecked && typeof data.alteration !== 'undefined' && data.alteration.description
                                )
                            ?
                                <SummaryItem
                                    title='Capacity'
                                    changePath='/manual-capacity'
                                    className='multi'
                                    content={
                                        <>
                                            {this.props.data.connections.alterationIsChecked && typeof data.alteration !== 'undefined' && data.alteration.riskCategory ?
                                                <div>
                                                    <h3>Risk Category</h3>
                                                    <span>{data.alteration.riskCategory}</span>
                                                </div>
                                            : null}

                                            {data.electricity.serviceMain ?
                                                <div>
                                                    <h3>Service main</h3>
                                                    <span>{data.electricity.serviceMain}</span>
                                                </div>
                                            : null}

                                            {data.electricity.maximumDemandKW ?
                                                <div>
                                                    <h3>kW</h3>
                                                    <span>{data.electricity.maximumDemandKW}</span>
                                                </div>
                                            : null}

                                            {data.electricity.maximumDemandAmps ?
                                                <div>
                                                    <h3>Amps</h3>
                                                    <span>{data.electricity.maximumDemandAmps}</span>
                                                </div>
                                            : null}

                                            {data.electricity.controlledLoad ?
                                                <div>
                                                    <h3>Controlled Load</h3>
                                                    <span>Required</span>
                                                </div>
                                            : null}

                                            {data.electricity.fuseCurrentRatings ?
                                                <div>
                                                    <h3>Fuse ratings</h3>
                                                    <span>{data.electricity.fuseCurrentRatings}</span>
                                                </div>
                                            : null}

                                            {this.props.data.connections.alterationIsChecked && typeof data.alteration !== 'undefined' && data.alteration.workType ?
                                                <div>
                                                    <h3>Work Type</h3>
                                                    <span>{data.alteration.workType}</span>
                                                </div>
                                            : null}

                                            {this.props.data.connections.alterationIsChecked && typeof data.alteration !== 'undefined' && data.alteration.description ?
                                                <div>
                                                    <h3>Description of work required</h3>
                                                    <span>{data.alteration.description}</span>
                                                </div>
                                            : null}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {checkedConnections.includes('project') ?
                                <SummaryItem
                                    title='Capacity'
                                    changePath='/manual-capacity'
                                    className='multi'
                                    content={
                                        <>
                                            {typeof data.electricity !== 'undefined' && data.electricity.maximumDemandKW ?
                                                <div>
                                                    <h3>kW</h3>
                                                    <span>{data.electricity.maximumDemandKW}</span>
                                                </div>
                                            : null}

                                            {typeof data.electricity !== 'undefined' && data.electricity.maximumDemandAmps ?
                                                <div>
                                                    <h3>Amps</h3>
                                                    <span>{data.electricity.maximumDemandAmps}</span>
                                                </div>
                                            : null}

                                            {this.props.project.description ?
                                                <div>
                                                    <h3>Description of work</h3>
                                                    <span>{this.props.project.description}</span>
                                                </div>
                                            : null}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {data.services.safetyDisconnectionIsChecked ?
                                <SummaryItem
                                    title='Temporary Power Disconnection'
                                    changePath='/temporary-disconnection'
                                    className='multi'
                                    content={
                                        <>
                                            <div>
                                                <h3>For electrical work</h3>
                                                <span>{data.safetyDisconnection.electricalWork}</span>
                                            </div>
                                            {data.safetyDisconnection.electricalWork === 'Yes' ?
                                            <>
                                                <div>
                                                    <h3>Who will be carrying out the work</h3>
                                                    <span>{data.safetyDisconnection.electricalWorkWho}</span>
                                                </div>
                                                <div>
                                                    <h3>Risk level</h3>
                                                    <span>{data.safetyDisconnection.electricalWorkRisk}</span>
                                                </div>
                                            </>
                                            : null}
                                            <div>
                                                <h3>Information</h3>
                                                <span>{data.safetyDisconnection.description}</span>
                                            </div>
                                            {data.safetyDisconnection.electricianName ?
                                                <div>
                                                    <h3>Electrician name</h3>
                                                    <span>{data.safetyDisconnection.electricianName}</span>
                                                </div>
                                            : null}

                                            {data.safetyDisconnection.electricianPhone ?
                                                <div>
                                                    <h3>Electrician phone</h3>
                                                    <span>{data.safetyDisconnection.electricianPhone}</span>
                                                </div>
                                            : null}

                                            {data.safetyDisconnection.icpNumber ?
                                                <div>
                                                    <h3>ICP Number</h3>
                                                    <span>{data.safetyDisconnection.icpNumber}</span>
                                                </div>
                                            : null}

                                            <div>
                                                <h3>Disconnection Date</h3>
                                                <span>{formatDateReadable(data.services.safetyDisconnectionDateRequested)}</span>
                                            </div>

                                            {typeof data.safetyDisconnection.preferredDisconnectionTime !== 'undefined' && data.safetyDisconnection.preferredDisconnectionTime !== null ?
                                                <div>
                                                    <h3>Preferred Disconnection Time</h3>
                                                    <span>{formatTimepickerTime(data.safetyDisconnection.preferredDisconnectionTime)}</span>
                                                </div>
                                            : null}

                                            <div>
                                                <h3>Reconnection Date</h3>
                                                <span>{formatDateReadable(data.safetyDisconnection.reconnectionDateRequested)}</span>
                                            </div>

                                            {typeof data.safetyDisconnection.preferredReconnectionTime !== 'undefined' && data.safetyDisconnection.preferredReconnectionTime !== null ?
                                                <div>
                                                    <h3>Preferred Reconnection Time</h3>
                                                    <span>{formatTimepickerTime(data.safetyDisconnection.preferredReconnectionTime)}</span>
                                                </div>
                                            : null}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {data.services.vegetationControlIsChecked ?
                                <SummaryItem
                                    title='Vegetation Control'
                                    changePath='/vegetation-control'
                                    className='multi'
                                    content={
                                        <>
                                            <div>
                                                <h3>Line type</h3>
                                                <span>{data.vegetationControl.lineType}</span>
                                            </div>
                                            <div>
                                                <h3>Issue</h3>
                                                <span>{data.vegetationControl.issue}</span>
                                            </div>
                                            <div>
                                                <h3>Northpower completed vegetation control work before</h3>
                                                <span>{data.vegetationControl.previousWorkCompleted}</span>
                                            </div>
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {data.services.closeApproachConsentIsChecked ?
                                <SummaryItem
                                    title='Close Approach Consent'
                                    changePath='/close-approach-consent'
                                    className='multi'
                                    content={
                                        <>
                                            <div>
                                                <h3>Information</h3>
                                                <span>{data.closeApproachConsent.description}</span>
                                            </div>

                                            <div>
                                                <h3>Start Date</h3>
                                                <span>{formatDateReadable(data.services.closeApproachConsentDateRequested)}</span>
                                            </div>

                                            {typeof data.closeApproachConsent.preferredWorkStartTime !== 'undefined' && data.closeApproachConsent.preferredWorkStartTime !== null ?
                                                <div>
                                                    <h3>Preferred Start Time</h3>
                                                    <span>{formatTimepickerTime(data.closeApproachConsent.preferredWorkStartTime)}</span>
                                                </div>
                                            : null}

                                            <div>
                                                <h3>End Date</h3>
                                                <span>{formatDateReadable(data.closeApproachConsent.workEndDateRequested)}</span>
                                            </div>

                                            {typeof data.closeApproachConsent.preferredWorkEndTime !== 'undefined' && data.closeApproachConsent.preferredWorkEndTime !== null ?
                                                <div>
                                                    <h3>Preferred End Time</h3>
                                                    <span>{formatTimepickerTime(data.closeApproachConsent.preferredWorkEndTime)}</span>
                                                </div>
                                            : null}
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {data.services.cableLocationIsChecked ?
                                <SummaryItem
                                    title='Cable Location'
                                    changePath='/cable-location'
                                    className='multi'
                                    content={
                                        <>
                                            <div>
                                                <h3>Before U Dig Reference Number</h3>
                                                <span>{data.cableLocation.beforeUDigReferenceNumber}</span>
                                            </div>

                                            <div>
                                                <h3>Information</h3>
                                                <span>{data.cableLocation.description}</span>
                                            </div>

                                            <div>
                                                {data.cableLocation.serviceToLocateElectricity && data.cableLocation.serviceToLocateFibre ?
                                                    <h3>Services to locate</h3>
                                                :
                                                    <h3>Service to locate</h3>
                                                }

                                                {data.cableLocation.serviceToLocateElectricity ?
                                                    <span>Electricity</span>
                                                : null}

                                                {data.cableLocation.serviceToLocateFibre ?
                                                    <span>Fibre</span>
                                                : null}
                                            </div>
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {(checkedConnections.includes('project') || hasElectricalConnectionChecked) && data.otherInformation && data.otherInformation.notes ?
                                <SummaryItem
                                    title='Additional info/notes'
                                    changePath='/other-information'
                                    content={data.otherInformation.notes}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {hasElectricalConnectionChecked && data.otherInformation && data.otherInformation.locationInformation ?
                                <SummaryItem
                                    title='Location information'
                                    changePath='/other-information'
                                    content={data.otherInformation.locationInformation}
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                />
                            : null}

                            {disclaimers.length > 0 ?
                                <div className="disclaimers">
                                    <h2>Disclaimer</h2>
                                    {disclaimers}
                                </div>
                            : null}

                            {checkedServices.includes('cableLocation') ?
                                <>
                                    <Modal
                                        title="Cable Location Terms and Conditions"
                                        content={cableLocationTermsAndConditions}
                                        show={this.state.showCableLocationTerms}
                                        setParentState={this.setParentState}
                                        closeAction={() => this.setState({showCableLocationTerms: false})}
                                        className="terms-and-conditions"
                                    />

                                    <TextButton color="orange" text="Please read the Cable Location terms and conditions" onClick={() => this.setState({showCableLocationTerms: !this.state.showCableLocationTerms})} />
                                </>
                            : null}

                            {checkedServices.includes('closeApproachConsent') ?
                                <>
                                    <Modal
                                        title="Close Approach Consent Terms and Conditions"
                                        content={closeApproachTermsAndConditions}
                                        show={this.state.showCloseApproachConsentTerms}
                                        setParentState={this.setParentState}
                                        closeAction={() => this.setState({showCloseApproachConsentTerms: false})}
                                        className="terms-and-conditions"
                                    />

                                    <TextButton color="orange" text="Please read the Close Approach Consent terms and conditions" onClick={() => this.setState({showCloseApproachConsentTerms: !this.state.showCloseApproachConsentTerms})} />
                                </>
                            : null}

                            {checkedConnections.includes('onsiteGeneration') ?
                                <>
                                    <Modal
                                        title="Alternative Energy Connection Terms and Conditions"
                                        content={alternativeEnergyTermsAndConditions}
                                        show={this.state.showOnsiteGenerationTerms}
                                        setParentState={this.setParentState}
                                        closeAction={() => this.setState({showOnsiteGenerationTerms: false})}
                                        className="terms-and-conditions"
                                    />

                                    <TextButton color="orange" text="Please read the Alternative Energy terms and conditions" onClick={() => this.setState({showOnsiteGenerationTerms: !this.state.showOnsiteGenerationTerms})} />
                                </>
                            : null}

                            {this.isGeneralEnquiry() ?
                                <SummaryItem
                                    title='Enquiry Details'
                                    changePath='/enquiry-details'
                                    className='multi'
                                    content={
                                        <>
                                            <div>
                                                <h3>Type of Enquiry</h3>
                                                <p>{data.generalEnquiry.typeOfEnquiry}</p>
                                            </div>
                                            <div>
                                                <h3>Your Enquiry</h3>
                                                <pre>
                                                    <p>{data.generalEnquiry.detailsOfEnquiry}</p>
                                                </pre>
                                            </div>
                                        </>
                                    }
                                    updateLocation={this.props.updateLocation}
                                    setScrollTop={this.setScrollTop}
                                    contentLong={true}
                                />
                            : null}

                            {(
                                this.props.data.connections.electricityIsChecked ||
                                this.props.data.connections.btsIsChecked ||
                                this.props.data.connections.alterationIsChecked ||
                                this.props.data.connections.newSubdivisionIsChecked ||
                                this.props.data.connections.projectIsChecked ||
                                this.props.data.connections.ballparkEstimateIsChecked
                            ) ?
                                <>
                                    <Modal
                                        title="Terms and Conditions"
                                        content={newConnectionTermsAndConditions}
                                        show={this.state.showConnectionTerms}
                                        setParentState={this.setParentState}
                                        closeAction={() => this.setState({showConnectionTerms: false})}
                                        className="terms-and-conditions"
                                    />

                                    <TextButton color="orange" text="Please read the Connection terms and conditions" onClick={() => this.setState({showConnectionTerms: !this.state.showConnectionTerms})} />
                                </>
                            : null}

                            {!this.isOnlyVegetationControl() && !this.isGeneralEnquiry() ?
                                <Checkbox
                                    label={"I agree"}
                                    keyString="termsAndConditionsIsChecked"
                                    updateStoreValue={this.props.updateStoreValue}
                                    isChecked={this.props.termsAndConditionsIsChecked}
                                    setParentState={this.setParentState}
                                    parentErrorKey="termsAndConditionsIsCheckedErrors"
                                    validationMessages={validationMessages.termsAndConditionsIsChecked}
                                    validationErrorsFromParent={this.state.termsAndConditionsIsCheckedErrors}
                                    validationRules={this.validationRules.termsAndConditionsIsChecked}
                                />
                            : null}

                            <div className="bottom-button-wrapper">
                                <Button text={showNextButton ? "Next" : "Submit"} btnType="btn-primary" action={this.finished} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <Summary ref={ref} {...props} />
    })
)
